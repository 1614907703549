import React, { useContext, useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { Formik, Form, FormikValues } from 'formik'
import { Auth } from 'aws-amplify'
import { Translations } from '@aws-amplify/ui-components'
import Yup from '../../utils/lang/ValidationTranslation'
import CustomTranslations from '../../utils/lang/CustomTranslations'
import { SnackbarContext } from '../../context/Snackbar'
import UserContext, { User } from '../../context/User'
import { useAuthenticatedUser } from '../../hooks/AuthHooks'
import AmplifyTranslations from '../../utils/lang/AmplifyTranslations'
import FormikTextField from '../Input/FormikTextField'
import CustomButton from '../Button/CustomButton'

const validationSchema = Yup.object().shape({
  newEmail: Yup.string().email().required(),
})

const ChangeEmail = (): JSX.Element => {
  const currentUser = useAuthenticatedUser()
  const { user, updateUser } = useContext(UserContext)
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const [verifyEmail, setVerifyEmail] = useState<boolean>(false)
  const {
    EMAIL_SAVED,
    EMAIL_VERIFIED,
    CHANGE_EMAIL,
    NEW_EMAIL_LABEL,
    NEW_EMAIL_PLACEHOLDER,
    VERIFY_EMAIL,
  } = CustomTranslations

  const handleUpdate = (values: FormikValues): void => {
    Auth.updateUserAttributes(currentUser, {
      email: values.newEmail,
    })
      .then(() => {
        openSnackbar(EMAIL_SAVED, 'success')
        if (user?.email !== values.newEmail) {
          setVerifyEmail(true)
          updateUser({
            ...user,
            email: values.newEmail,
            emailVerified: false,
          } as User)
        } else setVerifyEmail(false)
      })
      .catch((err) => openSnackbarError(err))
  }

  const handleVerification = (
    values: FormikValues,
    reset: () => void
  ): void => {
    Auth.verifyCurrentUserAttributeSubmit('email', values.verificationCode)
      .then(() => {
        openSnackbar(EMAIL_VERIFIED, 'success')
        setVerifyEmail(false)
        updateUser({
          ...user,
          emailVerified: true,
        } as User)
        reset()
      })
      .catch((err) => {
        openSnackbarError(err)
      })
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {CHANGE_EMAIL}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          newEmail: '',
          verificationCode: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }): void => {
          if (verifyEmail) {
            handleVerification(values, resetForm)
          } else handleUpdate(values)
        }}
      >
        {({ submitForm, isValid }): JSX.Element => (
          <>
            <Form>
              <FormikTextField
                name="newEmail"
                label={NEW_EMAIL_LABEL}
                placeholder={NEW_EMAIL_PLACEHOLDER}
                margin="normal"
                disabled={false}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  autoComplete: 'email',
                }}
              />
              {verifyEmail && (
                <FormikTextField
                  name="verificationCode"
                  label={
                    AmplifyTranslations[Translations.CONFIRM_SIGN_UP_CODE_LABEL]
                  }
                  placeholder={
                    AmplifyTranslations[
                      Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER
                    ]
                  }
                  type="string"
                  margin="normal"
                  disabled={false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Form>
            <Box my={3}>
              <CustomButton disabled={!isValid} onClick={submitForm}>
                {verifyEmail ? VERIFY_EMAIL : CHANGE_EMAIL}
              </CustomButton>
            </Box>
          </>
        )}
      </Formik>
    </>
  )
}

export default ChangeEmail
