import { TextField } from 'formik-material-ui'
import { Field } from 'formik'
import { TextFieldProps } from '@material-ui/core/TextField'
import { AccordionDetailsTitle } from '../Accordion/CustomAccordion'
import { useStylesFilter } from './FormikTextField'

export interface StylesProps {
  color: string
  maxwidth: string
}

const FormikNumberField = (
  props: TextFieldProps & {
    title?: string
    tooltip?: string
  }
): JSX.Element => {
  const { title, tooltip, ...rest } = props
  const styleProps: StylesProps = {
    color: 'white',
    maxwidth: '180px',
  }
  const classes = useStylesFilter(styleProps)

  return (
    <>
      {title && (
        <AccordionDetailsTitle
          title={title}
          tooltip={tooltip}
          variant="body1"
          margin="0"
        />
      )}
      <Field
        component={TextField}
        fullWidth
        variant="filled"
        className={classes.root}
        color="secondary"
        margin="normal"
        disabled={false}
        inputProps={{ min: 0 }}
        type="number"
        onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
          ['e', 'E', '+', '-', '.', ','].includes(evt.key) &&
          evt.preventDefault()
        }
        {...rest}
      />
    </>
  )
}

export default FormikNumberField
