/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ChargingStationGroup
 */
export interface ChargingStationGroup {
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    groupId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ChargingStationGroup
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingStationGroup
     */
    launchDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingStationGroup
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingStationGroup
     */
    electricityType?: ChargingStationGroupElectricityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    hardwareCosts?: number;
    /**
     * 
     * @type {string}
     * @memberof ChargingStationGroup
     */
    hardwareCostsState?: ChargingStationGroupHardwareCostsStateEnum;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    nLPkl11?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    nLPgrgl11klgl22?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    sLPgr22kl50?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    sLPgrgl50kl100?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    sLPgrgl100kl150?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    sLPgrgl150kl300?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    sLPgrgl300?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingStationGroup
     */
    sumOfChargingPoints?: number;
    /**
     * 
     * @type {string}
     * @memberof ChargingStationGroup
     */
    location?: ChargingStationGroupLocationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ChargingStationGroupElectricityTypeEnum {
    Ac = 'AC',
    Dc = 'DC'
}
/**
    * @export
    * @enum {string}
    */
export enum ChargingStationGroupHardwareCostsStateEnum {
    Preliminary = 'PRELIMINARY',
    Invoice = 'INVOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum ChargingStationGroupLocationEnum {
    Yes = 'YES',
    No = 'NO',
    Unknown = 'UNKNOWN'
}



