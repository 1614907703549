/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ReportAddress } from './report-address';
import { ReportChargingStations } from './report-charging-stations';
import { ReportLocationUsage } from './report-location-usage';
import { ReportSite } from './report-site';

/**
 * 
 * @export
 * @interface ReportLocations
 */
export interface ReportLocations {
    /**
     * 
     * @type {ReportAddress}
     * @memberof ReportLocations
     */
    address?: ReportAddress;
    /**
     * 
     * @type {string}
     * @memberof ReportLocations
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportLocations
     */
    completionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportLocations
     */
    state?: ReportLocationsStateEnum;
    /**
     * 
     * @type {number}
     * @memberof ReportLocations
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportLocations
     */
    lock?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportLocations
     */
    questionnaire?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportLocations
     */
    kfwChargingPoints?: number;
    /**
     * 
     * @type {ReportLocationUsage}
     * @memberof ReportLocations
     */
    locationUsage?: ReportLocationUsage;
    /**
     * 
     * @type {ReportSite}
     * @memberof ReportLocations
     */
    site?: ReportSite;
    /**
     * 
     * @type {Array<ReportChargingStations>}
     * @memberof ReportLocations
     */
    chargingStations?: Array<ReportChargingStations>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReportLocationsStateEnum {
    Incomplete = 'INCOMPLETE',
    Step1Complete = 'STEP1_COMPLETE',
    Step2Complete = 'STEP2_COMPLETE',
    Step3Complete = 'STEP3_COMPLETE',
    Complete = 'COMPLETE',
    Cancelled = 'CANCELLED'
}



