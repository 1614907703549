import React, { useContext, useState, useEffect } from 'react'
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import ColoredCard from '../../../components/Card/ColoredCard'
import LoadingAnimation from '../../../components/Loader/LoadingAnimation'
import {
  primaryPetrolSoft,
  secondarySensitiveGreen,
} from '../../../components/Theme/CustomColors'
import { SnackbarContext } from '../../../context/Snackbar'
import { REPORT, REPORT_DATA } from '../../../routes/routes'
import {
  DefaultApi,
  ReportLocations,
  ReportLocationsStateEnum,
  ReportReportTypeEnum,
} from '../../../services'
import CustomTranslations from '../../../utils/lang/CustomTranslations'
import LocationActions from './LocationActions'
import CustomButton from '../../../components/Button/CustomButton'
import ReportingIdCard from '../ReportingIdCard'
import { reportTitleLabel, stateButtonLabel } from '../../../utils/labelHelper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    responsive: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        '& > button': {
          marginTop: '1rem',
        },
      },
    },
  })
)

const LocationDetail = (): JSX.Element => {
  const defaultApi = new DefaultApi()
  const navigate = useNavigate()
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()
  const { openSnackbarError } = useContext(SnackbarContext)
  const [location, setLocation] = useState<ReportLocations | undefined>()
  const [report, setReport] = useState<{
    kfwReportId: string | undefined
    reportType: ReportReportTypeEnum | undefined
    isStale: boolean | undefined
  }>()
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    if (reportId)
      defaultApi
        .reportReportIdGet(reportId)
        .then((res) => {
          const {
            locations,
            kfwReportId,
            reportType,
            showNotification: isStale,
          } = res.data
          const currLocation = locations?.find(
            (item: ReportLocations) => item.id?.toString() === locationId
          )
          setReport({ kfwReportId, reportType, isStale })
          setLocation(currLocation)
          setLoading(false)
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
          setLoading(false)
        })
  }, [])

  if (loading)
    return (
      <Box mt={11}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <LoadingAnimation />
          </Grid>
        </Grid>
      </Box>
    )

  if (location) {
    const isCompleted = location.state === ReportLocationsStateEnum.Complete
    return (
      <Box mt={18}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Box minHeight="240px">
              <ColoredCard
                backgroundcolor={
                  isCompleted ? secondarySensitiveGreen : primaryPetrolSoft
                }
                padding="1.8rem"
              >
                <Box maxWidth="352px">
                  <Typography variant="h2" gutterBottom>
                    {reportTitleLabel(
                      location.state as ReportLocationsStateEnum
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mt={6}
                  className={classes.responsive}
                >
                  <Typography variant="h4">
                    {location.address?.street}
                    <br />
                    {location.address?.postalCode}
                    <br />
                    {location.address?.city}
                  </Typography>
                  <CustomButton
                    onClick={(): void => {
                      navigate(
                        `${REPORT}/${reportId}/investitionsstandort/${locationId}/${REPORT_DATA}`
                      )
                    }}
                  >
                    {stateButtonLabel(
                      location.state as ReportLocationsStateEnum
                    )}
                  </CustomButton>
                </Box>
              </ColoredCard>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {report && report.reportType === ReportReportTypeEnum.Company && (
              <ReportingIdCard
                reportingId={report.kfwReportId}
                type={report.reportType}
              />
            )}
          </Grid>
          <LocationActions {...{ isCompleted }} />
        </Grid>
      </Box>
    )
  }
  return (
    <Typography>{CustomTranslations.INVESTMENT_LOCATION_NOT_FOUND}</Typography>
  )
}

export default LocationDetail
