import { Box } from '@material-ui/core'
import CustomDialog from '../../../../../components/Dialog/CustomDialog'
import CustomButton from '../../../../../components/Button/CustomButton'
import { primaryPetrolSoft } from '../../../../../components/Theme/CustomColors'

interface DialogProps {
  title: string
  handleSubmit: () => void
  handleCancel: () => void
  btnTextSubmit: string
  btnTextCancel: string
  open: boolean
  handleClose: () => void
}

const ChargingStationsDialog = (props: DialogProps): JSX.Element => {
  const {
    title,
    handleSubmit,
    handleCancel,
    btnTextCancel,
    btnTextSubmit,
    open,
    handleClose,
  } = props
  return (
    <CustomDialog title={title} open={open} handleClose={handleClose}>
      <Box display="flex" justifyContent="space-between" mt={4} mx={2}>
        <CustomButton onClick={handleSubmit} style={{ marginRight: '1.5rem' }}>
          {btnTextSubmit}
        </CustomButton>
        <CustomButton
          backgroundcolor={primaryPetrolSoft}
          onClick={handleCancel}
        >
          {btnTextCancel}
        </CustomButton>
      </Box>
    </CustomDialog>
  )
}

export default ChargingStationsDialog
