/* eslint-disable no-param-reassign */
import axios from 'axios'
import { aws4Interceptor } from 'aws4-axios'
import { Auth } from 'aws-amplify'

export async function AxiosInterceptor(): Promise<void> {
  const customCredentialsProvider = {
    getCredentials: async () => {
      const credentials = await Auth.currentCredentials()
      return Auth.essentialCredentials(credentials)
    },
  }

  const axiosAWS4Interceptor = aws4Interceptor({
    options: {
      region: 'eu-central-1',
      service: 'execute-api',
    },
    credentials: customCredentialsProvider,
  })

  axios.interceptors.request.use(axiosAWS4Interceptor)
  axios.interceptors.request.use(async (config) => {
    const token = await Auth.currentSession()
    config.headers['X-Amz-Access-Token'] = token.getAccessToken().getJwtToken()
    config.headers['X-Amz-Id-Token'] = token.getIdToken().getJwtToken()
    return config
  })
}
