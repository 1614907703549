/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChargingStationGroup } from '../models';
// @ts-ignore
import { ChargingStationGroupList } from '../models';
// @ts-ignore
import { ChargingStationModelListItem } from '../models';
// @ts-ignore
import { CompleteLocation } from '../models';
// @ts-ignore
import { CreateChargingData } from '../models';
// @ts-ignore
import { CreateChargingStationGroup } from '../models';
// @ts-ignore
import { CreateOperatingData } from '../models';
// @ts-ignore
import { CreateReport } from '../models';
// @ts-ignore
import { EMailQuery } from '../models';
// @ts-ignore
import { LocationUsage } from '../models';
// @ts-ignore
import { ReferenceIdListItem } from '../models';
// @ts-ignore
import { Report } from '../models';
// @ts-ignore
import { ReportListItem } from '../models';
// @ts-ignore
import { Site } from '../models';
// @ts-ignore
import { UserEmailFilterListItem } from '../models';
// @ts-ignore
import { UserMails } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportChargingStationModelsGet: async (filter?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/charging-station-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportChargingStationModelsOptions: async (filter?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/charging-station-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateReport} createReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPut: async (createReport: CreateReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReport' is not null or undefined
            assertParamExists('reportPut', 'createReport', createReport)
            const localVarPath = `/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdGet: async (reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdGet', 'reportId', reportId)
            const localVarPath = `/report/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingDataOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingDataOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingDataOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-data`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateChargingData} createChargingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingDataPost: async (locationId: string, reportId: string, createChargingData: CreateChargingData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingDataPost', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingDataPost', 'reportId', reportId)
            // verify required parameter 'createChargingData' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingDataPost', 'createChargingData', createChargingData)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-data`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChargingData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete: async (locationId: string, reportId: string, groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete', 'reportId', reportId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete', 'groupId', groupId)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-group/{groupId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdGet: async (locationId: string, reportId: string, groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdGet', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdGet', 'reportId', reportId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdGet', 'groupId', groupId)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-group/{groupId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions: async (locationId: string, reportId: string, groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions', 'reportId', reportId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions', 'groupId', groupId)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-group/{groupId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {ChargingStationGroup} chargingStationGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdPut: async (locationId: string, reportId: string, groupId: string, chargingStationGroup: ChargingStationGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdPut', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdPut', 'reportId', reportId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdPut', 'groupId', groupId)
            // verify required parameter 'chargingStationGroup' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupGroupIdPut', 'chargingStationGroup', chargingStationGroup)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-group/{groupId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chargingStationGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-group`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateChargingStationGroup} createChargingStationGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupPost: async (locationId: string, reportId: string, createChargingStationGroup: CreateChargingStationGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupPost', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupPost', 'reportId', reportId)
            // verify required parameter 'createChargingStationGroup' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupPost', 'createChargingStationGroup', createChargingStationGroup)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-group`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChargingStationGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupsGet: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupsGet', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupsGet', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-groups`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupsOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupsOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdChargingStationGroupsOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/charging-station-groups`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdCompleteOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdCompleteOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdCompleteOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/complete`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CompleteLocation} completeLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdCompletePut: async (locationId: string, reportId: string, completeLocation: CompleteLocation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdCompletePut', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdCompletePut', 'reportId', reportId)
            // verify required parameter 'completeLocation' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdCompletePut', 'completeLocation', completeLocation)
            const localVarPath = `/report/{reportId}/location/{locationId}/complete`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeLocation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdOperatingDataOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdOperatingDataOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdOperatingDataOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/operating-data`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateOperatingData} createOperatingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdOperatingDataPost: async (locationId: string, reportId: string, createOperatingData: CreateOperatingData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdOperatingDataPost', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdOperatingDataPost', 'reportId', reportId)
            // verify required parameter 'createOperatingData' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdOperatingDataPost', 'createOperatingData', createOperatingData)
            const localVarPath = `/report/{reportId}/location/{locationId}/operating-data`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOperatingData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdSiteGet: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdSiteGet', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdSiteGet', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/site`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdSiteOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdSiteOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdSiteOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/site`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {Site} site 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdSitePut: async (locationId: string, reportId: string, site: Site, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdSitePut', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdSitePut', 'reportId', reportId)
            // verify required parameter 'site' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdSitePut', 'site', site)
            const localVarPath = `/report/{reportId}/location/{locationId}/site`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(site, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdUsageGet: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdUsageGet', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdUsageGet', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/usage`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdUsageOptions: async (locationId: string, reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdUsageOptions', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdUsageOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location/{locationId}/usage`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {LocationUsage} locationUsage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdUsagePut: async (locationId: string, reportId: string, locationUsage: LocationUsage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdUsagePut', 'locationId', locationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdUsagePut', 'reportId', reportId)
            // verify required parameter 'locationUsage' is not null or undefined
            assertParamExists('reportReportIdLocationLocationIdUsagePut', 'locationUsage', locationUsage)
            const localVarPath = `/report/{reportId}/location/{locationId}/usage`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationUsage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationOptions: async (reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdLocationOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}/location`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdOptions: async (reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportReportIdOptions', 'reportId', reportId)
            const localVarPath = `/report/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListCsvGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/reference-id-list/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListCsvOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/reference-id-list/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/reference-id-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/reference-id-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/restricted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsFilterGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/restricted/user-emails/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsFilterOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/restricted/user-emails/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/restricted/user-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserMails} userMails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsPost: async (userMails: UserMails, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userMails' is not null or undefined
            assertParamExists('restrictedUserEmailsPost', 'userMails', userMails)
            const localVarPath = `/restricted/user-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsQueryOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/restricted/user-emails/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EMailQuery} eMailQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsQueryPost: async (eMailQuery: EMailQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eMailQuery' is not null or undefined
            assertParamExists('restrictedUserEmailsQueryPost', 'eMailQuery', eMailQuery)
            const localVarPath = `/restricted/user-emails/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eMailQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportChargingStationModelsGet(filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChargingStationModelListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportChargingStationModelsGet(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportChargingStationModelsOptions(filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportChargingStationModelsOptions(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateReport} createReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPut(createReport: CreateReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPut(createReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdGet(reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdGet(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingDataOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingDataOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateChargingData} createChargingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingDataPost(locationId: string, reportId: string, createChargingData: CreateChargingData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingDataPost(locationId, reportId, createChargingData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete(locationId: string, reportId: string, groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete(locationId, reportId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupGroupIdGet(locationId: string, reportId: string, groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargingStationGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupGroupIdGet(locationId, reportId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions(locationId: string, reportId: string, groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions(locationId, reportId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {ChargingStationGroup} chargingStationGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupGroupIdPut(locationId: string, reportId: string, groupId: string, chargingStationGroup: ChargingStationGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargingStationGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupGroupIdPut(locationId, reportId, groupId, chargingStationGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateChargingStationGroup} createChargingStationGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupPost(locationId: string, reportId: string, createChargingStationGroup: CreateChargingStationGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargingStationGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupPost(locationId, reportId, createChargingStationGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupsGet(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargingStationGroupList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupsGet(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdChargingStationGroupsOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdChargingStationGroupsOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdCompleteOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdCompleteOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CompleteLocation} completeLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdCompletePut(locationId: string, reportId: string, completeLocation: CompleteLocation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdCompletePut(locationId, reportId, completeLocation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdOperatingDataOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdOperatingDataOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateOperatingData} createOperatingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdOperatingDataPost(locationId: string, reportId: string, createOperatingData: CreateOperatingData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdOperatingDataPost(locationId, reportId, createOperatingData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdSiteGet(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdSiteGet(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdSiteOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdSiteOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {Site} site 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdSitePut(locationId: string, reportId: string, site: Site, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdSitePut(locationId, reportId, site, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdUsageGet(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationUsage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdUsageGet(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdUsageOptions(locationId: string, reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdUsageOptions(locationId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {LocationUsage} locationUsage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationLocationIdUsagePut(locationId: string, reportId: string, locationUsage: LocationUsage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationUsage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationLocationIdUsagePut(locationId, reportId, locationUsage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdLocationOptions(reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdLocationOptions(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportReportIdOptions(reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportReportIdOptions(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsReferenceIdListCsvGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsReferenceIdListCsvGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsReferenceIdListCsvOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsReferenceIdListCsvOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsReferenceIdListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReferenceIdListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsReferenceIdListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsReferenceIdListOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsReferenceIdListOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restrictedOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restrictedOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restrictedUserEmailsFilterGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserEmailFilterListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restrictedUserEmailsFilterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restrictedUserEmailsFilterOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restrictedUserEmailsFilterOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restrictedUserEmailsOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restrictedUserEmailsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserMails} userMails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restrictedUserEmailsPost(userMails: UserMails, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restrictedUserEmailsPost(userMails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restrictedUserEmailsQueryOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restrictedUserEmailsQueryOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EMailQuery} eMailQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restrictedUserEmailsQueryPost(eMailQuery: EMailQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restrictedUserEmailsQueryPost(eMailQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqGet(options?: any): AxiosPromise<object> {
            return localVarFp.faqGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqOptions(options?: any): AxiosPromise<object> {
            return localVarFp.faqOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportChargingStationModelsGet(filter?: string, options?: any): AxiosPromise<Array<ChargingStationModelListItem>> {
            return localVarFp.reportChargingStationModelsGet(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportChargingStationModelsOptions(filter?: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportChargingStationModelsOptions(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportOptions(options?: any): AxiosPromise<object> {
            return localVarFp.reportOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateReport} createReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPut(createReport: CreateReport, options?: any): AxiosPromise<Report> {
            return localVarFp.reportPut(createReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdGet(reportId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.reportReportIdGet(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingDataOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdChargingDataOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateChargingData} createChargingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingDataPost(locationId: string, reportId: string, createChargingData: CreateChargingData, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdChargingDataPost(locationId, reportId, createChargingData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete(locationId: string, reportId: string, groupId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete(locationId, reportId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdGet(locationId: string, reportId: string, groupId: string, options?: any): AxiosPromise<ChargingStationGroup> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupGroupIdGet(locationId, reportId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions(locationId: string, reportId: string, groupId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions(locationId, reportId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {string} groupId 
         * @param {ChargingStationGroup} chargingStationGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupGroupIdPut(locationId: string, reportId: string, groupId: string, chargingStationGroup: ChargingStationGroup, options?: any): AxiosPromise<ChargingStationGroup> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupGroupIdPut(locationId, reportId, groupId, chargingStationGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateChargingStationGroup} createChargingStationGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupPost(locationId: string, reportId: string, createChargingStationGroup: CreateChargingStationGroup, options?: any): AxiosPromise<ChargingStationGroup> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupPost(locationId, reportId, createChargingStationGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupsGet(locationId: string, reportId: string, options?: any): AxiosPromise<ChargingStationGroupList> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupsGet(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdChargingStationGroupsOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdChargingStationGroupsOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdCompleteOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdCompleteOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CompleteLocation} completeLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdCompletePut(locationId: string, reportId: string, completeLocation: CompleteLocation, options?: any): AxiosPromise<Report> {
            return localVarFp.reportReportIdLocationLocationIdCompletePut(locationId, reportId, completeLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdOperatingDataOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdOperatingDataOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {CreateOperatingData} createOperatingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdOperatingDataPost(locationId: string, reportId: string, createOperatingData: CreateOperatingData, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdOperatingDataPost(locationId, reportId, createOperatingData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdSiteGet(locationId: string, reportId: string, options?: any): AxiosPromise<Site> {
            return localVarFp.reportReportIdLocationLocationIdSiteGet(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdSiteOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdSiteOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {Site} site 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdSitePut(locationId: string, reportId: string, site: Site, options?: any): AxiosPromise<Site> {
            return localVarFp.reportReportIdLocationLocationIdSitePut(locationId, reportId, site, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdUsageGet(locationId: string, reportId: string, options?: any): AxiosPromise<LocationUsage> {
            return localVarFp.reportReportIdLocationLocationIdUsageGet(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdUsageOptions(locationId: string, reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationLocationIdUsageOptions(locationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} reportId 
         * @param {LocationUsage} locationUsage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationLocationIdUsagePut(locationId: string, reportId: string, locationUsage: LocationUsage, options?: any): AxiosPromise<LocationUsage> {
            return localVarFp.reportReportIdLocationLocationIdUsagePut(locationId, reportId, locationUsage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdLocationOptions(reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdLocationOptions(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportIdOptions(reportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.reportReportIdOptions(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGet(options?: any): AxiosPromise<Array<ReportListItem>> {
            return localVarFp.reportsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOptions(options?: any): AxiosPromise<object> {
            return localVarFp.reportsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListCsvGet(options?: any): AxiosPromise<object> {
            return localVarFp.reportsReferenceIdListCsvGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListCsvOptions(options?: any): AxiosPromise<object> {
            return localVarFp.reportsReferenceIdListCsvOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListGet(options?: any): AxiosPromise<Array<ReferenceIdListItem>> {
            return localVarFp.reportsReferenceIdListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReferenceIdListOptions(options?: any): AxiosPromise<object> {
            return localVarFp.reportsReferenceIdListOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedOptions(options?: any): AxiosPromise<object> {
            return localVarFp.restrictedOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsFilterGet(options?: any): AxiosPromise<Array<UserEmailFilterListItem>> {
            return localVarFp.restrictedUserEmailsFilterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsFilterOptions(options?: any): AxiosPromise<object> {
            return localVarFp.restrictedUserEmailsFilterOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsOptions(options?: any): AxiosPromise<object> {
            return localVarFp.restrictedUserEmailsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserMails} userMails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsPost(userMails: UserMails, options?: any): AxiosPromise<object> {
            return localVarFp.restrictedUserEmailsPost(userMails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsQueryOptions(options?: any): AxiosPromise<object> {
            return localVarFp.restrictedUserEmailsQueryOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EMailQuery} eMailQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restrictedUserEmailsQueryPost(eMailQuery: EMailQuery, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.restrictedUserEmailsQueryPost(eMailQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqGet(options?: any) {
        return DefaultApiFp(this.configuration).faqGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqOptions(options?: any) {
        return DefaultApiFp(this.configuration).faqOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportChargingStationModelsGet(filter?: string, options?: any) {
        return DefaultApiFp(this.configuration).reportChargingStationModelsGet(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportChargingStationModelsOptions(filter?: string, options?: any) {
        return DefaultApiFp(this.configuration).reportChargingStationModelsOptions(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportOptions(options?: any) {
        return DefaultApiFp(this.configuration).reportOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateReport} createReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportPut(createReport: CreateReport, options?: any) {
        return DefaultApiFp(this.configuration).reportPut(createReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdGet(reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdGet(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingDataOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingDataOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {CreateChargingData} createChargingData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingDataPost(locationId: string, reportId: string, createChargingData: CreateChargingData, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingDataPost(locationId, reportId, createChargingData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete(locationId: string, reportId: string, groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete(locationId, reportId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupGroupIdGet(locationId: string, reportId: string, groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupGroupIdGet(locationId, reportId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions(locationId: string, reportId: string, groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupGroupIdOptions(locationId, reportId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {string} groupId 
     * @param {ChargingStationGroup} chargingStationGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupGroupIdPut(locationId: string, reportId: string, groupId: string, chargingStationGroup: ChargingStationGroup, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupGroupIdPut(locationId, reportId, groupId, chargingStationGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {CreateChargingStationGroup} createChargingStationGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupPost(locationId: string, reportId: string, createChargingStationGroup: CreateChargingStationGroup, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupPost(locationId, reportId, createChargingStationGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupsGet(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupsGet(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdChargingStationGroupsOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdChargingStationGroupsOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdCompleteOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdCompleteOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {CompleteLocation} completeLocation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdCompletePut(locationId: string, reportId: string, completeLocation: CompleteLocation, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdCompletePut(locationId, reportId, completeLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdOperatingDataOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdOperatingDataOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {CreateOperatingData} createOperatingData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdOperatingDataPost(locationId: string, reportId: string, createOperatingData: CreateOperatingData, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdOperatingDataPost(locationId, reportId, createOperatingData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdSiteGet(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdSiteGet(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdSiteOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdSiteOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {Site} site 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdSitePut(locationId: string, reportId: string, site: Site, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdSitePut(locationId, reportId, site, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdUsageGet(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdUsageGet(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdUsageOptions(locationId: string, reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdUsageOptions(locationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} reportId 
     * @param {LocationUsage} locationUsage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationLocationIdUsagePut(locationId: string, reportId: string, locationUsage: LocationUsage, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationLocationIdUsagePut(locationId, reportId, locationUsage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdLocationOptions(reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdLocationOptions(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportReportIdOptions(reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).reportReportIdOptions(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportsGet(options?: any) {
        return DefaultApiFp(this.configuration).reportsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportsOptions(options?: any) {
        return DefaultApiFp(this.configuration).reportsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportsReferenceIdListCsvGet(options?: any) {
        return DefaultApiFp(this.configuration).reportsReferenceIdListCsvGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportsReferenceIdListCsvOptions(options?: any) {
        return DefaultApiFp(this.configuration).reportsReferenceIdListCsvOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportsReferenceIdListGet(options?: any) {
        return DefaultApiFp(this.configuration).reportsReferenceIdListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportsReferenceIdListOptions(options?: any) {
        return DefaultApiFp(this.configuration).reportsReferenceIdListOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restrictedOptions(options?: any) {
        return DefaultApiFp(this.configuration).restrictedOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restrictedUserEmailsFilterGet(options?: any) {
        return DefaultApiFp(this.configuration).restrictedUserEmailsFilterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restrictedUserEmailsFilterOptions(options?: any) {
        return DefaultApiFp(this.configuration).restrictedUserEmailsFilterOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restrictedUserEmailsOptions(options?: any) {
        return DefaultApiFp(this.configuration).restrictedUserEmailsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserMails} userMails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restrictedUserEmailsPost(userMails: UserMails, options?: any) {
        return DefaultApiFp(this.configuration).restrictedUserEmailsPost(userMails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restrictedUserEmailsQueryOptions(options?: any) {
        return DefaultApiFp(this.configuration).restrictedUserEmailsQueryOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EMailQuery} eMailQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restrictedUserEmailsQueryPost(eMailQuery: EMailQuery, options?: any) {
        return DefaultApiFp(this.configuration).restrictedUserEmailsQueryPost(eMailQuery, options).then((request) => request(this.axios, this.basePath));
    }
}
