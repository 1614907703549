import React, { ChangeEvent } from 'react'
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { useStylesFilter } from './FormikTextField'

interface FormikAutocompleteProps {
  name: string
  label: string
  value: Record<string, unknown> | null | undefined
  error: boolean
  options: Record<string, unknown>[]
  getOptionSelected:
    | ((
        option: {
          [index: string]: string
        },
        value: {
          [index: string]: string
        }
      ) => boolean)
    | undefined
  onChange: (
    event: ChangeEvent<Record<string, unknown>>,
    value: Record<string, unknown> | null
  ) => void
  onOpen: () => void
  getOptionLabel: ((option: Record<string, unknown>) => string) | undefined
  renderOption:
    | ((
        option: {
          [index: string]: string
        },
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined
  disabled?: boolean
}

const FormikAutocomplete = (props: FormikAutocompleteProps): JSX.Element => {
  const {
    onChange,
    onOpen,
    renderOption,
    getOptionLabel,
    getOptionSelected,
    value,
    name,
    label,
    options,
    error,
    disabled = false,
    ...rest
  } = props
  const classes = useStylesFilter({ color: 'white' })
  return (
    <Autocomplete
      disabled={disabled}
      id={name}
      options={options as { [index: string]: string }[]}
      autoHighlight
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      onChange={onChange}
      onOpen={onOpen}
      getOptionSelected={getOptionSelected}
      value={value as { [index: string]: string }}
      fullWidth
      renderInput={(params) => (
        <TextField
          className={classes.root}
          variant="filled"
          color="secondary"
          label={label}
          error={error}
          {...params}
        />
      )}
      {...rest}
    />
  )
}

export default FormikAutocomplete
