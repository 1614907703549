import { createContext, Dispatch, SetStateAction } from 'react'
import { ChargingStationGroup } from '../services'

type ChargingStationsContextType = {
  chargingStations: ChargingStationGroup[]
  setChargingStations: Dispatch<SetStateAction<ChargingStationGroup[]>>
  selectedChargingStation: ChargingStationGroup | undefined
  setSelectedChargingStation: Dispatch<
    SetStateAction<ChargingStationGroup | undefined>
  >
}

const ChargingStationsContext = createContext<ChargingStationsContextType>({
  chargingStations: [],
  setChargingStations: (): void => {},
  selectedChargingStation: undefined,
  setSelectedChargingStation: (): void => {},
})

export default ChargingStationsContext
