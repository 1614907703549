import React, { useContext, useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import CustomTexts from '../../../../utils/lang/CustomTexts'
import Usage from './Usage'
import Site from './Site'
import ChargingStations from './ChargingStations'
import Overview from './Overview'
import { primaryPetrol } from '../../../../components/Theme/CustomColors'
import StepperContext from '../../../../context/Stepper'
import { DefaultApi } from '../../../../services/api/default-api'
import { SnackbarContext } from '../../../../context/Snackbar'
import LoadingAnimation from '../../../../components/Loader/LoadingAnimation'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      paddingLeft: 0,
      paddingRight: 0,
      '& .MuiStepIcon-root.MuiStepIcon-active': {
        color: primaryPetrol,
      },
      '& .MuiStepIcon-root.MuiStepIcon-completed': {
        color: primaryPetrol,
      },
      '& .MuiStep-horizontal:first-child': {
        paddingLeft: 0,
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiStep-horizontal': {
          paddingLeft: 0,
          paddingBottom: '0.5rem',
        },
      },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)

const LocationStepper = (): JSX.Element => {
  const classes = useStyles()
  const defaultApi = new DefaultApi()
  const [activeStep, setActiveStep] = useState(0)
  const [lock, setLock] = useState<boolean | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(true)
  const { openSnackbarError } = useContext(SnackbarContext)
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()
  const steps = CustomTexts.stepper.titles

  useEffect(() => {
    if (reportId)
      defaultApi
        .reportReportIdGet(reportId)
        .then((response) => {
          const locationReport = response.data.locations?.find(
            (location) => location.id?.toString() === locationId
          )
          setLock(locationReport?.lock)
          setLoading(false)
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
          setLoading(false)
        })
  }, [])

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return <Usage />
      case 1:
        return <Site />
      case 2:
        return <ChargingStations />
      case 3:
        return <Overview title={CustomTexts.stepper.completeReportTitle} />
      default:
        return <Usage />
    }
  }

  if (isLoading) return <LoadingAnimation />

  if (lock) {
    return <Overview title={CustomTexts.stepper.lockReportTitle} />
  }

  return (
    <StepperContext.Provider value={{ activeStep, setActiveStep }}>
      <Grid container spacing={3}>
        <Grid item md={1} />
        <Grid item xs={12} md={8}>
          <Stepper
            activeStep={activeStep}
            color={primaryPetrol}
            className={classes.stepper}
          >
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Grid>
        <Grid item md={3} />
      </Grid>
      {getStepContent(activeStep)}
    </StepperContext.Provider>
  )
}

export default LocationStepper
