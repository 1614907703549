import { Box, makeStyles } from '@material-ui/core'
import { primaryPetrol50 } from '../../../../../components/Theme/CustomColors'
import Row from './Row'

const useStyles = makeStyles({
  summaryBox: {
    backgroundColor: primaryPetrol50,
    padding: '2.5rem 3.7rem 2.5rem 1.3rem',
  },
})

export type ReportTranslated = [string, string | number | boolean | undefined][]

const SummaryCard = (args: {
  translatedReport: ReportTranslated
}): JSX.Element => {
  const classes = useStyles()

  const { translatedReport } = args

  return (
    <>
      <Box className={classes.summaryBox}>
        {translatedReport.map(([key, value], index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <Row question={key} answer={value} key={`${key}-${index}`} />
        })}
      </Box>
    </>
  )
}

export default SummaryCard
