import { CheckboxWithLabel } from 'formik-material-ui'
import { Field } from 'formik'
import { CheckboxProps } from '@material-ui/core'

const FormikCheckbox = (
  props: CheckboxProps & { name: string; label: string }
): JSX.Element => {
  const { name, label, ...rest } = props

  return (
    <Field
      variant="outlined"
      component={CheckboxWithLabel}
      type="checkbox"
      name={name}
      Label={{ label }}
      disabled={false}
      {...rest}
    />
  )
}

export default FormikCheckbox
