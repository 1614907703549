import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import NowTheme from './components/Theme/NowTheme'
import '@fontsource/roboto'
import { AxiosInterceptor } from './openapi/axios-config'

AxiosInterceptor()

ReactDOM.render(
  <React.StrictMode>
    <NowTheme>
      <App />
    </NowTheme>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
