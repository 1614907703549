import React, { useEffect, useState } from 'react'
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifyVerifyContact,
} from '@aws-amplify/ui-react'
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components'
import CustomTranslations from '../../utils/lang/CustomTranslations'
import LogoGrid from '../../pages/LogoGrid'

const AmplifyAuth = (props: {
  authState: AuthState | undefined
  user: CognitoUserInterface | undefined
}): JSX.Element => {
  const { authState, user } = props
  const { SIGN_IN_LABEL, SIGN_UP_LABEL } = CustomTranslations
  const [cognitoUser, setCognitoUser] = useState<
    CognitoUserInterface | undefined
  >(user)

  if (authState === AuthState.VerifyContact) {
    const style = document.createElement('style')
    const host = document.getElementsByTagName('amplify-verify-contact')
    style.innerHTML = 'span amplify-button { display: none; }'
    if (host.length > 0) host[0].shadowRoot?.appendChild(style)
  }

  useEffect(() => {
    setCognitoUser(user)
  }, [user])

  return (
    <AmplifyAuthenticator
      usernameAlias="email"
      initialAuthState={AuthState.SignIn}
    >
      {authState !== AuthState.VerifyContact}
      <AmplifySignUp
        headerText={SIGN_UP_LABEL}
        usernameAlias="email"
        slot="sign-up"
        formFields={[
          {
            type: 'email',
          },
          {
            type: 'password',
          },
        ]}
      >
        <div slot="header-subtitle">
          <LogoGrid isAuth />
        </div>
      </AmplifySignUp>
      <AmplifySignIn
        headerText={SIGN_IN_LABEL}
        usernameAlias="email"
        slot="sign-in"
        formFields={[
          {
            type: 'email',
          },
          {
            type: 'password',
          },
        ]}
      >
        <div slot="header-subtitle">
          <LogoGrid isAuth />
        </div>
      </AmplifySignIn>
      <AmplifyVerifyContact slot="verify-contact" user={cognitoUser} />
    </AmplifyAuthenticator>
  )
}

export default AmplifyAuth
