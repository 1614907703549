import {
  makeStyles,
  Card,
  createStyles,
  Theme,
  CardProps,
} from '@material-ui/core'

type ColoredCardProps = CardProps & {
  children: JSX.Element | JSX.Element[]
  backgroundcolor: string
  padding?: string
  color?: string
}

interface StylesProps {
  padding?: string
  backgroundcolor: string
  color?: string
}

export const useStyles = makeStyles<Theme, StylesProps>(() =>
  createStyles({
    root: (props) => ({
      backgroundColor: props.backgroundcolor,
      padding: props.padding || '1rem',
      borderRadius: 0,
      height: '100%',
      minHeight: 'inherit',
      color: props.color,
    }),
  })
)

const ColoredCard = (props: ColoredCardProps): JSX.Element => {
  const { children, backgroundcolor, padding, color } = props

  const styleProps: StylesProps = {
    backgroundcolor,
    padding,
    color,
  }
  const classes = useStyles(styleProps)

  return (
    <Card elevation={0} className={classes.root} {...props}>
      {children}
    </Card>
  )
}

export default ColoredCard
