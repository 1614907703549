/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ReportSite
 */
export interface ReportSite {
    /**
     * 
     * @type {string}
     * @memberof ReportSite
     */
    vehicleType?: ReportSiteVehicleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportSite
     */
    existingVehicles?: ReportSiteExistingVehiclesEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ReportSite
     */
    locationRelatedVehicles?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportSite
     */
    chargingLocations?: Array<ReportSiteChargingLocationsEnum>;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    totalCompanyVehicles?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    fullElectricCompanyVehicles?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    fullElectricClassCompanyN1?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    fullElectricClassCompanyN2?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    fullElectricClassCompanyN3?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    fullElectricCompanyPkw?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportSite
     */
    fullElectricCompanyNotKnown?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportSite
     */
    fullElectricUnknown?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    employeeVehicles?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    employeeVehiclesN1?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    employeeVehiclesN2?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    employeeVehiclesN3?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    employeeVehiclesPkw?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportSite
     */
    employeeVehiclesNotKnown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportSite
     */
    registeredAtNetOperator?: ReportSiteRegisteredAtNetOperatorEnum;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    suppliesPerChargingStation?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSite
     */
    supplyCostsPerLocation?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSite
     */
    supplyCostsState?: ReportSiteSupplyCostsStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReportSiteVehicleTypeEnum {
    CompanyFleet = 'COMPANY_FLEET',
    Employee = 'EMPLOYEE',
    Both = 'BOTH'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportSiteExistingVehiclesEnum {
    Yes = 'YES',
    No = 'NO',
    Planned = 'PLANNED'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportSiteChargingLocationsEnum {
    ThisSite = 'THIS_SITE',
    OtherCompanySites = 'OTHER_COMPANY_SITES',
    PublicSites = 'PUBLIC_SITES',
    PrivateSites = 'PRIVATE_SITES'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportSiteRegisteredAtNetOperatorEnum {
    Yes = 'YES',
    No = 'NO',
    Unknown = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportSiteSupplyCostsStateEnum {
    Preliminary = 'PRELIMINARY',
    Invoice = 'INVOICE'
}



