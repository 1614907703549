/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ChargingStationGroupListEntitiesItem } from './charging-station-group-list-entities-item';

/**
 * 
 * @export
 * @interface ChargingStationGroupList
 */
export interface ChargingStationGroupList {
    /**
     * 
     * @type {Array<ChargingStationGroupListEntitiesItem>}
     * @memberof ChargingStationGroupList
     */
    entities?: Array<ChargingStationGroupListEntitiesItem>;
    /**
     * 
     * @type {string}
     * @memberof ChargingStationGroupList
     */
    reportType?: ChargingStationGroupListReportTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ChargingStationGroupListReportTypeEnum {
    Commune = 'COMMUNE',
    Company = 'COMPANY',
    Ptj = 'PTJ'
}



