import React, { ChangeEvent, useState } from 'react'
import {
  Checkbox,
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core'
import { Field } from 'formik'
import { primaryWhite } from '../Theme/CustomColors'

type FormikMultiSelectProps = SelectProps & {
  options: { title: string; value: string }[]
  defaultValue: string[]
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void
  label: string
  name: string
  errorMsg?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      maxWidth: 550,
      width: '100%',
      '& .MuiFilledInput-root, & .MuiSelect-select:focus': {
        backgroundColor: primaryWhite,
      },
      '& .MuiFormHelperText-root .MuiFormHelperText-contained .Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  })
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
    },
  },
  getContentAnchorEl: () => null,
}

const FormikMultiSelect = (props: FormikMultiSelectProps): JSX.Element => {
  const {
    options,
    label,
    name,
    defaultValue,
    setFieldValue,
    error,
    errorMsg,
    ...rest
  } = props
  const [value, setValue] = useState<string[]>(defaultValue)
  const classes = useStyles()

  const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setValue(event.target.value as string[])
    setFieldValue(name, event.target.value)
  }

  return (
    <FormControl
      variant="filled"
      color="secondary"
      className={classes.formControl}
      error={error}
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Field
        component={Select}
        name={name}
        labelId={name}
        inputProps={{
          id: name,
        }}
        multiple
        onChange={handleChange}
        MenuProps={MenuProps}
        value={value}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.title} />
          </MenuItem>
        ))}
      </Field>
      <FormHelperText>{errorMsg}</FormHelperText>
    </FormControl>
  )
}

export default FormikMultiSelect
