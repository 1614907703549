import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import ColoredCard from '../../../components/Card/ColoredCard'
import {
  lightGrey,
  primaryPetrolSoft,
  primaryPetroWhite,
} from '../../../components/Theme/CustomColors'
import CustomTexts from '../../../utils/lang/CustomTexts'
import CustomButton from '../../../components/Button/CustomButton'
import {
  CHARGED_ENGERY,
  REPORT,
  UPLOAD_CHARGING_DATA,
} from '../../../routes/routes'

const inputCardTranslation = CustomTexts.locationDetail.inputCard
const uploadCardTranslation = CustomTexts.locationDetail.uploadCard

interface LocationActionProps {
  isCompleted: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    position: 'absolute',
    bottom: '1rem',
    maxWidth: 'calc(100% - 4rem)',
  },
  actionTitle: {
    minHeight: '56px',
    marginBottom: '1rem',
  },
  boxHeight: {
    minHeight: '240px',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  title: {
    marginBottom: '1.2rem',
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 3,
  },
}))

const LocationActions = (props: LocationActionProps): JSX.Element => {
  const { isCompleted } = props

  const navigate = useNavigate()
  const classes = useStyles()
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()

  const renderIncompleteCard = (desc: string): JSX.Element => {
    return (
      <ColoredCard padding="3rem" backgroundcolor={lightGrey}>
        <Box
          display="flex"
          alignItems="center"
          textAlign="center"
          height="100%"
          width="100%"
        >
          <Typography>{desc}</Typography>
        </Box>
      </ColoredCard>
    )
  }

  const renderCompleteBox = (
    title: string,
    desc: string,
    isUpload?: boolean
  ): JSX.Element => {
    return (
      <Box textAlign="left" paddingTop="1rem">
        <Typography variant="h2" className={classes.actionTitle}>
          {title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {desc}
        </Typography>
        <CustomButton
          style={{ marginTop: '1rem', width: '100%' }}
          onClick={() =>
            isUpload
              ? navigate(
                  `${REPORT}/${reportId}/investitionsstandort/${locationId}/${UPLOAD_CHARGING_DATA}`
                )
              : navigate(
                  `${REPORT}/${reportId}/investitionsstandort/${locationId}/${CHARGED_ENGERY}`
                )
          }
          className={classes.actionButton}
        >
          {CustomTexts.locationDetail.startDataEntry}
        </CustomButton>
      </Box>
    )
  }

  const renderActions = (
    title: string,
    descActive: string,
    descDisabled: string,
    isUpload?: boolean
  ): JSX.Element => {
    const backgroundcolor = isCompleted ? primaryPetrolSoft : primaryPetroWhite

    return (
      <Grid item xs={12} md={4}>
        <Box position="relative" className={classes.boxHeight}>
          <ColoredCard padding="1rem 2rem" {...{ backgroundcolor }}>
            {isCompleted
              ? renderCompleteBox(title, descActive, isUpload)
              : renderIncompleteCard(descDisabled)}
          </ColoredCard>
        </Box>
      </Grid>
    )
  }

  return (
    <>
      {renderActions(
        inputCardTranslation.title,
        inputCardTranslation.description,
        inputCardTranslation.disabled
      )}
      {renderActions(
        uploadCardTranslation.title,
        uploadCardTranslation.description,
        uploadCardTranslation.disabled,
        true
      )}
    </>
  )
}

export default LocationActions
