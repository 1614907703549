import React, { useState } from 'react'

import { Fade, makeStyles, Theme } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import {
  secondaryPaleOrange,
  secondaryBlackBlue,
  primaryPetrol,
} from '../Theme/CustomColors'
import ColoredCard from '../Card/ColoredCard'

interface StyleProps {
  backgroundColor: string
  textcolor: string
}

interface InfoCollapseButtonProps extends Partial<StyleProps> {
  children: JSX.Element
  belowIcon?: boolean
  nextToIcon?: boolean
}

const iconHeight = 1.8
const iconPadding = 0.5
const iconTotalHeightWidth = iconHeight + 2 * iconPadding

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  announceIcon: {
    boxSizing: 'content-box',
    position: 'absolute',
    padding: `${iconPadding}rem`,
    color: primaryPetrol,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    zIndex: 1,
  },

  anchor: {
    position: 'relative',
    minHeight: `${iconTotalHeightWidth}rem`,
    marginBottom: '1.5rem',
  },
}))

const InfoCollapseButton = (props: InfoCollapseButtonProps): JSX.Element => {
  const {
    children,
    belowIcon = true,
    nextToIcon = false,
    backgroundColor = secondaryPaleOrange,
    textcolor = secondaryBlackBlue,
  } = props

  const classes = useStyles({ backgroundColor, textcolor })
  const [collapsed, setCollapsed] = useState(false)

  const paddingTop = belowIcon ? `${iconTotalHeightWidth}rem` : 0
  const paddingLeft = nextToIcon ? `${iconTotalHeightWidth}rem` : 0

  return (
    <div className={classes.anchor}>
      <ErrorIcon
        className={classes.announceIcon}
        onClick={() => setCollapsed((oldState) => !oldState)}
      />

      <Fade
        in={!collapsed}
        style={{
          height: collapsed ? 0 : '100%',
        }}
      >
        <ColoredCard
          backgroundcolor={backgroundColor}
          {...{ color: textcolor }}
          padding={`${paddingTop} 0 0 ${paddingLeft}`}
        >
          {children}
        </ColoredCard>
      </Fade>
    </div>
  )
}

export default InfoCollapseButton
