import { TextField } from 'formik-material-ui'
import { Field } from 'formik'
import { TextFieldProps } from '@material-ui/core/TextField'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { AccordionDetailsTitle } from '../Accordion/CustomAccordion'

export interface StylesProps {
  color: string
  maxwidth?: string
}
export const useStylesFilter = makeStyles<Theme, StylesProps>(() =>
  createStyles({
    root: (props) => ({
      maxWidth: props.maxwidth || '400px',
      '& .MuiInputLabel-outlined': {
        color: props.color,
        fontSize: '16px',
      },
      '& .MuiInputLabel-outlined.Mui-focused': {
        color: props.color,
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: props.color,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: props.color,
      },
      '& .MuiInputAdornment-root': {
        position: 'absolute',
        right: '0.5rem',
      },
      '& .MuiOutlinedInput-root': {
        borderColor: props.color,
        paddingRight: '3rem',
        color: props.color,
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: props.color,
          },
        },
      },
      '& .MuiFilledInput-root': {
        backgroundColor: props.color,
      },
    }),
  })
)

const FormikTextField = (
  props: TextFieldProps & {
    title?: string
    lightTheme?: boolean
    maxwidth?: string
    tooltip?: string
    typoVariant?: 'inherit' | Variant | undefined
  }
): JSX.Element => {
  const { lightTheme, title, maxwidth, tooltip, typoVariant, ...rest } = props
  const styleProps: StylesProps = {
    color: lightTheme ? 'white' : 'default',
    maxwidth,
  }
  const classes = useStylesFilter(styleProps)

  return (
    <>
      {title && (
        <AccordionDetailsTitle
          title={title}
          tooltip={tooltip}
          variant={typoVariant}
          margin="0"
        />
      )}
      <Field
        component={TextField}
        fullWidth
        variant="filled"
        className={classes.root}
        color="secondary"
        margin="normal"
        disabled={false}
        {...rest}
      />
    </>
  )
}

export default FormikTextField
