import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { AxiosError } from 'axios'
import CustomTitle from '../components/Title/CustomTitle'
import CustomTexts from '../utils/lang/CustomTexts'
import CustomTranslations from '../utils/lang/CustomTranslations'
import CustomButton from '../components/Button/CustomButton'
import { DefaultApi } from '../services'
import { SnackbarContext } from '../context/Snackbar'

const useStyles = makeStyles({
  button: {
    padding: '0.375rem 2rem',
    margin: '0.8rem 0 4rem',
  },
})

const FAQDownload = (): JSX.Element => {
  const classes = useStyles()
  const defaultApi = new DefaultApi()
  const { openSnackbarError, openSnackbar } = useContext(SnackbarContext)
  const { title, buttonText } = CustomTexts.faq
  const { DOWNLOAD_SUCCESS } = CustomTranslations

  const handleBtnClicked = (): void => {
    defaultApi
      .faqGet({
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data as BlobPart], {
          type: `${type};charset=utf-8`,
        })
        const href = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.download = `OBELIS_gewerblich_FAQ.pdf`
        document.body.appendChild(link)
        link.click()
        link.remove()
        openSnackbar(DOWNLOAD_SUCCESS, 'success')
      })

      .catch((e: AxiosError) => {
        openSnackbarError(e)
      })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <CustomTitle title={title} />
          <CustomButton className={classes.button} onClick={handleBtnClicked}>
            {buttonText}
          </CustomButton>
        </Grid>
      </Grid>
    </>
  )
}

export default FAQDownload
