import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import CustomTitle from '../../../../components/Title/CustomTitle'
import CustomTexts from '../../../../utils/lang/CustomTexts'
import {
  DefaultApi,
  ReportChargingStations,
  ReportChargingStationsHardwareCostsStateEnum,
  ReportLocations,
  ReportLocationUsage,
  ReportLocationUsageCommercialUseEnum,
  ReportReportTypeEnum,
  ReportSite,
  ReportSiteChargingLocationsEnum,
  ReportSiteRegisteredAtNetOperatorEnum,
  ReportSiteSupplyCostsStateEnum,
  ReportSiteVehicleTypeEnum,
} from '../../../../services'
import LoadingAnimation from '../../../../components/Loader/LoadingAnimation'
import { primaryPetrol } from '../../../../components/Theme/CustomColors'
import { SnackbarContext } from '../../../../context/Snackbar'
import CustomTranslations from '../../../../utils/lang/CustomTranslations'
import ColoredCard from '../../../../components/Card/ColoredCard'
import InfoCard from '../../../../components/Card/InfoCard'
import NextButtonStepper from '../../../../components/Button/NextButtonStepper'
import BackButtonStepper from '../../../../components/Button/BackButtonStepper'
import {
  chargingLocationsLabel,
  chargingStationGroupLocationLabel,
  electricityTypeLabel,
  existingVehiclesLabel,
  hardwareCostStateLabel,
  networkOperatorRegisteredLabel,
  supplyCostsStateLabel,
  usageCommercialUseLabel,
  usageMotivationTypeLabel,
  vehicleTypeLabelReport,
} from '../../../../utils/labelHelper'
import Row from './Components/Row'
import SummaryCard, { ReportTranslated } from './Components/SummaryCard'
import { navigateLocationByType } from '../../../../hooks/RouteHooks'

const useStyles = makeStyles({
  heading: {
    marginBottom: '2.1rem',
    marginTop: '3.1rem',
  },

  checkboxContainer: {
    margin: '2rem 0',
    flexDirection: 'row-reverse',
  },
})

const UsageCard = (args: {
  usageReport: ReportLocationUsage
  reportType: ReportReportTypeEnum | undefined
}): JSX.Element => {
  const { usageReport, reportType } = args
  const usageQuestionTranslation = CustomTexts.summary.usage

  const usageReportTranslated: ReportTranslated = [
    [
      usageQuestionTranslation.motivation,
      (usageReport.motivationType || [])
        .map((item) => usageMotivationTypeLabel(item))
        .join(', '),
    ],
    [usageQuestionTranslation.acquireVehicles, usageReport.acquireVehicles],
  ]

  if (reportType !== ReportReportTypeEnum.Commune) {
    usageReportTranslated.push([
      usageQuestionTranslation.commercialUse,
      usageCommercialUseLabel(
        usageReport.commercialUse as ReportLocationUsageCommercialUseEnum
      ),
    ])
  }

  return <SummaryCard translatedReport={usageReportTranslated} />
}

const SiteCard = (args: {
  siteReport: ReportSite
  reportType: ReportReportTypeEnum | undefined
}): JSX.Element => {
  const { siteReport, reportType } = args

  const siteQuestionTranslation = CustomTexts.summary.site

  const locationReportTranslated: ReportTranslated = [
    [
      siteQuestionTranslation.existingVehicles,
      siteReport.existingVehicles
        ? existingVehiclesLabel(siteReport.existingVehicles)
        : '',
    ],
    [
      siteQuestionTranslation.vehicleType,
      vehicleTypeLabelReport(
        siteReport.vehicleType as ReportSiteVehicleTypeEnum
      ),
    ],
    [
      siteQuestionTranslation.locationRelatedVehicles,
      siteReport.locationRelatedVehicles,
    ],
    [
      siteQuestionTranslation.chargingLocations,
      (siteReport.chargingLocations as ReportSiteChargingLocationsEnum[])
        .map((location) => {
          const translatedLowerCase = chargingLocationsLabel(location)
          return (
            translatedLowerCase.charAt(0).toUpperCase() +
            translatedLowerCase.slice(1)
          )
        })
        .join(', '),
    ],
    [
      siteQuestionTranslation.totalCompanyVehicles,
      siteReport.totalCompanyVehicles,
    ],
  ]

  if (reportType !== ReportReportTypeEnum.Ptj)
    locationReportTranslated.push(
      [
        siteQuestionTranslation.fullElectricCompanyVehicles,
        siteReport.fullElectricCompanyVehicles ?? CustomTranslations.UNKNOWN,
      ],
      [siteQuestionTranslation.employeeVehicles, siteReport.employeeVehicles],
      [
        siteQuestionTranslation.registeredAtNetOperator,
        networkOperatorRegisteredLabel(
          siteReport.registeredAtNetOperator as ReportSiteRegisteredAtNetOperatorEnum
        ),
      ],
      [
        siteQuestionTranslation.suppliesPerChargingStation,
        siteReport.suppliesPerChargingStation,
      ],
      [
        siteQuestionTranslation.supplyCostState,
        `${(siteReport.supplyCostsPerLocation as number).toLocaleString(
          'de-De',
          {
            minimumFractionDigits: 2,
          }
        )}\t\t${supplyCostsStateLabel(
          siteReport.supplyCostsState as ReportSiteSupplyCostsStateEnum
        )}`,
      ]
    )
  else
    locationReportTranslated.push(
      [
        siteQuestionTranslation.fullElectricCompanyPkw,
        siteReport.fullElectricCompanyPkw,
      ],
      [
        siteQuestionTranslation.fullElectricClassCompanyN1,
        siteReport.fullElectricClassCompanyN1,
      ],
      [
        siteQuestionTranslation.fullElectricClassCompanyN2,
        siteReport.fullElectricClassCompanyN2,
      ],
      [
        siteQuestionTranslation.fullElectricClassCompanyN3,
        siteReport.fullElectricClassCompanyN3,
      ],
      [
        siteQuestionTranslation.fullElectricCompanyNotKnown,
        siteReport.fullElectricCompanyNotKnown,
      ],
      [siteQuestionTranslation.employeeVehicles, siteReport.employeeVehicles],
      [
        siteQuestionTranslation.employeeVehiclesPkw,
        siteReport.employeeVehiclesPkw,
      ],
      [
        siteQuestionTranslation.employeeVehiclesN1,
        siteReport.employeeVehiclesN1,
      ],
      [
        siteQuestionTranslation.employeeVehiclesN2,
        siteReport.employeeVehiclesN2,
      ],
      [
        siteQuestionTranslation.employeeVehiclesN3,
        siteReport.employeeVehiclesN3,
      ],
      [
        siteQuestionTranslation.employeeVehiclesNotKnown,
        siteReport.employeeVehiclesNotKnown,
      ],
      [
        siteQuestionTranslation.registeredAtNetOperator,
        networkOperatorRegisteredLabel(
          siteReport.registeredAtNetOperator as ReportSiteRegisteredAtNetOperatorEnum
        ),
      ],
      [
        siteQuestionTranslation.suppliesPerChargingStation,
        siteReport.suppliesPerChargingStation,
      ],
      [
        siteQuestionTranslation.supplyCostState,
        `${(siteReport.supplyCostsPerLocation as number).toLocaleString(
          'de-De',
          {
            minimumFractionDigits: 2,
          }
        )}\t\t${supplyCostsStateLabel(
          siteReport.supplyCostsState as ReportSiteSupplyCostsStateEnum
        )}`,
      ]
    )

  return <SummaryCard translatedReport={locationReportTranslated} />
}

const ChargingGroupsSummary = (args: {
  chargingStations: ReportChargingStations[]
  sumChargingPoints: number
}): JSX.Element => {
  const { chargingStations, sumChargingPoints } = args

  const sum = chargingStations.reduce((accumulator, chargingStation) => {
    return accumulator + (chargingStation?.amount || 0)
  }, 0)

  const headingSums: [string, number][] = [
    [CustomTexts.summary.chargingStations.sum, sum],
    [CustomTexts.summary.chargingStations.sumChargingPoints, sumChargingPoints],
  ]

  return (
    <ColoredCard
      backgroundcolor={primaryPetrol}
      color="white"
      padding="1.4rem 2rem 2rem"
    >
      <Grid container spacing={3}>
        {headingSums.map((headingSum) => {
          return (
            <Grid item key={headingSum[0]}>
              <Typography display="inline" variant="h4">
                {`${headingSum[0]}: `}
              </Typography>
              <Typography display="inline" variant="h2">
                {headingSum[1]}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </ColoredCard>
  )
}

const ChargingStationCard = (args: {
  chargingStation: ReportChargingStations
  reportType: ReportReportTypeEnum | undefined
}): JSX.Element => {
  const { chargingStation, reportType } = args
  const isPtJ = reportType === ReportReportTypeEnum.Ptj

  const {
    amount: amountQuestion,
    launchDate: launchDateQuestion,
    manufacturer: manufcatureQuestion,
    model: modelQuestion,
    location: locationQuestion,
    electricityType: electricityTypeQuestion,
    hardwareCosts: harddwareCostsQuestion,
    nLPkl11,
    nLPgrgl11klgl22,
    sLPgr22kl50,
    sLPgrgl50kl100,
    sLPgrgl100kl150,
    sLPgrgl150kl300,
    sLPgrgl300,
    titleGroup,
    titleSingle,
  } = CustomTexts.summary.chargingStations

  const isGroup = (chargingStation.amount as number) > 1

  const title = isGroup ? titleGroup : titleSingle

  const hardwareCosts = (
    chargingStation.hardwareCosts as number
  ).toLocaleString('de-DE', { minimumFractionDigits: 2 })

  const hardWareState = hardwareCostStateLabel(
    chargingStation.hardwareCostsState as ReportChargingStationsHardwareCostsStateEnum
  )

  const hardwareCostWithState = `${hardwareCosts}\n${hardWareState}`

  const reportTranslated: ReportTranslated = [
    [
      launchDateQuestion,
      new Date(chargingStation.launchDate as string).toLocaleDateString(
        'de-DE',
        { dateStyle: 'medium' }
      ),
    ],
  ]

  if (!isPtJ)
    reportTranslated.push(
      [manufcatureQuestion, chargingStation.manufacturer],
      [modelQuestion, chargingStation.model],
      [
        locationQuestion,
        chargingStation.location
          ? chargingStationGroupLocationLabel(chargingStation.location)
          : '',
      ],
      [nLPkl11, chargingStation.nLPkl11],
      [nLPgrgl11klgl22, chargingStation.nLPgrgl11klgl22],
      [sLPgr22kl50, chargingStation.sLPgr22kl50],
      [sLPgrgl50kl100, chargingStation.sLPgrgl50kl100],
      [sLPgrgl100kl150, chargingStation.sLPgrgl100kl150],
      [
        electricityTypeQuestion,
        chargingStation.electricityType
          ? electricityTypeLabel(chargingStation.electricityType)
          : '',
      ],
      [harddwareCostsQuestion, hardwareCostWithState]
    )

  if (isPtJ)
    reportTranslated.push(
      [
        locationQuestion,
        chargingStation.location
          ? chargingStationGroupLocationLabel(chargingStation.location)
          : '',
      ],
      [sLPgrgl50kl100, chargingStation.sLPgrgl50kl100],
      [sLPgrgl100kl150, chargingStation.sLPgrgl100kl150],
      [sLPgrgl150kl300, chargingStation.sLPgrgl150kl300],
      [sLPgrgl300, chargingStation.sLPgrgl300],
      [
        electricityTypeQuestion,
        chargingStation.electricityType
          ? electricityTypeLabel(chargingStation.electricityType)
          : '',
      ],
      [harddwareCostsQuestion, hardwareCostWithState]
    )

  if (isGroup)
    reportTranslated.unshift([amountQuestion, chargingStation.amount])

  return (
    <InfoCard title={`${title}`}>
      {reportTranslated.map(([key, value]) => {
        return <Row question={key} answer={value} key={key} />
      })}
    </InfoCard>
  )
}

const OverviewLoaded = (args: {
  lock: boolean | undefined
  usageReport: ReportLocationUsage
  siteReport: ReportSite
  chargingReports: ReportChargingStations[]
  kfwChargingPoints: number | undefined
  reportType: ReportReportTypeEnum | undefined
}): JSX.Element => {
  const {
    usageReport,
    siteReport,
    chargingReports,
    kfwChargingPoints,
    lock,
    reportType,
  } = args
  const [subscribeSurvey, setSubscribeSurvey] = useState(false)
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const classes = useStyles()
  const [confirmed, setConfirmed] = useState(false)
  const [confirmChargingPoints, setConfirmChargingPoints] = useState(false)
  const defaultApi = new DefaultApi()
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()
  const navigate = useNavigate()
  const headingTranslations = CustomTexts.summary.headings

  const sumChargingPoints = chargingReports.reduce(
    (accumulator, chargingStation) => {
      const { sumOfChargingPoints = 0, amount = 0 } = chargingStation
      return accumulator + sumOfChargingPoints * amount
    },
    0
  )

  const ChargingGroup = (): JSX.Element => {
    return (
      <>
        <ChargingGroupsSummary
          chargingStations={chargingReports}
          sumChargingPoints={sumChargingPoints}
        />
        <Grid container spacing={2}>
          {chargingReports.map((chargingStation) => {
            return (
              <Grid item xs={12} md={6} key={chargingStation.groupId as number}>
                <ChargingStationCard
                  chargingStation={chargingStation}
                  reportType={reportType}
                />{' '}
              </Grid>
            )
          })}
        </Grid>
      </>
    )
  }

  const headingsElements: [string, JSX.Element][] = [
    [
      headingTranslations.usage,
      <UsageCard {...{ usageReport }} {...{ reportType }} />,
    ],
    [headingTranslations.site, <SiteCard {...{ siteReport, reportType }} />],
    [headingTranslations.chargingStations, <ChargingGroup />],
  ]

  const handleSubmit = (): void => {
    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdCompletePut(locationId, reportId, {
          questionnaire: subscribeSurvey,
        })
        .then(() => {
          openSnackbar(CustomTranslations.SAVE_SUCCESS, 'success')
          navigateLocationByType(
            navigate,
            reportType === ReportReportTypeEnum.Company,
            reportId as string,
            locationId as string
          )
        })
        .catch((e: AxiosError) => openSnackbarError(e))
  }

  return (
    <>
      {headingsElements.map((headingElement) => {
        return (
          <React.Fragment key={headingElement[0]}>
            <Typography variant="h2" className={classes.heading}>
              {headingElement[0]}
            </Typography>
            {headingElement[1]}
          </React.Fragment>
        )
      })}

      {!lock && (
        <>
          <Grid container className={classes.checkboxContainer}>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                style={{ marginBottom: '1rem' }}
                control={
                  <Checkbox
                    onChange={() => setSubscribeSurvey((oldState) => !oldState)}
                    name="survey"
                    color="primary"
                  />
                }
                label={CustomTexts.summary.checkboxes.survey}
              />

              <FormControlLabel
                style={{ marginBottom: '1rem' }}
                control={
                  <Checkbox
                    onChange={() => setConfirmed((oldState) => !oldState)}
                    name="confirmation"
                    color="primary"
                  />
                }
                label={CustomTexts.summary.checkboxes.confirmation}
              />
              {kfwChargingPoints && sumChargingPoints < kfwChargingPoints ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        setConfirmChargingPoints((oldState) => !oldState)
                      }
                      name="confirmation"
                      color="primary"
                    />
                  }
                  label={`
              ${CustomTexts.summary.checkboxes.sumChargingPoints} ${sumChargingPoints} ${CustomTexts.summary.checkboxes.kfwChargingPoints}
              `}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '2rem' }}>
            <Grid item xs={12} md={6}>
              <BackButtonStepper />
            </Grid>
            <Grid item xs={12} md={6}>
              <NextButtonStepper
                disabled={
                  kfwChargingPoints && sumChargingPoints < kfwChargingPoints
                    ? !confirmed || !confirmChargingPoints
                    : !confirmed
                }
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

const Overview = (props: { title: string }): JSX.Element => {
  const { title } = props
  const defaultApi = new DefaultApi()
  const { openSnackbarError } = useContext(SnackbarContext)
  const [report, setReport] = useState<ReportLocations | undefined>(undefined)
  const [reportType, setReportType] = useState<
    ReportReportTypeEnum | undefined
  >(undefined)
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()

  useEffect(() => {
    if (reportId)
      defaultApi
        .reportReportIdGet(reportId)
        .then((response) => {
          const locationReport = response.data.locations?.find(
            (location) => location.id?.toString() === locationId
          )
          setReportType(response.data.reportType)
          setReport(locationReport)
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
        })
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item md={1} />
      <Grid item xs={12} md={9}>
        <Box>
          <CustomTitle title={title} />
          {report ? (
            <OverviewLoaded
              usageReport={report.locationUsage as ReportLocationUsage}
              siteReport={report.site as ReportSite}
              kfwChargingPoints={report.kfwChargingPoints}
              lock={report.lock}
              reportType={reportType}
              chargingReports={
                report.chargingStations as ReportChargingStations[]
              }
            />
          ) : (
            <LoadingAnimation />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Overview
