import React, { useContext } from 'react'
import { ButtonProps, makeStyles, Theme } from '@material-ui/core'
import StepperContext from '../../context/Stepper'
import CustomTexts from '../../utils/lang/CustomTexts'
import CustomButton from './CustomButton'

type NextButtonStepperProps = ButtonProps & {
  submitForm?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    float: 'none',
    marginTop: '1rem',
    [theme.breakpoints.up(428)]: {
      marginBottom: '1rem',
      marginTop: 0,
      float: 'right',
    },
  },
}))

const NextButtonStepper = (props: NextButtonStepperProps): JSX.Element => {
  const { submitForm, ...rest } = props
  const { activeStep, setActiveStep } = useContext(StepperContext)
  const classes = useStyles()
  const titles = CustomTexts.stepper

  const getStepButtonText = (step: number): string => {
    switch (step) {
      case 0:
        return titles.nextBtnTitles[0]
      case 1:
        return titles.nextBtnTitles[1]
      case 2:
        return titles.nextBtnTitles[2]
      case 3:
        return titles.nextBtnTitles[3]
      default:
        return titles.nextBtnDefaultTitle
    }
  }

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (submitForm) submitForm()
  }

  return (
    <CustomButton onClick={handleNext} className={classes.button} {...rest}>
      {getStepButtonText(activeStep)}
    </CustomButton>
  )
}

export default NextButtonStepper
