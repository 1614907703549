import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { fontWhite } from '../Theme/CustomColors'

type CustomButtonProps = ButtonProps & {
  children: string | JSX.Element | JSX.Element[]
  backgroundcolor?: string
  fontcolor?: string
  component?: string
}

interface StylesProps {
  backgroundcolor?: string
  fontcolor?: string
}

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    root: (props) => ({
      backgroundColor: props.backgroundcolor || theme.palette.primary.main,
      color: props.fontcolor || fontWhite,
      transition: 'ease-in-out .3s',
      '&:hover': {
        backgroundColor: props.backgroundcolor || theme.palette.primary.main,
        color: props.fontcolor || fontWhite,
        opacity: 0.5,
      },
    }),
  })
)

const CustomButton = (props: CustomButtonProps): JSX.Element => {
  const { children, backgroundcolor, fontcolor, className, ...rest } = props
  const styleProps: StylesProps = {
    backgroundcolor,
    fontcolor,
  }
  const classes = useStyles(styleProps)
  return (
    <Button
      variant="contained"
      size="medium"
      className={`${className} ${classes.root}`}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default CustomButton
