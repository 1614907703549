import { Box, Typography } from '@material-ui/core'

interface TitleProps {
  title: string
  subtitle?: string
}

const CustomTitle = ({ title, subtitle }: TitleProps): JSX.Element => {
  return (
    <Box mt={7} mb={3}>
      <Typography variant="h1">{title}</Typography>
      {subtitle && (
        <Box mt={1}>
          <Typography variant="body1">{subtitle}</Typography>
        </Box>
      )}
    </Box>
  )
}

export default CustomTitle
