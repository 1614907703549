import React, { useContext } from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import CustomButton from '../../components/Button/CustomButton'
import ColoredCard from '../../components/Card/ColoredCard'
import {
  lightGrey,
  secondarySensitiveGreen,
} from '../../components/Theme/CustomColors'
import CustomTexts from '../../utils/lang/CustomTexts'
import CustomTranslations from '../../utils/lang/CustomTranslations'
import CopyLinkIcon from '../../assets/images/icons/CopyLink.svg'
import { SnackbarContext } from '../../context/Snackbar'
import { ReportReportTypeEnum } from '../../services/models/report'

interface ReportingIdProps {
  reportingId: string | undefined
  type: ReportReportTypeEnum
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: '25px',
      height: '20px',
    },
  })
)

const ReportingIdCard = (props: ReportingIdProps): JSX.Element => {
  const { reportingId, type } = props
  const { openSnackbar } = useContext(SnackbarContext)
  const classes = useStyles()
  const { missingReportingId, missingReportingIdCommune, copyReportingId } =
    CustomTexts.locationDetail
  const { COPY_REPORTINGID_SUCCESS, COPY_REPORTINGID_ERROR } =
    CustomTranslations

  const copyToClipboard = (): void => {
    if (reportingId)
      navigator.clipboard
        .writeText(reportingId)
        .then(() => openSnackbar(COPY_REPORTINGID_SUCCESS, 'success'))
        .catch(() => openSnackbar(COPY_REPORTINGID_ERROR, 'error'))
  }

  return (
    <Box minHeight="125px">
      {!reportingId ? (
        <ColoredCard
          backgroundcolor={lightGrey}
          padding="1.8rem 5.8rem 1.8rem 2.8rem"
        >
          <Typography variant="body1">
            {type === ReportReportTypeEnum.Commune
              ? missingReportingIdCommune
              : missingReportingId}
          </Typography>
        </ColoredCard>
      ) : (
        <ColoredCard
          backgroundcolor={secondarySensitiveGreen}
          padding="1.15rem"
        >
          <Typography variant="body1" gutterBottom>
            Reporting-ID
          </Typography>
          <Box textAlign="center">
            <Typography variant="h2" gutterBottom>
              {reportingId}
            </Typography>
            <CustomButton
              startIcon={
                <img
                  className={classes.icon}
                  src={CopyLinkIcon}
                  alt="copy icon"
                />
              }
              onClick={copyToClipboard}
              fullWidth
            >
              {copyReportingId}
            </CustomButton>
          </Box>
        </ColoredCard>
      )}
    </Box>
  )
}

export default ReportingIdCard
