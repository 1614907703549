import { Box, makeStyles, Theme, Typography } from '@material-ui/core'

import BmviLogo from '../assets/images/logos/BMVI.svg'
import LeitstelleLogo from '../assets/images/logos/Leitstelle.svg'
import NowLogo from '../assets/images/logos/NOW.svg'
import ObelisLogo from '../assets/images/logos/obelis-commercial.svg'
import KfwLogo from '../assets/images/logos/KFW.svg'
import PtjLogo from '../assets/images/logos/PtJ-Logo_2016_Schutzzone.svg'

const useStyle = makeStyles((theme: Theme) => ({
  imageContainer: {
    paddingTop: '0.5rem',
    minHeight: '3.75rem',
    boxSizing: 'content-box',
    '& img': {
      maxWidth: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
}))

const LogoWithHeading = (props: {
  img: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
  heading?: string
}): JSX.Element => {
  const { img, heading } = props
  const classes = useStyle()

  return (
    <>
      <Typography variant="caption" style={{ fontSize: '0.56rem' }}>
        {heading}
      </Typography>
      <div className={classes.imageContainer}>{img}</div>
    </>
  )
}

const LogoGrid = (props: {
  isAuth?: boolean
  showKfWLogo?: boolean
  showPtjLogo?: boolean
}): JSX.Element => {
  const { isAuth, showKfWLogo, showPtjLogo } = props
  const classes = useStyle()

  const logosWithHeading = [
    <LogoWithHeading
      img={<img src={BmviLogo} alt="BMVI Logo" />}
      heading="Gefördert durch:"
    />,
    <LogoWithHeading
      img={<img src={LeitstelleLogo} alt="Leitstelle Logo" />}
      heading="Koordiniert durch:"
    />,
    <LogoWithHeading img={<img src={NowLogo} alt="Now Logo" />} />,
    ...(showKfWLogo
      ? [
          <LogoWithHeading
            img={
              <img
                src={KfwLogo}
                alt="KfW Logo"
                style={{ width: isAuth ? '60px' : 'inherit' }}
              />
            }
            heading="Umgesetzt durch:"
          />,
        ]
      : []),
    ...(showPtjLogo
      ? [
          <LogoWithHeading
            img={<img src={PtjLogo} alt="PtJ Logo" width="90px" />}
          />,
        ]
      : []),
  ]

  const obelisLogo = (
    <LogoWithHeading
      img={<img src={ObelisLogo} alt="Obelis Logo" />}
      heading=" "
    />
  )
  const logoMapper = isAuth
    ? [obelisLogo, ...logosWithHeading]
    : logosWithHeading
  return (
    <Box className={classes.wrapper}>
      {logoMapper.map((logoWithHeading, i) => {
        const key = logoWithHeading?.props.img.props.alt
        const lastItem = logoMapper.length - 1 === i
        const costumMargin = isAuth ? 2 : 6
        return (
          <Box
            mt={4}
            mr={lastItem ? 0 : costumMargin}
            width={isAuth ? '25%' : 'inherit'}
            {...{ key }}
          >
            {logoWithHeading}
          </Box>
        )
      })}
    </Box>
  )
}

export default LogoGrid
