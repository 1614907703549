import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import LocationIcon from '../../assets/images/icons/LocationIcon.svg'
import DownloadIcon from '../../assets/images/icons/DownloadIcon.svg'
import SavingsIcon from '../../assets/images/icons/SavingsIcon.svg'
import CustomTexts from '../../utils/lang/CustomTexts'
import InfoCollapseButton from '../../components/Button/InfoCollapseButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentBox: {
      padding: '1.45rem 1rem 2.1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',

      [theme.breakpoints.down('xs')]: {
        paddingTop: '0.5rem',
      },
    },

    workflowGrid: {
      paddingTop: '1.25rem',
      alignItems: 'baseline',
    },

    imageBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },

    link: {
      textDecoration: 'underline',
      color: 'inherit',
    },

    caption: {
      whiteSpace: 'pre-wrap',
    },
  })
)

const Workflow = (): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreenWidth = useMediaQuery(theme.breakpoints.down('xs'))
  const { title, steps } = CustomTexts.workflow
  const workflowImages = [
    <img src={LocationIcon} alt="Location Icon" />,
    <img src={DownloadIcon} alt="Download Icon" />,
    <img src={SavingsIcon} alt="Savings Icon" />,
  ]

  return (
    <InfoCollapseButton belowIcon={isSmallScreenWidth}>
      <Box className={classes.contentBox}>
        <Typography variant="h3" align="center">
          {title}
        </Typography>

        <Grid className={classes.workflowGrid} container spacing={3}>
          {workflowImages.map((img, index) => {
            const step = steps[index]

            return (
              <Grid key={step[0].substring} item xs={12} sm={6} md={4}>
                <div className={classes.imageBox}>
                  {img}
                  <Typography variant="body2" className={classes.caption}>
                    {step.map(({ substring }) => substring)}
                  </Typography>
                </div>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </InfoCollapseButton>
  )
}

export default Workflow
