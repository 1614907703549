import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import CustomTranslations from '../../utils/lang/CustomTranslations'

const Error: FC<{ msg?: string }> = ({ msg }) => (
  <Box p={4} textAlign="center">
    <Typography variant="h5">{CustomTranslations.ERROR_404}</Typography>
    <Typography variant="h5">{msg}</Typography>
  </Box>
)

export default Error
