import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import {
  activeCardBg,
  primaryPetrol50,
  primaryPetrolSoft,
  secondaryLightBlue,
  secondarySensitiveGreen,
} from '../Theme/CustomColors'

interface InfoCardProps {
  children: JSX.Element | JSX.Element[]
  title: string | JSX.Element
  isActive?: boolean
  isLatest?: boolean
}
interface StylesProps {
  bgColor: string
  titleColor: string
}

export const useStyles = makeStyles<Theme, StylesProps>(() =>
  createStyles({
    root: (props) => ({
      backgroundColor: props.bgColor,
    }),
    title: (props) => ({
      backgroundColor: props.titleColor,
    }),
  })
)

const InfoCard = ({
  children,
  title,
  isActive,
  isLatest,
}: InfoCardProps): JSX.Element => {
  const setBgColor = (): string => {
    if (isActive) return activeCardBg
    if (isLatest) return secondarySensitiveGreen
    return primaryPetrol50
  }
  const setTitleColor = (): string => {
    if (isActive) return secondaryLightBlue
    return primaryPetrolSoft
  }

  const styleProps: StylesProps = {
    bgColor: setBgColor(),
    titleColor: setTitleColor(),
  }
  const classes = useStyles(styleProps)

  return (
    <Box my={2} className={classes.root} style={{ height: '100%' }}>
      <Box className={classes.title} px={2} py={3}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box p={2} pb={1}>
        {children}
      </Box>
    </Box>
  )
}

export default InfoCard
