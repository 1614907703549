/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LocationUsage
 */
export interface LocationUsage {
    /**
     * 
     * @type {string}
     * @memberof LocationUsage
     */
    commercialUse?: LocationUsageCommercialUseEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationUsage
     */
    motivationType?: Array<LocationUsageMotivationTypeEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof LocationUsage
     */
    acquireVehicles?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LocationUsage
     */
    reportType?: LocationUsageReportTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LocationUsageCommercialUseEnum {
    Yes = 'YES',
    No = 'NO',
    Unknown = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum LocationUsageMotivationTypeEnum {
    RenewableEnergy = 'RENEWABLE_ENERGY',
    SustainableMobility = 'SUSTAINABLE_MOBILITY',
    CompanyImage = 'COMPANY_IMAGE',
    EmployeeSatisfaction = 'EMPLOYEE_SATISFACTION',
    EmissionReduction = 'EMISSION_REDUCTION',
    CostSaving = 'COST_SAVING',
    Others = 'OTHERS'
}
/**
    * @export
    * @enum {string}
    */
export enum LocationUsageReportTypeEnum {
    Commune = 'COMMUNE',
    Company = 'COMPANY',
    Ptj = 'PTJ'
}



