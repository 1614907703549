import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

interface CustomTooltipProps {
  title: string | React.ReactFragment
  margin?: string
}

interface StylesProps {
  margin: string | undefined
}

const useStyles = makeStyles<Theme, StylesProps>(() =>
  createStyles({
    icon: (props) => ({
      margin: props.margin ?? '0.35rem 0 0 0.65rem',
    }),
  })
)

const CustomTooltip = (props: CustomTooltipProps): JSX.Element => {
  const { title, margin } = props
  const styleProps: StylesProps = {
    margin,
  }
  const classes = useStyles(styleProps)

  return (
    <Tooltip title={title} arrow>
      <InfoIcon className={classes.icon} color="primary" />
    </Tooltip>
  )
}

export const renderFormTooltip = (
  tooltip?: string | React.ReactFragment,
  margin?: string
): JSX.Element => {
  return (
    <>
      {tooltip ? (
        <Box textAlign="right">
          <CustomTooltip title={tooltip} margin={margin} />{' '}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export default CustomTooltip
