import React, { useContext, useEffect, useRef, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { fontWhite, primaryPetrol } from '../Theme/CustomColors'
import { FAQ, ID_LIST, PROFILE } from '../../routes/routes'
import UserContext from '../../context/User'
import CustomLink from '../Link/CustomLink'
import BackButton from '../Button/BackButton'
import CustomTranslations from '../../utils/lang/CustomTranslations'
import { matchRootPath } from '../../utils/locationPath'
import CustomTexts from '../../utils/lang/CustomTexts'
import SignoutButton from '../Auth/SignoutButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: primaryPetrol,
      width: '38px',
      height: '38px',
      marginRight: '0.5rem',
      fontSize: '0.9rem',
      color: fontWhite,
      textDecoration: 'none',
    },
    dropdown: {
      boxShadow: '0px 4px 4px 0px #00000040',
    },
    responsive: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  })
)

const UserAvatar = (props: { isAdmin: boolean }): JSX.Element => {
  const { isAdmin } = props
  const classes = useStyles()
  const location = useLocation()
  const { user } = useContext(UserContext)
  const isRootPath = matchRootPath(location.pathname)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { profile, reportingList, faq } = CustomTexts.menu

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (anchorRef.current && prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="end"
      className={classes.responsive}
    >
      {!isRootPath ? (
        <BackButton buttonText={CustomTranslations.BACK} />
      ) : (
        <Box width="100%" />
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        className={classes.responsive}
      >
        {user && user.email && (
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Avatar className={classes.avatar}>
              {user?.email.charAt(0).toUpperCase()}
            </Avatar>
            <span>{user?.email}</span>
          </Button>
        )}
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <CustomLink to={PROFILE}>
                    <MenuItem onClick={handleClose}>{profile}</MenuItem>
                  </CustomLink>
                  {!isAdmin && (
                    <CustomLink to={ID_LIST}>
                      <MenuItem onClick={handleClose}>{reportingList}</MenuItem>
                    </CustomLink>
                  )}
                  {!isAdmin && (
                    <CustomLink to={FAQ}>
                      <MenuItem onClick={handleClose}>{faq}</MenuItem>
                    </CustomLink>
                  )}
                  <MenuItem onClick={handleClose}>
                    <SignoutButton />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default UserAvatar
