import React, { useContext, useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { AxiosError } from 'axios'
import CustomTitle from '../components/Title/CustomTitle'
import CustomTexts from '../utils/lang/CustomTexts'
import CustomTranslations from '../utils/lang/CustomTranslations'
import CustomButton from '../components/Button/CustomButton'
import {
  DefaultApi,
  ReferenceIdListItem,
  ReferenceIdListItemLocations,
} from '../services'
import { primaryPetrol } from '../components/Theme/CustomColors'
import LoadingAnimation from '../components/Loader/LoadingAnimation'
import { SnackbarContext } from '../context/Snackbar'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  headCell: {
    paddingBottom: '1.2rem',
  },

  cell: {
    borderBottom: `1px solid ${primaryPetrol}`,
  },

  button: {
    padding: '0.375rem 2rem',
    margin: '0.8rem 0 4rem',
  },
})

const HeadingCell = (props: { text: string }): JSX.Element => {
  const { text } = props
  const classes = useStyles()

  return (
    <TableCell className={`${classes.headCell} ${classes.cell}`}>
      <Typography variant="h3">{text}</Typography>
    </TableCell>
  )
}

const BodyCell = (props: { text: string | number }): JSX.Element => {
  const { text } = props
  const classes = useStyles()

  return (
    <TableCell className={classes.cell}>
      <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
        {text}
      </Typography>
    </TableCell>
  )
}

const locationsToString = (
  locations: ReferenceIdListItemLocations[] | undefined
): string => {
  if (!locations) return ''

  return locations
    .map((location) => {
      if (!location.address) return ''
      const { street, city, postalCode } = location.address
      return `${street}, ${postalCode} ${city}`
    })
    .join('\n')
}

const ReportingIdList = (): JSX.Element => {
  const classes = useStyles()

  const defaultApi = new DefaultApi()
  const { openSnackbarError, openSnackbar } = useContext(SnackbarContext)
  const { title, buttonText, locationHeading, reportingIdHeading } =
    CustomTexts.reportingIdList
  const [reportingList, setReportingList] = useState<
    null | ReferenceIdListItem[]
  >(null)
  const { DOWNLOAD_SUCCESS, KFW_REFERENCE_ID, PTJ_REFERENCE_ID } =
    CustomTranslations

  useEffect(() => {
    defaultApi
      .reportsReferenceIdListGet({})
      .then((res) => {
        setReportingList(res.data)
      })
      .catch((e: AxiosError) => {
        openSnackbarError(e)
      })
  }, [])

  const handleBtnClicked = (): void => {
    defaultApi
      .reportsReferenceIdListCsvGet()
      .then((response) => {
        const blob = new Blob([`\uFEFF${response.data}` as BlobPart], {
          type: 'text/csv;charset=utf-8',
        })
        const href = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.download = `reportingIds.csv`
        document.body.appendChild(link)
        link.click()
        link.remove()
        openSnackbar(DOWNLOAD_SUCCESS, 'success')
      })

      .catch((e: AxiosError) => {
        openSnackbarError(e)
      })
  }

  return (
    <>
      {reportingList ? (
        <Grid container>
          <Grid item xs={12} md={8}>
            <CustomTitle title={title} />
            <CustomButton className={classes.button} onClick={handleBtnClicked}>
              {buttonText}
            </CustomButton>

            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadingCell
                    text={`${KFW_REFERENCE_ID} / ${PTJ_REFERENCE_ID}`}
                  />
                  <HeadingCell text={locationHeading} />
                  <HeadingCell text={reportingIdHeading} />
                </TableRow>
              </TableHead>
              <TableBody>
                {reportingList.map((report) => (
                  <TableRow key={report.kfwReferenceId}>
                    <BodyCell text={report.kfwReferenceId ?? ''} />
                    <BodyCell text={locationsToString(report.locations)} />
                    <BodyCell text={report.kfwReportId ?? ''} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      ) : (
        <LoadingAnimation />
      )}
    </>
  )
}

export default ReportingIdList
