const CustomTranslations = {
  SIGN_UP_LABEL: 'Registrierung',
  SIGN_IN_LABEL: 'Anmeldung',
  CHANGE_EMAIL: 'E-Mail-Adresse ändern',
  NEW_EMAIL_LABEL: 'Neue E-Mail-Adresse',
  EMAIL_SAVED: 'E-Mail-Adresse gespeichert',
  EMAIL_VERIFIED: 'Email-Adresse verifiziert',
  VERIFY_EMAIL: 'Email verifizieren',
  NEW_EMAIL_PLACEHOLDER: 'Neue E-Mail-Adresse eingeben',
  REPEAT_PASSWORD_LABEL: 'Passwort wiederholen',
  REPEAT_PASSWORD_PLACEHOLDER: 'Neues Passwort wiederholen',
  PASSWORD_SCHEMA_INVALID:
    'Das Passwort muss Groß- und Kleinbuchstaben und Zahlen enthalten',
  PASSWORD_MIN_LENGTH:
    'Das Passwort ist zu kurz - die Mindestlänge beträgt 8 Zeichen',
  PASSWORD_NO_MATCH: 'Passwörter stimmen nicht überein',
  PASSWORD_SAVED: 'Passwort gespeichert',
  ERROR_404: '404 Fehler',
  ERROR_MISSING_DATA: 'Unvollständig: Bitte tragen Sie alle Werte ein',
  UNKNOWN: 'Unbekannt',
  UNKNOWN_ERROR: 'Unbekannter Fehler: Bitte kontaktieren Sie den Administrator',
  PROFIL_SETTINGS: 'Profil Einstellungen',
  BACK: 'zurück',
  ONE_STEP_BACK: 'Ein Schritt zurück',
  YOUR: 'Ihre',
  LOCATION: 'Standort',
  INVESTMENT_LOCATION: 'Investitionsstandort',
  INVESTMENT_LOCATION_NOT_FOUND: 'Es wurde kein Investitionsstandort gefunden',
  INVESTMENT_LOCATIONS: 'Investitionsstandorte',
  CHANGE_DATA: 'Ändern oder Zusatzangaben eingeben',
  CHANGE_FORM_DETAILS: 'Formularangaben ändern',
  CHANGE_DETAILS: 'Angaben ändern',
  START_DATA_ENTRY: 'Dateneingabe starten',
  SELECT: 'Auswählen',
  CONTINUE: 'Fortsetzen',
  EDIT: 'Bearbeiten',
  REPORTING_ID: 'Reporting-ID',
  REPORT: 'Bericht',
  POSTCODE_ERROR: 'Muss aus 5 Ziffern bestehen',
  KFW_REFERENCE_ID: 'Zuschussreferenznummer',
  KFW_REFERENCE_ID_FUNDING: 'Zuschussreferenznummer / Förderkennzeichen',
  PTJ_REFERENCE_ID: 'Förderkennzeichen',
  LOCATIONS: 'Investitionsstandorte',
  REPORTING_IDS: 'Reporting-IDs',
  KFW_ERROR: 'Darf maximal 15 Ziffern lang sein',
  SEND_REPORT: 'Bestätigen',
  CREATE_REPORT_LABEL: '+  Investitionsstandort in Betrieb melden',
  CREATE_REPORT_SUCCESS: 'Investitionsstandort erfolgreich in Betrieb gemeldet',
  SAVE_SUCCESS: 'Ihre Angaben wurden gespeichert',
  DELETE_SUCCESS: 'Erfolgreich gelöscht',
  AMOUNT_LABEL: 'Anzahl',
  COSTS_LABEL: 'Kosten',
  LOCATIONS_LABEL: 'Standorte',
  SAVE: 'Speichern',
  SAVE_CREATE: 'Speichern und anlegen',
  COPY_REPORTINGID: 'Reporting-ID kopieren',
  COPY_REPORTINGID_SUCCESS: 'Reporting-ID erfolgreich kopiert',
  COPY_REPORTINGID_ERROR: 'Reporting-ID konnten nicht kopiert werden',
  INVALID_DATE: 'Ungültiges Datum',
  INVALID_DATE_FUTURE:
    'Das Datum darf nicht vor Beginn des Förderprogramms und nicht in der Zukunft liegen',
  INVALID_MAX_DATE: 'Das Datum darf nicht in der Zukunft liegen',
  INVALID_MIN_DATE:
    'Das Datum darf nicht vor Beginn des Förderprogramms liegen',
  REQUIRED_MULTISELECT: 'Wählen Sie mindestens eine Option aus',
  YES: 'Ja',
  NO: 'Nein',
  DOWNLOAD_SUCCESS: 'Erfolgreich runtergeladen',
  CANCEL: 'Abbrechen',
  SEND: 'Senden',
  EMAIL_SEND_SUCCESS: 'Emails erfolgreich versendet',
  FILTER_SUCCESS: 'Filter erfolgreich angewendet',
  INVALID_FIELDS:
    'Bitte prüfen Sie alle Formularfelder, auch in eingeklappten Bereichen',
}

export default CustomTranslations
