import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import CustomTitle from '../../../../components/Title/CustomTitle'
import CustomTexts from '../../../../utils/lang/CustomTexts'
import UsageForm from '../../Form/UsageForm'
import { DefaultApi } from '../../../../services/api/default-api'
import { SnackbarContext } from '../../../../context/Snackbar'
import { LocationUsage } from '../../../../services/models/location-usage'
import LoadingAnimation from '../../../../components/Loader/LoadingAnimation'

const Usage = (): JSX.Element => {
  const defaultApi = new DefaultApi()
  const { openSnackbarError } = useContext(SnackbarContext)
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()
  const [loading, setLoading] = useState<boolean>(false)
  const [usage, setUsage] = useState<LocationUsage>({})

  useEffect(() => {
    setLoading(true)
    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdUsageGet(locationId, reportId)
        .then((res) => {
          setUsage(res.data)
          setLoading(false)
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
          setLoading(false)
        })
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item md={1} />
      <Grid item xs={12} md={7}>
        <CustomTitle title={CustomTexts.stepper.usageTitle} />
        {loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Box width="100%" textAlign="right">
              <Typography variant="body2">
                {CustomTexts.form.allFieldsRequired}
              </Typography>
            </Box>
            <UsageForm usage={usage} />
          </>
        )}
      </Grid>
      <Grid item md={4} />
    </Grid>
  )
}

export default Usage
