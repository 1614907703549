import { Grid } from '@material-ui/core'
import ChangeEmail from '../components/Auth/ChangeEmail'
import ChangePassword from '../components/Auth/ChangePwd'
import CustomTranslations from '../utils/lang/CustomTranslations'
import CustomTitle from '../components/Title/CustomTitle'

const Profile = (): JSX.Element => {
  return (
    <>
      <CustomTitle title={CustomTranslations.PROFIL_SETTINGS} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ChangeEmail />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChangePassword />
        </Grid>
      </Grid>
    </>
  )
}

export default Profile
