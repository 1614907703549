import React, { useContext } from 'react'
import { ButtonProps } from '@material-ui/core'
import StepperContext from '../../context/Stepper'
import CustomTexts from '../../utils/lang/CustomTexts'
import CustomButton from './CustomButton'
import { primaryPetrolSoft, secondaryBlackBlue } from '../Theme/CustomColors'

type BackButtonStepperProps = ButtonProps

const BackButtonStepper = (props: BackButtonStepperProps): JSX.Element => {
  const { ...rest } = props
  const { activeStep, setActiveStep } = useContext(StepperContext)
  const titles = CustomTexts.stepper

  const getStepButtonText = (step: number): string => {
    switch (step) {
      case 0:
        return titles.backBtnTitles[0]
      case 1:
        return titles.backBtnTitles[1]
      case 2:
        return titles.backBtnTitles[2]
      case 3:
        return titles.backBtnTitles[3]
      default:
        return titles.backBtnDefaultTitle
    }
  }

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <CustomButton
      disabled={activeStep === 0}
      onClick={handleBack}
      backgroundcolor={primaryPetrolSoft}
      fontcolor={secondaryBlackBlue}
      {...rest}
    >
      {getStepButtonText(activeStep)}
    </CustomButton>
  )
}

export default BackButtonStepper
