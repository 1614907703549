import { Auth } from 'aws-amplify'
import { Translations } from '@aws-amplify/ui-components'
import { Typography, createStyles, makeStyles } from '@material-ui/core'
import AmplifyTranslations from '../../utils/lang/AmplifyTranslations'

export async function handleLogout(): Promise<void> {
  await Auth.signOut({ global: true })
  window.location.replace('/')
}

const SignoutButton = (): JSX.Element => {
  const useStyles = makeStyles(() =>
    createStyles({
      text: { width: '100%' },
    })
  )
  const classes = useStyles()
  return (
    <Typography className={classes.text} onClick={handleLogout}>
      {AmplifyTranslations[Translations.SIGN_OUT]}
    </Typography>
  )
}

export default SignoutButton
