import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import CreateReportForm from './Form/CreateReportForm'
import CheckList from '../../components/List/CheckList'
import {
  primaryPetrolSoft,
  fontWhite,
} from '../../components/Theme/CustomColors'
import CustomTexts from '../../utils/lang/CustomTexts'

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: primaryPetrolSoft,
    color: fontWhite,
  },

  heading: {
    marginBottom: '1.2rem',
    overflowWrap: 'break-word',
  },
})

const CreateReport = (): JSX.Element => {
  const classes = useStyles()
  const { newReport, checklist } = CustomTexts
  return (
    <Box mt={18}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box className={classes.wrapper} p={4} height="100%">
            <Grid container spacing={5}>
              <Grid item xs={12} md={5}>
                <Box pr={5}>
                  <Typography variant="h2" className={classes.heading}>
                    {newReport.title}
                  </Typography>
                  <Typography variant="body2">
                    {newReport.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <CreateReportForm />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <CheckList title={checklist.title} list={checklist.list} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CreateReport
