/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOperatingData
 */
export interface CreateOperatingData {
    /**
     * 
     * @type {string}
     * @memberof CreateOperatingData
     */
    referencePeriodStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOperatingData
     */
    referencePeriodEndDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOperatingData
     */
    energyAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOperatingData
     */
    energyAmountUnknown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOperatingData
     */
    methodOfRecording?: CreateOperatingDataMethodOfRecordingEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOperatingDataMethodOfRecordingEnum {
    Invoice = 'INVOICE',
    ElectricityMeterDiff = 'ELECTRICITY_METER_DIFF',
    Kilometers = 'KILOMETERS',
    ItBackend = 'IT_BACKEND',
    Estimation = 'ESTIMATION',
    Others = 'OTHERS'
}



