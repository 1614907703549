import React, { useContext } from 'react'
import { Box, TextFieldProps, Typography } from '@material-ui/core'
import { Translations } from '@aws-amplify/ui-components'
import { Formik, Form, FormikValues } from 'formik'
import { Auth } from 'aws-amplify'
import Yup from '../../utils/lang/ValidationTranslation'
import AmplifyTranslations from '../../utils/lang/AmplifyTranslations'
import { useAuthenticatedUser } from '../../hooks/AuthHooks'
import CustomTranslations from '../../utils/lang/CustomTranslations'
import { SnackbarContext } from '../../context/Snackbar'
import FormikTextField from '../Input/FormikTextField'
import CustomButton from '../Button/CustomButton'

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/
const {
  PASSWORD_MIN_LENGTH,
  PASSWORD_NO_MATCH,
  PASSWORD_SCHEMA_INVALID,
  PASSWORD_SAVED,
  REPEAT_PASSWORD_LABEL,
  REPEAT_PASSWORD_PLACEHOLDER,
} = CustomTranslations

const validationSchema = Yup.object().shape({
  oldPwd: Yup.string().required(),
  newPwd: Yup.string()
    .required()
    .min(8, PASSWORD_MIN_LENGTH)
    .matches(PASSWORD_REGEX, PASSWORD_SCHEMA_INVALID),
  confirmPwd: Yup.string()
    .required()
    .oneOf([Yup.ref('newPwd'), null], PASSWORD_NO_MATCH),
})

const ChangePassword = (): JSX.Element => {
  const user = useAuthenticatedUser()
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const renderPasswordField = (props: TextFieldProps): JSX.Element => {
    return (
      <FormikTextField
        name={props.name || ''}
        type="password"
        label={props.label}
        placeholder={props.placeholder}
        margin="normal"
        disabled={false}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          autoComplete: props.autoComplete,
        }}
      />
    )
  }

  const handleUpdate = (values: FormikValues, reset: () => void): void => {
    Auth.changePassword(user, values.oldPwd, values.newPwd)
      .then(() => {
        openSnackbar(PASSWORD_SAVED, 'success')
        reset()
      })
      .catch((err) => openSnackbarError(err))
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {AmplifyTranslations[Translations.CHANGE_PASSWORD]}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          oldPwd: '',
          newPwd: '',
          confirmPwd: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }): void =>
          handleUpdate(values, resetForm)
        }
      >
        {({ submitForm, isValid }): JSX.Element => (
          <>
            <Form>
              <input hidden type="text" name="email" autoComplete="email" />
              {renderPasswordField({
                name: 'oldPwd',
                label: AmplifyTranslations[Translations.PASSWORD_LABEL],
                placeholder:
                  AmplifyTranslations[Translations.PASSWORD_PLACEHOLDER],
                autoComplete: 'current-password',
              })}
              {renderPasswordField({
                name: 'newPwd',
                label: AmplifyTranslations[Translations.NEW_PASSWORD_LABEL],
                placeholder:
                  AmplifyTranslations[Translations.NEW_PASSWORD_PLACEHOLDER],
                autoComplete: 'new-password',
              })}
              {renderPasswordField({
                name: 'confirmPwd',
                label: REPEAT_PASSWORD_LABEL,
                placeholder: REPEAT_PASSWORD_PLACEHOLDER,
                autoComplete: 'new-password',
              })}
            </Form>
            <Box my={3}>
              <CustomButton disabled={!isValid} onClick={submitForm}>
                {AmplifyTranslations[Translations.CHANGE_PASSWORD]}
              </CustomButton>
            </Box>
          </>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword
