import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  SelectProps,
} from '@material-ui/core'
import { Field } from 'formik'
import { Select } from 'formik-material-ui'
import { primaryWhite } from '../Theme/CustomColors'

type FormikSelectProps = SelectProps & {
  options: { title: string; value: string }[]
  error: boolean
  label: string
  name: string
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      maxWidth: 455,
      width: '100%',
      '& .MuiFilledInput-root, & .Mui-focused, & .MuiSelect-select:focus': {
        backgroundColor: primaryWhite,
      },
    },
  })
)

const FormikSelect = (props: FormikSelectProps): JSX.Element => {
  const { options, label, name, error, ...rest } = props
  const classes = useStyles()

  return (
    <FormControl
      variant="filled"
      color="secondary"
      className={classes.formControl}
      error={error}
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Field
        component={Select}
        name={name}
        labelId={name}
        disabled={false}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  )
}

export default FormikSelect
