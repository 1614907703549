import React, { useState, useContext, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import CustomTitle from '../../../components/Title/CustomTitle'
import CustomTranslations from '../../../utils/lang/CustomTranslations'
import { SnackbarContext } from '../../../context/Snackbar'
import { DefaultApi, Report, ReportLocations } from '../../../services'
import LoadingAnimation from '../../../components/Loader/LoadingAnimation'
import ReportingIdCard from '../ReportingIdCard'
import LogoGrid from '../../LogoGrid'
import { useLogoGridStyles } from '../../Dashboard/Dashboard'
import ReportCard from '../../Dashboard/ReportCard'

const LocationList = (): JSX.Element => {
  const defaultApi = new DefaultApi()
  const { reportId } = useParams<{ reportId: string }>()
  const { openSnackbarError } = useContext(SnackbarContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [locations, setLocations] = useState<ReportLocations[] | undefined>([])
  const [report, setReport] = useState<Report>()
  const classes = useLogoGridStyles()

  useEffect(() => {
    setLoading(true)
    if (reportId)
      defaultApi
        .reportReportIdGet(reportId)
        .then((res) => {
          setLocations(res.data.locations)
          setReport(res.data)
          setLoading(false)
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
          setLoading(false)
        })
  }, [])

  return (
    <Box className={classes.root} mt={1}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomTitle
            title={`${CustomTranslations.YOUR} ${CustomTranslations.INVESTMENT_LOCATIONS}`}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          {loading ? (
            <LoadingAnimation />
          ) : (
            <Grid container spacing={3}>
              {report &&
                locations?.map((location: ReportLocations) => (
                  <Grid item xs={12} md={6} key={location.id}>
                    <ReportCard
                      report={report}
                      location={location}
                      isLocationListCard
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {report && report.reportType && (
            <ReportingIdCard
              reportingId={report.kfwReportId}
              type={report.reportType}
            />
          )}
        </Grid>
      </Grid>
      <LogoGrid showKfWLogo showPtjLogo />
    </Box>
  )
}

export default LocationList
