/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ReportListItemLocations } from './report-list-item-locations';

/**
 * 
 * @export
 * @interface ReportListItem
 */
export interface ReportListItem {
    /**
     * 
     * @type {number}
     * @memberof ReportListItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportListItem
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportListItem
     */
    completionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportListItem
     */
    kfwReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportListItem
     */
    kfwReportId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportListItem
     */
    reporterPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportListItem
     */
    reportType?: ReportListItemReportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportListItem
     */
    state?: ReportListItemStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ReportListItem
     */
    showNotification?: boolean;
    /**
     * 
     * @type {Array<ReportListItemLocations>}
     * @memberof ReportListItem
     */
    locations?: Array<ReportListItemLocations>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReportListItemReportTypeEnum {
    Commune = 'COMMUNE',
    Company = 'COMPANY',
    Ptj = 'PTJ'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportListItemStateEnum {
    Complete = 'COMPLETE',
    Incomplete = 'INCOMPLETE',
    Cancelled = 'CANCELLED'
}



