import { Button, ButtonProps } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useNavigate } from 'react-router-dom'

type BackButtonProps = ButtonProps & {
  buttonText: string
}

const BackButton = (props: BackButtonProps): JSX.Element => {
  const navigate = useNavigate()
  const { buttonText, ...rest } = props
  return (
    <Button
      startIcon={<ChevronLeftIcon style={{ marginTop: '0.2rem' }} />}
      onClick={(): void => navigate(-1)}
      {...rest}
    >
      {buttonText}
    </Button>
  )
}

export default BackButton
