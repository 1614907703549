import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import { Variant } from '@material-ui/core/styles/createTypography'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Box } from '@material-ui/core'
import CustomTooltip, { renderFormTooltip } from '../Tooltip/CustomTooltip'
import { accordionBg } from '../Theme/CustomColors'

const Accordion = withStyles({
  root: {
    border: 'unset',
    boxShadow: 'none',
    marginBottom: '1rem',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: '1rem',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles(() => ({
  root: {
    backgroundColor: accordionBg,
    border: 'unset',
    padding: '1.75rem 2.2rem',
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    '& > .MuiSvgIcon-root': {
      transition: 'ease-in-out .3s',
    },
    '&$expanded': {
      '& > .MuiSvgIcon-root': {
        transform: 'rotate(90deg)',
      },
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const AccordionDetails = withStyles(() => ({
  root: {
    display: 'block',
    padding: '0 2.2rem 2.2rem',
    backgroundColor: accordionBg,
  },
}))(MuiAccordionDetails)

type CustomAccordionProps = AccordionProps & {
  children: JSX.Element | JSX.Element[]
  title: string
  id: string
  isExpanded?: boolean
  defaultExpanded?: boolean
  tooltipTitle?: string | React.ReactFragment
}

type AccordionDetailsTitleProps = {
  title: string
  variant?: 'inherit' | Variant | undefined
  margin?: string
  tooltip?: string | React.ReactFragment
}

export const AccordionDetailsTitle = (
  props: AccordionDetailsTitleProps
): JSX.Element => {
  const { title, variant, margin, tooltip } = props
  return (
    <>
      {renderFormTooltip(tooltip, '0')}
      <Typography
        variant={variant || 'body1'}
        style={{ margin: margin || '0 0 2rem' }}
      >
        {title}
      </Typography>
    </>
  )
}

const CustomAccordion = (props: CustomAccordionProps): JSX.Element => {
  const {
    defaultExpanded,
    isExpanded,
    children,
    title,
    tooltipTitle,
    id,
    ...rest
  } = props
  const [expanded, setExpanded] = useState<boolean>(
    isExpanded || defaultExpanded || false
  )

  useEffect(() => {
    if (isExpanded) setExpanded(isExpanded)
  }, [isExpanded])

  const handleChange = (): void => {
    setExpanded(!expanded)
  }

  return (
    <Accordion square expanded={expanded} onChange={handleChange} {...rest}>
      <AccordionSummary aria-controls={`${id}-content`} id={`${id}-header`}>
        <Box display="flex" alignItems="end">
          <Typography variant="h2">{title}</Typography>
          {tooltipTitle && <CustomTooltip title={tooltipTitle} />}
        </Box>
        <KeyboardArrowRightIcon style={{ fontSize: '2rem' }} />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion
