import { Box, Hidden, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import CustomTexts from '../../utils/lang/CustomTexts'
import { sidebarBg } from '../Theme/CustomColors'
import { matchReportDataPath } from '../../utils/locationPath'
import obelisLogo from '../../assets/images/logos/obelis-commercial.svg'
import CustomLink from '../Link/CustomLink'
import { DASHBOARD, FAQ } from '../../routes/routes'

const useStyles = makeStyles({
  sidebar: {
    backgroundColor: sidebarBg,
  },
  title: {
    marginBottom: '1.2rem',
    marginTop: '2.5rem',
    fontSize: 20,
  },
  description: {
    lineHeight: '18px',
  },
  link: {
    fontSize: 14,
  },
  logo: {
    maxWidth: '100%',
    width: '11.25rem',
  },
})

const Sidebar = (props: { isAdmin: boolean }): JSX.Element => {
  const { isAdmin } = props
  const classes = useStyles()
  const location = useLocation()
  const matchPath = matchReportDataPath(location.pathname)
  const { sidebar, support, dataProtection, impressum } = CustomTexts

  return (
    <Hidden only={['xs', 'sm', 'md']}>
      <Box width={matchPath || isAdmin ? '156px' : '300px'} position="relative">
        <Box
          px={3}
          pt={4}
          height="100%"
          minHeight={matchPath || isAdmin ? '100vh' : '852px'}
          className={classes.sidebar}
        >
          <Box>
            <CustomLink to={DASHBOARD}>
              <img
                className={classes.logo}
                src={obelisLogo}
                alt="Obelis Logo"
              />
            </CustomLink>
          </Box>
          {!matchPath && !isAdmin && (
            <>
              <Box mb={3} mt={14}>
                {sidebar.map((text) => (
                  <Box key={text.title}>
                    <Typography
                      className={classes.title}
                      variant="h3"
                      gutterBottom
                    >
                      {text.title}
                    </Typography>
                    <Typography
                      className={classes.description}
                      variant="body2"
                      gutterBottom
                    >
                      {text.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box position="absolute" bottom="2rem">
                <Typography variant="body2" gutterBottom>
                  {`${support.txt} `}
                  <br />
                  <Link href={FAQ}>{support.faqs}</Link>
                  <br />
                  {support.emailTxt}
                  <br />
                  <Link href={`mailto:${support.email}`}>
                    {` ${support.email}`}
                  </Link>
                </Typography>
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Link
                    href={impressum.link}
                    target="_blank"
                    style={{ marginRight: 10 }}
                  >
                    {impressum.title}
                  </Link>
                  <Link href={dataProtection.link} target="_blank">
                    {dataProtection.title}
                  </Link>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Hidden>
  )
}

export default Sidebar
