// brand primary colors
export const primaryPetrol = '#3F6558'
export const primaryPetrol50 = '#F5FAF8'
export const primaryPetrolLight = '#8AB1A3'
export const primaryPetrolSoft = '#AFCDC1'
export const primaryPetroWhite = '#EAF5F1'

export const primaryBlack = '#000000'
export const primaryWhite = '#FFFFFF'

export const successGreen = '#40B630'
export const warningYellow = '#FACA00'
export const errorRed = '#FF151F'

// brand secondary colors
export const secondaryBlackBlue = '#002B45'

export const secondaryFrogGreen = '#4A9134'
export const secondaryGrasGreen = '#69BE28'
export const secondaryLightGreen = '#A3D899'
export const secondarySensitiveGreen = '#D1EBCC'

export const secondaryOrange = '#FFB200'
export const secondaryLightOrange = '#FFE389'
export const secondaryPaleOrange = '#FFEFB8'

export const secondaryMarineBlue = '#165788'
export const secondaryWaterBlue = '#739ABC'
export const secondaryLightBlue = '#C3D3DF'
export const secondarySoftBlue = '#F0F4F7'

export const deepGrey = '#696A6F'
export const middleGrey = '#94969A'
export const borderGrey = '#C4C4C4'
export const lightGrey = '#E9EAE8'

// background colors
export const sidebarBg = 'rgba(233,234,232, 0.54)'
export const overlayBg = 'rgba(233, 234, 232, 0.75)'
export const accordionBg = primaryPetrol50
export const activeCardBg = secondarySoftBlue
export const inputLightGreenBg = '#E2EDE9'

export const fontWhite = primaryWhite
export const fontBlack = primaryBlack
