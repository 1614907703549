import { makeStyles, Box, Typography } from '@material-ui/core'
import { secondaryBlackBlue, secondaryPaleOrange } from '../Theme/CustomColors'

interface ChecklistProps {
  list: Array<string>
  title: string
}

const useStyles = makeStyles({
  listWrapper: {
    backgroundColor: secondaryPaleOrange,
    color: secondaryBlackBlue,
  },
  list: {
    paddingLeft: '1rem',
    marginTop: '1.5rem',
    lineHeight: '25px',
  },
})

const CheckList = ({ list, title }: ChecklistProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Box className={classes.listWrapper} p={3} height="100%">
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <ul className={classes.list}>
        {list.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </Box>
  )
}

export default CheckList
