const basePath =
  document.getElementsByTagName('base')?.[0]?.getAttribute('href') || '/'
export const ROOT_PATH = basePath
export const DASHBOARD = `${ROOT_PATH}dashboard`
export const NEW_REPORT = `${ROOT_PATH}neuer-bericht`
export const REPORT = `${ROOT_PATH}bericht`
export const REPORT_DATA = `stammdaten`
export const PROFILE = `${ROOT_PATH}profil`
export const ID_LIST = `${ROOT_PATH}reporting-ids`
export const FAQ = `${ROOT_PATH}faq`
export const UPLOAD_CHARGING_DATA = `ladevorgaenge-hochladen`
export const CHARGED_ENGERY = `energiemenge`
