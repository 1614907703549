const CustomTexts = {
  sidebar: [
    {
      title: 'Was ist OBELISgewerblich?',
      description:
        'OBELISgewerblich ist die Online-Plattform für die Berichterstattung zu geförderten, gewerblich genutzten Ladesäulen. Hier melden Sie Ihre Investitionsstandorte in Betrieb unter Angabe der Stammdaten Ihrer geförderten Ladesäulen. Sobald Ihre Angaben vollständig sind, wird Ihnen die Reporting-ID angezeigt, mit der Sie bei Ihrem Projektträger die Auszahlung der Fördermittel veranlassen können.',
    },
    {
      title: 'Erfassung der geladenen Energiemenge',
      description:
        'Die wiederkehrende Angabe Ihrer geladenen Energiemenge am Investitionsstandort ist optional und kann zu jeder Zeit erfolgen. Diese Angabe ist für wissenschaftliche Auswertungen im Bereich Nutzung von nicht öffentlicher Ladeinfrastruktur sehr wertvoll, ist jedoch keine Förderbedingung.',
    },
  ],
  support: {
    txt: 'Benötigen Sie Hilfe?',
    faqs: 'FAQs',
    emailTxt: 'oder E-Mail an:',
    email: 'obelis@now-gmbh.de',
  },
  impressum: { title: 'Impressum', link: 'https://www.now-gmbh.de/impressum/' },
  dataProtection: {
    title: 'Datenschutz',
    link: 'https://www.now-gmbh.de/datenschutzerklaerung/',
  },
  dashboard: {
    title: 'Ihr persönliches Dashboard',
    subtitle: 'Ihre Investitionsstandorte:',
  },
  menu: {
    profile: 'E-Mail und Passwort ändern',
    reportingList: 'Ihre Reporting IDs',
    faq: 'Hilfe',
    logout: 'Abmelden',
  },
  reportingIdList: {
    title: 'Liste Ihrer Reporting-IDs',
    buttonText: 'Reporting IDs herunterladen',
    locationHeading: 'Investitionstandort',
    reportingIdHeading: 'Reporting-ID',
  },
  faq: {
    title: 'Hilfe',
    buttonText: 'Hilfe herunterladen',
  },
  newReport: {
    title:
      'Angabe Ihrer Zuschussreferenznummer\nbeziehungsweise Ihres Förderkennzeichens',
    description:
      'Hier melden Sie die Ladesäulen an Ihrem Investitionsstandort in Betrieb und geben die Stammdaten dazu an.',
    postcodeLabel: 'PLZ des Antragstellenden',
    tooltipReferenceId:
      '<b>Unternehmen:</b> Ihre Zuschussreferenznummer besteht aus max. 15 Ziffern und wurde Ihnen von der KfW im Zuschussportal und per E-Mail mitgeteilt. <br/><br/> <b>Kommunen:</b> Ihre Zuschussnummer besteht aus max. 8 Ziffern und wurde Ihnen von der KfW per Zusageschreiben mitgeteilt. <br/> <br/><b>Projektträger Jülich:</b> Ihr Förderkennzeichen ist 10-stellig (Zahlen und Großbuchstaben) und wurde Ihnen von PtJ bei der Förderzusage mitgeteilt.',
    tooltipPostcode:
      'Bitte geben Sie hier die Postleitzahl Ihrer Adresse an (Unternehmenssitz oder Verwaltungssitz), die Sie auch bei der Antragstellung bei Ihrem Projektträger angegeben haben.',
  },
  reportStateTitle: 'Reporting',
  locationDetail: {
    startReportTitle: 'Starten Sie Ihre Berichterstattung über Ihre Ladesäulen',
    inputCard: {
      disabled:
        'Die Dateneingabe zur geladenen Energiemenge wird erst nach Vervollständigung der Stammdaten freigegeben.',
      title: 'Dateneingabe geladene Energiemenge',
      description: 'Hier können Sie die geladene Energiemenge eintragen.',
    },
    uploadCard: {
      disabled:
        'Der Upload der erfolgten Ladevorgänge wird erst nach Vervollständigung der Stammdaten freigegeben.',
      title: 'Upload erfolgter Ladevorgänge',
      description: 'Hier können Sie die erfolgten Ladevorgängen hochladen.',
    },
    completeReportTitle: 'Berichterstattung über Ihre Ladesäulen abgeschlossen',
    startDataEntry: 'Dateneingabe starten',
    missingReportingId:
      'Reporting-ID erscheint nach Abschluss der Meldung am Investitionsstandort',
    missingReportingIdCommune:
      'Reporting-ID erscheint nach vollständigem Reporting an allen Investitionsstandorten',
    copyReportingId: 'Reporting-ID kopieren',
  },
  form: {
    allFieldsRequired: 'Alle Angaben sind Pflichtfelder',
    maxFullElectricVehicles: 'Darf nicht größer als die Gesamtanzahl sein',
    maxtotalCompanyVehicles:
      'Die Summer aller Fahrzeugklassen darf nicht größer als die Gesamtanzahl sein',
    motivation: {
      motivationTitle: 'Motivation Ihrer Investition',
      motivationType: 'Warum haben Sie sich für die Investition entschieden?',
      acquireVehicles:
        'Haben Sie sich aufgrund der Fördermöglichkeit Ihrer eigenen Ladesäule in diesem Förderprogramm dazu entschieden, mindestens ein zusätzliches E-Fahrzeug für Ihr Unternehmen/ Ihre Kommune zu erwerben?',
      commercialUse: {
        accordionTitle: 'Gewerblicher Mobilitätsbezug',
        hasCommercialUse:
          'Hat Ihr Unternehmen einen gewerblichen Mobilitätsbezug?',
      },
    },
    site: {
      electricVehiclesTitle: 'E-Fahrzeuge an diesem Standort',
      existingVehicles:
        'Hat Ihr Unternehmen/ Ihre Kommune an diesem Standort bereits ein E-Fahrzeug?',
      vehicleType: 'Welche E-Fahrzeuge werden an diesem Standort laden?',
      locationRelatedVehicles:
        'Sind bzw. wären Ihre unternehmenseigenen/ kommuneneigenen E-Fahrzeuge eindeutig diesem Standort zuordenbar?',
      chargingLocations:
        'Wo laden Ihre unternehmenseigenen/ kommuneneigenen E-Fahrzeuge?',
      totalCompanyVehiclesTitle:
        'Wie viele unternehmenseigene/ kommuneneigene E-Fahrzeuge sollen regulär an diesem Standort laden?',
      totalCompanyVehicles: 'Gesamtanzahl',
      fullElectricCompanyVehicles:
        'Davon vollelektrisch (keine Plug-in-Hybride)',

      thereOfPkw: 'Davon Pkw',
      vehiclesN1: 'Davon Kraftfahrzeug-Klasse N1',
      vehiclesN2: 'Davon Kraftfahrzeug-Klasse N2',
      vehiclesN3: 'Davon Kraftfahrzeug-Klasse N3',
      vehiclesNotKnown: 'Weiß nicht',
      fullElectricUnknown: ' Anzahl vollelektrisch unbekannt',
      employeeVehicles:
        'Wie viele E-Fahrzeuge von Mitarbeitenden sollen regulär an diesem Standort laden?',
      networkOperatorTitle: 'Informationen zum Netzbetreiber',
      registeredAtNetOperator:
        'Haben Sie Ihre Ladesäulen beim Netzbetreiber angemeldet?',
      suppliesPerChargingStation:
        'Wie viele Netzanschlüsse gibt es an diesem Standort für Ihre geförderten Ladesäulen?',
      supplyCostsPerLocation:
        'Gesamtkosten Netzanschlüsse an diesem Standort in € (netto und auf volle Euros gerundet)?',
      supplyCostsPreliminary: '',
    },
    chargingStationGroup: {
      amount: 'Geben Sie die Anzahl der Ladesäulen mit gleichen Merkmalen ein:',
      amountTitle: 'Anzahl an Ladesäulen mit gleichen Merkmalen',
      generalInformationTitle: 'Allgemeine Informationen',
      launchDate: 'Inbetriebnahmedatum',
      manufacturer: 'Hersteller',
      model: 'Modell',
      location:
        'Hat Ihre Ladesäule ein IT-Backend, über das die einzelnen Ladevorgänge erfasst werden?',
      locationLabel: 'IT-Backend',
      chargingPointsTitle: 'Informationen zu den Ladepunkten',
      chargingPoints:
        'Wie viele Ladepunkte befinden sich an einer einzelnen Ladesäule? Bitte geben Sie die Anzahl der Ladepunkte je Ladeleistungs-Kategorie an. Falls Sie keine Ladepunkte in einer Kategorie haben sollten, tragen Sie bitte eine Null ein.',
      nLPkl11: 'Ladepunkte unter 11 kW',
      nLPgrgl11klgl22: 'Ladepunkte ab 11 kW bis 22 kW ',
      sLPgr22kl50: 'Ladepunkte über 22 kW unter 50 kW ',
      sLPgrgl50kl100: 'Ladepunkte ab 50 kW unter 100 kW',
      sLPgrgl100kl150: 'Schnellladepunkte von 100kW bis 149kW',
      sLPgrgl150kl300: 'Schnellladepunkte von 150kW bis 299kW',
      sLPgrgl300: 'Schnellladepunkte ab 300kW',
      electricityType: 'Welche Stromart nutzen Ihre Ladepunkte?',
      hardwareTitle: 'Kosten der Ladesäule',
      hardwareCosts:
        'Wie hoch sind die Hardware-Kosten einer Ladesäule in € (netto und auf volle Euros gerundet)?',
      hardwareCostsPreliminary: '',
    },
    complete: {
      questionnaire:
        'Sind Sie damit einverstanden für wissenschaftliche Zwecke an einer Umfrage teilzunehmen?',
      dataCorrectness:
        'Hiermit bestätige ich, dass die gemachten Angaben zu meinen Ladesäulen wahrheitsgemäß und vollständig sind.',
    },
  },
  summary: {
    usage: {
      motivation: 'Motivation Ihrer Investition',
      acquireVehicles:
        'Anschaffung E-Fahrzeug aufgrund der geförderten Ladesäulen in diesem Förderprogramm',
      commercialUse: 'Gewerblicher Bezug zur Elektromobilität',
    },
    site: {
      existingVehicles: 'Eigene E-Fahrzeuge am Standort',
      vehicleType: 'Nutzung der Ladesäule',
      locationRelatedVehicles: 'Eigene E-Fahrzeuge dem Standort zuordenbar',
      chargingLocations: 'Ladestandorte der eigenen E-Fahrzeuge',
      totalCompanyVehicles: 'Anzahl eigener E-Fahrzeuge am Standort',
      fullElectricCompanyVehicles:
        'Davon vollelektrisch (keine Plug-in-Hybride)',
      employeeVehicles:
        'Anzahl E-Fahrzeuge der Mitarbeitenden an diesem Standort',
      registeredAtNetOperator: 'Ladesäulen beim Netzbetreiber angemeldet',
      suppliesPerChargingStation: 'Anzahl Netzanschlüsse an diesem Standort',
      supplyCostState:
        'Gesamtkosten der Netzanschlüsse an diesem Standort in €',
      employeeVehiclesPkw: 'Davon Pkw',
      employeeVehiclesN1: 'Davon Kraftfahrzeug-Klasse N1',
      employeeVehiclesN2: 'Davon Kraftfahrzeug-Klasse N2',
      employeeVehiclesN3: 'Davon Kraftfahrzeug-Klasse N3',
      employeeVehiclesNotKnown: 'Weiß nicht',
      fullElectricCompanyPkw: 'Davon Pkw',
      fullElectricClassCompanyN1: 'Davon Kraftfahrzeug-Klasse N1',
      fullElectricClassCompanyN2: 'Davon Kraftfahrzeug-Klasse N2',
      fullElectricClassCompanyN3: 'Davon Kraftfahrzeug-Klasse N3',
      fullElectricCompanyNotKnown: 'Weiß nicht',
    },
    chargingStations: {
      sum: 'Gesamtzahl an gemeldeten Ladesäulen',
      sumChargingPoints: 'Gesamtzahl an gemeldeten Ladepunkten',
      titleGroup: 'Ladesäulengruppe',
      titleSingle: 'Ladesäule',
      amount: 'Anzahl an Ladesäulen',
      launchDate: 'Inbetriebnahmedatum',
      manufacturer: 'Hersteller',
      model: 'Modell',
      location: 'IT-Backend',
      electricityType: 'Stromart',
      hardwareCosts: 'Hardware-Kosten',
      nLPkl11: 'Ladepunkte unter 11 kW',
      nLPgrgl11klgl22: 'Ladepunkte ab 11 kW bis 22 kW ',
      sLPgr22kl50: 'Ladepunkte über 22 kW unter 50 kW ',
      sLPgrgl50kl100: 'Ladepunkte ab 50 kW unter 100 kW',
      sLPgrgl100kl150: 'Schnellladepunkte von 100kW bis 149kW',
      sLPgrgl150kl300: 'Schnellladepunkte von 150kW bis 299kW',
      sLPgrgl300: 'Schnellladepunkte ab 300kW',
    },
    headings: {
      usage: '1. Motivation',
      site: '2. Standort',
      chargingStations: '3. Ladesäulen',
    },
    checkboxes: {
      survey:
        'Sind Sie damit einverstanden für wissenschaftliche Zwecke an einer Umfrage teilzunehmen?',
      confirmation:
        'Hiermit bestätige ich, dass die gemachten Angaben zu meinen Ladesäulen wahrheitsgemäß und vollständig sind.',
      sumChargingPoints: 'Die Anzahl Ihrer in Betrieb gemeldeten Ladepunkte',
      kfwChargingPoints:
        'ist niedriger als die Anzahl Ihrer beantragten Ladepunkte. Hiermit bestätige ich, dass weniger Ladepunkte aufgebaut wurden als beantragt.',
    },
  },
  checklist: {
    title: 'Halten Sie folgende Informationen bereit:',
    list: [
      'Rechnung Ihrer Ladesäulen',
      'Inbetriebnahmeprotokoll Ihrer Ladesäulen',
    ],
  },
  chargingStations: {
    title: 'Angaben zu Ihren Ladesäulen',
    subtitle: 'Hier werden spezifische Merkmale zu Ihren Ladesäulen abgefragt.',
    howToCard: {
      titleOne: 'Haben Sie Ladesäulen mit gleichen Merkmalen?',
      contentOne:
        'Legen Sie diese in einer Ladesäulengruppe an. Es können mehrere Ladesäulengruppen nacheinander angelegt werden.',
      titleTwo:
        'Haben Sie Sie einzelne Ladesäulen mit jeweils unterschiedlichen Merkmalen?',
      contentTwo:
        'Es können mehrere einzelne Ladesäulen nacheinander angelegt werden.',
    },
    copyStationsDesc:
      'Möchten Sie die letzten eingetragenen Daten auf eine einzelne Ladesäulengruppe übertragen?',
    copySingleStationDesc:
      'Möchten Sie die letzten eingetragenen Daten auf eine einzelne Ladesäule übertragen?',
    deleteStationsDesc: 'Möchten Sie diese Ladesäulengruppe löschen?',
    deleteStationDesc: 'Möchten Sie diese Ladesäule löschen?',
    groupCards: {
      totalGroupAmount: 'Insgesamt am Standort gemeldete Ladesäulen: ',
      createdStationGroup: 'Ladesäulengruppe ',
      createdStation: 'Ladesäule',
      totalStationsAmount: 'Anzahl an Ladesäulen: ',
      manufacturer: 'Hersteller: ',
      model: 'Modell: ',
    },
    groupStationBtnText: '+ Ladesäulengruppe anlegen',
    singleStationBtnText: '+ Einzelne Ladesäule anlegen',
  },
  dialog: {
    copyTextSubmit: 'Ja, übernehmen',
    copyTextCancel: 'Nein, nicht übernehmen',
    deleteTextSubmit: 'Ja, Löschen',
    cancel: 'Nein, Abbrechen',
  },
  stepper: {
    titles: ['Motivation', 'Standort', 'Ladesäulen', 'Abschluss'],
    backBtnTitles: [
      'Zurück',
      'Zurück zu Motivation',
      'Zurück zu Standort',
      'Zurück und Angaben ändern',
    ],
    nextBtnTitles: [
      'Speichern und weiter',
      'Speichern und weiter',
      'Weiter zum Abschluss',
      'Bestätigen und abschließen',
    ],
    nextBtnDefaultTitle: 'Weiter',
    backBtnDefaultTitle: 'Zurück',
    usageTitle: 'Fragen zur Motivation',
    siteTitle: 'Fragen zum Standort Ihrer Ladesäule',
    completeReportTitle: 'Bitte überprüfen Sie Ihre Angaben',
    lockReportTitle: 'Übersicht Ihrer Angaben',
  },
  workflow: {
    title: 'In 3 Schritten Reporting abschließen',

    steps: [
      [
        {
          substring: '1. Investitionsstandort in\nBetrieb melden',
        },
      ],
      [
        {
          substring: '2. Nach Angabe der Stammdaten\nReporting-ID erhalten',
        },
      ],
      [
        {
          substring: '3. Bei Ihrem Projektträger\nFörderung auszahlen lassen',
        },
      ],
    ],
  },
  csvUpload: {
    title: 'Upload erfolgter Ladevorgänge',
    card: {
      title: 'Laden Sie hier Ihre Datei hoch (Excel oder CSV, max. 1 MB)',
      checkBoxLabel:
        'Mit Hochladen der Daten stimmen Sie der \nDatennutzung zu',
      button: 'Durchsuchen und hochladen',
    },
    success: 'Datei erfolgreich hochgeladen',
    warning: 'Datei ist zu groß. Datei darf maximal 1 MB groß sein.',
  },
  tooltips: {
    commercialUse:
      'Ihr Unternehmen hat einen gewerblichen Bezug zur Elektromobilität, wenn Ihre Unternehmenstätigkeit mit Elektromobilität im weitesten Sinne im Zusammenhang steht. Beispiel: Sie betreiben eine Kfz-Werkstatt, in der auch E-Fahrzeuge repariert werden. ',
    locationRelatedVehicles:
      'Sollten Ihre unternehmenseigenen E-Fahrzeuge in ihrer Nutzung zwischen verschiedenen Unternehmensstandorten rotieren und somit diesem Standort nicht eindeutig zuordenbar sein, geben Sie hier bitte "Nein" an. Andernfalls geben Sie bitte "Ja" an.',
    totalCompanyVehicles:
      'Bitte geben Sie hier die Gesamtanzahl an unternehmenseigenen E-Fahrzeugen an, die Sie perspektivisch an diesem Standort an Ihren geförderten Ladesäulen laden werden.',
    employeeVehicles:
      'Bitte geben Sie hier die Gesamtanzahl an E-Fahrzeugen Ihrer Mitarbeitenden an, die perspektivisch an diesem Standort an Ihren geförderten Ladesäulen laden werden.',
    supplyCostsPerLocation:
      'Bitte geben Sie hier die Gesamtkosten Ihrer Netzanschlüsse an, die Sie für die Stromversorgung Ihrer geförderten Ladesäulen nutzen. Falls Sie den vorhandenen Netzanschluss direkt nutzen konnten, geben Sie bitte hier nur die Kosten für das Anschließen der Ladesäulen an. Dazu gehören angefallene Kosten der damit verbundenen Baumaßnahmen. Sollten Ihnen hier keine Kosten entstanden sein, tragen Sie bitte eine Null ein.',
    amount:
      'Folgende Merkmale der Ladesäulen müssen gleich sein, um sie als eine Ladesäulengruppe anzulegen: <b> Inbetriebnahmedatum, Hersteller, Modell, Hardware-Kosten, Vorhandensein eines IT-Backends, Anzahl Ladepunkte.</b> Sollte sich mind. ein Merkmal unterscheiden, müssen die Ladesäulen einzeln angelegt werden. Sie können dabei die Angaben der zuletzt angelegten Ladesäule oder Ladesäulengruppe übernehmen und dann einzelne Angaben anpassen.',
    manufacturer:
      'Bitte wählen Sie hier den Hersteller Ihrer Ladesäule aus. Sollte der Hersteller Ihrer Ladesäule nicht in der Liste aufgeführt sein, schicken Sie bitte eine E-Mail mit den Namen des Herstellers und Modells Ihrer Ladesäule an den OBELIS-Support.',
    model:
      'Bitte wählen Sie hier das Modell Ihrer Ladesäule aus. Sollte das Modell Ihrer Ladesäule nicht in der Liste aufgeführt sein, schicken Sie bitte eine E-Mail mit dem Namen des Herstellers und Modells Ihrer Ladesäule an den OBELIS-Support.',
    hardwareCosts:
      'Bitte geben Sie hier die Kosten einer einzelnen Ladesäule an und berücksichtigen Sie dabei nur die Anschaffungskosten des Ladesäulenmodells (Hardware).',
    upload:
      'Die Datei erhalten Sie von dem Anbieter des IT-Backends Ihrer Ladesäule. Jede Zeile der Datei entspricht einem Ladevorgang.',
  },
  validation: {
    maxChargingStations:
      'Die Anzahl der Ladesäulen darf die Zahl 100 nicht überschreiten',
    minChargingStations:
      'Die Anzahl der Ladesäulen darf die Zahl 1 nicht unterschreiten',
  },
  chargingOperational: {
    title: 'Dateneingabe geladene Energiemenge',
    unknownCheckbox: 'Geladene Energiemenge kann nicht angegeben werden',
    referencePeriodTitle: 'Bezugszeitraum',
    beginLabel: 'Beginn',
    endLabel: 'Ende',
    energyAmountTitle: 'Geladene Energiemenge an diesem Standort in kWh',
    sumLabel: 'Summe',
    recordingMethodTitle: 'Wie wurde die geladene Energiemenge ermittelt?',
    recordingMethodLabel: 'Ermittlungsart',
  },
  adminPanel: {
    title: 'Admin-Bereich - Support von Nutzern',
    filterText: {
      title: 'Filter',
      label: 'Filter',
      placeholder: 'Filterauswahl',
    },
    customFilter: {
      title: 'Eigene Filter',
      label: 'Datenbank Query für Filter',
      placeholder: 'SELECT * FROM mytable WHERE status = example',
    },
    subject: 'Betreff',
    message: 'Nachricht schreiben',
    emailTitle: 'Email schreiben',
    recipientAmount: 'Anzahl der Empfänger',
    submitFilter: 'Filter anwenden',
  },
}

export default CustomTexts
