import {
  Box,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Form, Formik, FormikValues } from 'formik'
import React, { useContext } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de-AT'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import ColoredCard from '../components/Card/ColoredCard'
import FormikDatepicker from '../components/Input/FormikDatepicker'
import {
  fontWhite,
  primaryPetrol,
  primaryPetrol50,
} from '../components/Theme/CustomColors'
import CustomTitle from '../components/Title/CustomTitle'
import CustomTexts from '../utils/lang/CustomTexts'
import CustomTranslations from '../utils/lang/CustomTranslations'
import FormikCheckbox from '../components/Input/FormikCheckbox'
import { DefaultApi } from '../services'
import Yup from '../utils/lang/ValidationTranslation'
import { SnackbarContext } from '../context/Snackbar'
import CustomButton from '../components/Button/CustomButton'
import { CreateOperatingDataMethodOfRecordingEnum } from '../services/models/create-operating-data'
import { methodRecordingLabel } from '../utils/labelHelper'
import FormikSelect from '../components/Input/FormikSelect'
import FormikNumberField from '../components/Input/FormikNumberField'

const {
  title,
  unknownCheckbox,
  referencePeriodTitle,
  beginLabel,
  endLabel,
  energyAmountTitle,
  sumLabel,
  recordingMethodLabel,
  recordingMethodTitle,
} = CustomTexts.chargingOperational
const { SAVE_SUCCESS, SEND } = CustomTranslations

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      marginTop: '3rem',
      marginBottom: '2rem',
    },

    datePicker: {
      width: '253px',
      marginTop: '1rem',
      marginRight: '1rem',
    },
    disabledTitle: {
      opacity: 0.3,
    },
    dateBox: {
      marginBottom: '4rem',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  })
)

const YupDate = Yup.date()
  .nullable()
  .transform((v) => (v instanceof Date ? v : null))

const validationSchema = Yup.object().shape({
  referencePeriodStartDate: YupDate.when('energyAmountUnknown', {
    is: (energyAmountUnknown: boolean) => !energyAmountUnknown,
    then: YupDate.required(),
  }),
  referencePeriodEndDate: YupDate.when('energyAmountUnknown', {
    is: (energyAmountUnknown: boolean) => !energyAmountUnknown,
    then: YupDate.required(),
  }),
  energyAmount: Yup.number()
    .min(0)
    .when('energyAmountUnknown', {
      is: (energyAmountUnknown: boolean) => !energyAmountUnknown,
      then: Yup.number().min(0).required(),
    }),
  methodOfRecording: Yup.string().when('energyAmountUnknown', {
    is: (energyAmountUnknown: boolean) => !energyAmountUnknown,
    then: Yup.string().required(),
  }),
})

const ChargingOperationalForm = (props: {
  onSubmit: (values: FormikValues) => void
}): JSX.Element => {
  const classes = useStyles()

  const { onSubmit } = props

  const renderQuestionTitle = (
    questionTitle: string,
    disabled: boolean
  ): JSX.Element => {
    return (
      <Typography
        variant="h2"
        style={{ marginBottom: '1.2rem' }}
        className={disabled ? classes.disabledTitle : ''}
      >
        {questionTitle}
      </Typography>
    )
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <Formik
        initialValues={{
          referencePeriodStartDate: null,
          referencePeriodEndDate: null,
          energyAmount: '',
          energyAmountUnknown: false,
          methodOfRecording: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values)
          actions.resetForm()
        }}
      >
        {({ values, touched, errors }): JSX.Element => (
          <Form id="my-form" autoComplete="off">
            <FormikCheckbox
              name="energyAmountUnknown"
              label={unknownCheckbox}
            />

            <Divider className={classes.divider} />

            {renderQuestionTitle(
              referencePeriodTitle,
              values.energyAmountUnknown
            )}

            <Box className={classes.dateBox}>
              <Box className={classes.datePicker}>
                <FormikDatepicker
                  name="referencePeriodStartDate"
                  error={Boolean(
                    values.energyAmountUnknown
                      ? false
                      : touched.referencePeriodStartDate &&
                          errors.referencePeriodStartDate
                  )}
                  label={beginLabel}
                  disabled={values.energyAmountUnknown}
                  disableFuture
                  lightTheme
                />
              </Box>
              <br />
              <Box className={classes.datePicker}>
                <FormikDatepicker
                  name="referencePeriodEndDate"
                  error={Boolean(
                    values.energyAmountUnknown
                      ? false
                      : touched.referencePeriodEndDate &&
                          errors.referencePeriodEndDate
                  )}
                  label={endLabel}
                  disabled={values.energyAmountUnknown}
                  disableFuture
                  lightTheme
                />
              </Box>
            </Box>

            {renderQuestionTitle(energyAmountTitle, values.energyAmountUnknown)}

            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              mb={7}
            >
              <FormikNumberField
                name="energyAmount"
                label={sumLabel}
                error={Boolean(
                  values.energyAmountUnknown
                    ? false
                    : touched.energyAmount && errors.energyAmount
                )}
                disabled={values.energyAmountUnknown}
              />
            </Box>

            {renderQuestionTitle(
              recordingMethodTitle,
              values.energyAmountUnknown
            )}

            <FormikSelect
              name="methodOfRecording"
              label={recordingMethodLabel}
              error={Boolean(
                values.energyAmountUnknown
                  ? false
                  : touched.methodOfRecording && errors.methodOfRecording
              )}
              disabled={values.energyAmountUnknown}
              options={Object.values(
                CreateOperatingDataMethodOfRecordingEnum
              ).map((type) => {
                return {
                  title: methodRecordingLabel(type),
                  value: type,
                }
              })}
            />
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  )
}

const ChargingOperationalData = (): JSX.Element => {
  const defaultApi = new DefaultApi()
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()
  const navigate = useNavigate()
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)

  const handleSubmit = (values: FormikValues): void => {
    if (reportId && locationId)
      defaultApi
        .reportReportIdLocationLocationIdOperatingDataPost(
          locationId,
          reportId,
          values.energyAmountUnknown
            ? { energyAmountUnknown: values.energyAmountUnknown }
            : values
        )
        .then(() => {
          openSnackbar(SAVE_SUCCESS, 'success')
          navigate(-1)
        })
        .catch((e: AxiosError) => openSnackbarError(e))
  }

  return (
    <>
      <Grid container>
        <Grid item md={1} />
        <Grid item xs={12} md={7}>
          <CustomTitle title={title} />
          <Box width="100%">
            <ColoredCard
              backgroundcolor={primaryPetrol50}
              padding="3rem 2.5rem 4.5rem"
            >
              <ChargingOperationalForm onSubmit={handleSubmit} />
            </ColoredCard>
            <Box textAlign="right" mb={3}>
              <CustomButton
                fontcolor={fontWhite}
                backgroundcolor={primaryPetrol}
                form="my-form"
                type="submit"
                style={{ marginTop: '2.5rem' }}
              >
                {SEND}
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ChargingOperationalData
