import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { Form, Formik, FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../components/Button/CustomButton'
import ColoredCard from '../components/Card/ColoredCard'
import FormikSelect from '../components/Input/FormikSelect'
import FormikTextField from '../components/Input/FormikTextField'
import { primaryPetrol50 } from '../components/Theme/CustomColors'
import CustomTitle from '../components/Title/CustomTitle'
import { SnackbarContext } from '../context/Snackbar'
import { DefaultApi } from '../services/api/default-api'
import { UserEmailFilterListItem } from '../services/models/user-email-filter-list-item'
import CustomTexts from '../utils/lang/CustomTexts'
import CustomTranslations from '../utils/lang/CustomTranslations'

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      opacity: 0.3,
    },
  })
)

const Admin = (): JSX.Element => {
  const {
    title,
    filterText,
    customFilter,
    subject,
    message,
    emailTitle,
    recipientAmount,
    submitFilter,
  } = CustomTexts.adminPanel
  const defaultApi = new DefaultApi()
  const { openSnackbarError, openSnackbar } = useContext(SnackbarContext)
  const [options, setOptions] = useState<UserEmailFilterListItem[]>([])
  const [emails, setEmails] = useState<string[]>([])
  const classes = useStyles()

  useEffect(() => {
    defaultApi
      .restrictedUserEmailsFilterGet()
      .then((res) => setOptions(res.data))
  }, [])

  const handleFilter = (values: FormikValues): void => {
    const base64 = btoa(values.base64Query)
    defaultApi
      .restrictedUserEmailsQueryPost({
        base64Query: values.filter !== 'customFilter' ? values.filter : base64,
      })
      .then((res) => {
        openSnackbar(CustomTranslations.FILTER_SUCCESS, 'success')
        setEmails(res.data)
      })
      .catch((err) => openSnackbarError(err))
  }

  const handleSubmit = (values: FormikValues): void => {
    defaultApi
      .restrictedUserEmailsPost({
        mailList: emails,
        mailBody: values.body,
        mailSubject: values.subject.toString(),
      })
      .then(() =>
        openSnackbar(CustomTranslations.EMAIL_SEND_SUCCESS, 'success')
      )
      .catch((err) => openSnackbarError(err))
  }

  return (
    <Box height="inhreit" mx={8}>
      <CustomTitle title={title} />
      <ColoredCard backgroundcolor={primaryPetrol50} padding="2.5rem 6.7rem">
        <Formik
          enableReinitialize
          initialValues={{
            base64Query: '',
            subject: '',
            body: '',
            filter: '',
          }}
          onSubmit={(values): void => handleSubmit(values)}
        >
          {({ submitForm, values }): JSX.Element => (
            <Form autoComplete="off">
              <Box mb={9}>
                <Typography variant="h2" style={{ marginBottom: '1rem' }}>
                  {filterText.title}
                </Typography>
                <FormikSelect
                  name="filter"
                  label={filterText.label}
                  error={false}
                  options={[
                    ...options.map((item) => {
                      return {
                        title: item.name || '',
                        value: item.base64Query || '',
                      }
                    }),
                    { title: customFilter.title, value: 'customFilter' },
                  ]}
                />
              </Box>
              <Box
                mb={4}
                className={
                  values.filter !== 'customFilter' ? classes.disabled : ''
                }
              >
                <FormikTextField
                  name="base64Query"
                  label={customFilter.label}
                  placeholder={customFilter.placeholder}
                  title={customFilter.title}
                  maxwidth="620px"
                  typoVariant="h2"
                  type="number"
                  disabled={values.filter !== 'customFilter'}
                  multiline
                  minRows={4}
                  lightTheme
                />
              </Box>
              <Box display="block" mb={9} maxWidth="620px" textAlign="right">
                <CustomButton onClick={(): void => handleFilter(values)}>
                  {submitFilter}
                </CustomButton>
              </Box>
              <Box mb={9}>
                <Typography variant="h2" gutterBottom>
                  {recipientAmount}
                </Typography>
                <Typography variant="h4">{emails.length}</Typography>
              </Box>
              <FormikTextField
                name="subject"
                title={emailTitle}
                typoVariant="h2"
                label={subject}
                placeholder={subject}
                maxwidth="620px"
                type="text"
                lightTheme
              />
              <FormikTextField
                name="body"
                label={message}
                placeholder={message}
                maxwidth="620px"
                type="number"
                multiline
                minRows={10}
                lightTheme
              />
              <Box display="block" mt={4} maxWidth="620px" textAlign="right">
                <CustomButton onClick={submitForm}>
                  {CustomTranslations.SEND}
                </CustomButton>
              </Box>
            </Form>
          )}
        </Formik>
      </ColoredCard>
    </Box>
  )
}

export default Admin
