import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import { Field } from 'formik'
import { useStylesFilter } from './FormikTextField'
import CustomTranslations from '../../utils/lang/CustomTranslations'

export interface StylesProps {
  color: string
}

const FormikDatepicker = (props: {
  name: string
  label: string
  disableFuture?: boolean
  lightTheme?: boolean
  disabled?: boolean
  minDate?: Date
  error: boolean
}): JSX.Element => {
  const {
    disableFuture,
    minDate,
    name,
    label,
    lightTheme,
    error,
    disabled = false,
  } = props
  const styleProps: StylesProps = {
    color: lightTheme ? 'white' : 'default',
  }
  const classes = useStylesFilter(styleProps)
  const {
    INVALID_DATE,
    INVALID_MIN_DATE,
    INVALID_MAX_DATE,
    INVALID_DATE_FUTURE,
  } = CustomTranslations

  return (
    <Field
      component={KeyboardDatePicker}
      name={name}
      label={label}
      error={error}
      format="dd.MM.yyyy"
      inputVariant="filled"
      className={classes.root}
      color="secondary"
      fullWidth
      disabled={disabled}
      invalidDateMessage={INVALID_DATE}
      maxDateMessage={disableFuture ? INVALID_DATE_FUTURE : INVALID_MAX_DATE}
      minDateMessage={INVALID_MIN_DATE}
      disableFuture={disableFuture || false}
      minDate={minDate ?? undefined}
      disableToolbar
      variant="dialog"
      cancelLabel="Abbrechen"
    />
  )
}

export default FormikDatepicker
