import { Box, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import {
  Report,
  ReportListItem,
  ReportListItemLocations,
  ReportListItemReportTypeEnum,
  ReportListItemStateEnum,
  ReportLocations,
  ReportLocationsStateEnum,
} from '../../services'
import CustomTranslations from '../../utils/lang/CustomTranslations'
import ColoredCard from '../../components/Card/ColoredCard'
import {
  primaryPetrolSoft,
  secondarySensitiveGreen,
} from '../../components/Theme/CustomColors'
import { stateLabel, stateReportButtonLabel } from '../../utils/labelHelper'
import CustomButton from '../../components/Button/CustomButton'
import CustomTexts from '../../utils/lang/CustomTexts'
import { navigateLocationByType } from '../../hooks/RouteHooks'

interface ReportCardProps {
  report: ReportListItem | Report
  location?: ReportLocations
  isLocationListCard?: boolean
}

const ReportCard = ({
  report,
  location,
  isLocationListCard,
}: ReportCardProps): JSX.Element => {
  const { kfwReferenceId, kfwReportId, locations, reportType, id } = report
  const state = isLocationListCard ? location?.state : report.state
  const navigate = useNavigate()
  const isCompleted = isLocationListCard
    ? state === ReportLocationsStateEnum.Complete
    : state === ReportListItemStateEnum.Complete
  const isCompany = reportType === ReportListItemReportTypeEnum.Company
  const { KFW_REFERENCE_ID, PTJ_REFERENCE_ID, INVESTMENT_LOCATIONS } =
    CustomTranslations

  return (
    <Box minHeight="240px">
      <ColoredCard
        backgroundcolor={
          isCompleted ? secondarySensitiveGreen : primaryPetrolSoft
        }
        padding="1rem 1.55rem"
      >
        <>
          <Typography
            variant="body2"
            style={{ marginBottom: '0.34rem', minHeight: '1.35rem' }}
          >
            {!isLocationListCard &&
              `${
                report.reportType === ReportListItemReportTypeEnum.Ptj
                  ? PTJ_REFERENCE_ID
                  : KFW_REFERENCE_ID
              }: ${kfwReferenceId}`}
          </Typography>
          {locations && locations.length > 1 ? (
            <Box mb={3}>
              {location ? (
                <Box key={location.id} mb={3}>
                  <Typography variant="body1">
                    {location.address?.street}
                    <br />
                    {location.address?.postalCode} {location.address?.city}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body1" style={{ minHeight: '3.2rem' }}>
                  {locations?.length} {INVESTMENT_LOCATIONS}
                </Typography>
              )}
            </Box>
          ) : (
            <>
              {locations?.map(
                (item: ReportListItemLocations | ReportLocations) => (
                  <Box key={item.id} mb={2}>
                    <Typography variant="body1">
                      {item.address?.street}
                      <br />
                      {item.address?.postalCode} {item.address?.city}
                    </Typography>
                  </Box>
                )
              )}
            </>
          )}
          <Typography
            variant="h2"
            style={{
              marginTop: '1.4rem',
              color: 'white',
            }}
          >
            {CustomTexts.reportStateTitle} {state && stateLabel(state)}
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: 'white',
              minHeight: '1.8rem',
            }}
            gutterBottom
          >
            {kfwReportId && (
              <>
                {!isLocationListCard &&
                  `${CustomTranslations.REPORTING_ID}: ${kfwReportId}`}
              </>
            )}
          </Typography>
          <CustomButton
            fullWidth
            onClick={(): void =>
              navigateLocationByType(
                navigate,
                isCompany || (isLocationListCard ?? false),
                `${id}`,
                `${location ? location.id : (locations || [])[0].id}`
              )
            }
          >
            {stateReportButtonLabel(
              state as ReportListItemStateEnum | ReportLocationsStateEnum
            )}
          </CustomButton>
        </>
      </ColoredCard>
    </Box>
  )
}

export default ReportCard
