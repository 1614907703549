import { createTheme, CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { Shadows } from '@material-ui/core/styles/shadows'
import {
  primaryWhite,
  fontWhite,
  errorRed,
  warningYellow,
  successGreen,
  secondaryFrogGreen,
  secondaryGrasGreen,
  secondaryLightGreen,
  primaryPetrol,
  primaryPetrolLight,
  primaryPetrolSoft,
  secondaryBlackBlue,
  secondaryPaleOrange,
} from './CustomColors'
import CustomFonts from '../../assets/fonts/CustomFonts'

const theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Univers LT W01_47 Light, Roboto',
    h1: {
      fontFamily: 'Univers LT W01_57 Condensed',
      fontSize: 28,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: 'Univers LT W01_57 Condensed',
      fontSize: 24,
      letterSpacing: 0.15,
    },
    h3: {
      fontFamily: 'Univers LT W01_47 Light',
      fontSize: 20,
      letterSpacing: 0.15,
    },
    h4: {
      fontFamily: 'Univers LT W01_47 Light',
      fontSize: 20,
      letterSpacing: 0.15,
    },
    body1: {
      fontFamily: 'Univers LT W01_47 Light',
      fontSize: 17,
      letterSpacing: 0.5,
    },
    body2: {
      fontFamily: 'Univers LT W01_47 Light',
      fontSize: 14,
      letterSpacing: 0.5,
    },
    button: {
      fontFamily: 'Univers LT W01_47 Light',
      fontSize: 14,
      letterSpacing: 0.5,
      textTransform: 'capitalize',
    },
    caption: {
      fontFamily: 'Univers LT W01_47 Light',
      fontSize: 12,
      letterSpacing: 0.4,
    },
    overline: {
      fontFamily: 'Univers LT W01_57 Condensed',
      fontSize: 12,
      letterSpacing: 0.5,
    },
  },
  palette: {
    text: {
      primary: secondaryBlackBlue,
    },
    primary: {
      light: primaryPetrolSoft,
      dark: primaryPetrolLight,
      main: primaryPetrol,
      contrastText: fontWhite,
    },
    secondary: {
      light: secondaryLightGreen,
      main: secondaryGrasGreen,
      contrastText: fontWhite,
    },
    error: {
      main: errorRed,
      contrastText: fontWhite,
    },
    warning: {
      main: warningYellow,
      contrastText: fontWhite,
    },
    success: {
      main: successGreen,
      contrastText: fontWhite,
    },
  },
  shadows: Array(25).fill('none') as Shadows,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': CustomFonts,
        ':root': {
          '--amplify-primary-color': secondaryGrasGreen,
          '--amplify-primary-tint': secondaryGrasGreen,
          '--amplify-primary-shade': secondaryFrogGreen,
          '--amplify-font-family': 'Univers LT W01_47 Light, Roboto',
        },
        'amplify-authenticator': {
          '--min-width': '517px',
        },
        body: {
          margin: 0,
          backgroundColor: primaryWhite,
          '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
            {
              '-webkit-appearance': 'none',
              margin: 0,
            },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 200,
        padding: '6px 17px',
        fontSize: 15,
        textTransform: 'none',
        minWidth: '190px',
      },
      text: {
        minWidth: 'unset',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDivider: {
      root: {
        margin: '3.15rem 0',
        backgroundColor: primaryPetrolSoft,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: secondaryPaleOrange,
        color: secondaryBlackBlue,
        fontSize: 14,
        padding: '0.6rem 1.7rem ',
        boxShadow: '0px 4px 4px 0px #00000040',
        textAlign: 'center',
      },
      arrow: {
        '&:before': {
          border: `1px solid ${secondaryPaleOrange}`,
          color: secondaryPaleOrange,
        },
      },
    },
  },
})
interface NowThemeProps {
  children: JSX.Element
}

const NowTheme = (props: NowThemeProps): JSX.Element => {
  const { children } = props
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default NowTheme
