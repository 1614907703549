import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroupProps,
} from '@material-ui/core'
import { Field } from 'formik'
import { RadioGroup } from 'formik-material-ui'
import { AccordionDetailsTitle } from '../Accordion/CustomAccordion'
import { secondaryBlackBlue } from '../Theme/CustomColors'
import { renderFormTooltip } from '../Tooltip/CustomTooltip'

type RadioProps = RadioGroupProps & {
  options: { title: string; value: boolean | string }[]
  error: boolean | undefined
  name: string
  formLabel: string
  tooltip?: string
}

export const booleanRadioType = [
  { title: 'Ja', value: 'true' },
  { title: 'Nein', value: 'false' },
]

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      color: 'inherit',
    },
    formControl: {
      '& .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-root.Mui-error': {
        color: secondaryBlackBlue,
      },
      '&.Mui-error, &.Mui-error .MuiRadio-root': {
        color: theme.palette.error.main,
      },
    },
    radioGroup: {
      width: '218px',
      marginRight: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1rem',
      },
    },
    radioGroupRow: {
      flexWrap: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  })
)

const FormikRadioGroup = (props: RadioProps): JSX.Element => {
  const { options, name, formLabel, tooltip, error, ...rest } = props
  const classes = useStyles()
  return (
    <>
      {renderFormTooltip(tooltip, '0')}
      <FormControl
        component="fieldset"
        fullWidth
        error={error}
        className={`${classes.formControl} ${error ? 'Mui-error' : ''}`}
      >
        <FormLabel component="legend" className={classes.formLabel}>
          <AccordionDetailsTitle title={formLabel} />
        </FormLabel>

        <Field
          component={RadioGroup}
          name={name}
          row
          className={classes.radioGroupRow}
          {...rest}
        >
          {options.map((option: { title: string; value: boolean | string }) => (
            <FormControlLabel
              key={option.title}
              value={option.value}
              control={<Radio />}
              label={option.title}
              className={classes.radioGroup}
            />
          ))}
        </Field>
      </FormControl>
    </>
  )
}

export default FormikRadioGroup
