import { NavigateFunction } from 'react-router-dom'
import { REPORT } from '../routes/routes'

export function navigateLocationById(
  navigate: NavigateFunction,
  reportId: string,
  locationId: string
): void {
  navigate(`${REPORT}/${reportId}/investitionsstandort/${locationId}`)
}

export function navigateLocationList(
  navigate: NavigateFunction,
  reportId: string
): void {
  navigate(`${REPORT}/${reportId}/investitionsstandorte`)
}

export function navigateLocationByType(
  navigate: NavigateFunction,
  isCompany: boolean,
  reportId: string,
  locationId: string
): void {
  if (isCompany)
    navigate(`${REPORT}/${reportId}/investitionsstandort/${locationId}`)
  else navigate(`${REPORT}/${reportId}/investitionsstandorte`)
}
