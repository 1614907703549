import { Translations } from '@aws-amplify/ui-components'

const CognitoTranslations = {
  USER_NOT_EXIST: 'User does not exist.',
  USER_ALREADY_EXIST: 'User already exists.',
  ACCOUNT_EMAIL_EXIST: 'An account with the given email already exists.',
  INVALID_PWD_FORMAT: 'Invalid password format.',
  INVALID_PWD_LENGTH:
    'Password did not conform with policy: Password not long enough',
  INVALID_USERNAME_CLIENT: 'Username/client id combination not found.',
  INVALID_USERNAME_PWD: 'Incorrect username or password.',
  PWD_ATTEMPTS_EXCEEDED: 'Password attempts exceeded.',
  INVALID_VERIFICATION_CODE:
    'Invalid verification code provided, please try again.',
  REGISTER_NOT_VERIFIED:
    'Cannot reset password for the user as there is no registered/verified email or phone_number',
  ATTEMPT_LIMIT_EXCEED: 'Attempt limit exceeded, please try after some time.',
}

const AmplifyTranslations = {
  [CognitoTranslations.USER_ALREADY_EXIST]: 'Dieser Benutzer existiert bereits',
  [CognitoTranslations.ACCOUNT_EMAIL_EXIST]:
    'Ein Konto mit der angegebenen E-Mail existiert bereits',
  [CognitoTranslations.INVALID_PWD_FORMAT]: 'Ungültiges Passwort-Format',
  [CognitoTranslations.INVALID_PWD_LENGTH]:
    'Passwort entsprach nicht der Richtlinie: Passwort nicht lang genug',
  [CognitoTranslations.INVALID_USERNAME_CLIENT]: 'E-Mail nicht gefunden.',
  [CognitoTranslations.USER_NOT_EXIST]:
    'Account nicht gefunden. Falls Sie Ihren Account gerade erst angelegt haben, aktivieren Sie ihn bitte durch die Eingabe des Bestätigungscodes, den Sie per E-Mail erhalten haben. Sollten Sie noch keinen Account haben, registrieren Sie sich bitte und loggen Sie sich danach ein.',
  [CognitoTranslations.INVALID_USERNAME_PWD]:
    'Ungültige E-Mail-Adresse oder ungültiges Passwort',
  [CognitoTranslations.PWD_ATTEMPTS_EXCEEDED]:
    'Die maximale Anzahl der fehlerhaften Anmeldeversuche wurde erreicht',
  [CognitoTranslations.INVALID_VERIFICATION_CODE]:
    'Ungültiger Bestätigungscode angegeben. Bitte versuchen Sie es erneut.',
  [CognitoTranslations.REGISTER_NOT_VERIFIED]:
    'Passwort für den Benutzer kann nicht zurückgesetzt werden, da keine registrierte/verifizierte E-Mail vorhanden ist',
  [CognitoTranslations.ATTEMPT_LIMIT_EXCEED]:
    'Versuchslimit überschritten, bitte versuchen Sie es nach einiger Zeit.',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Custom Sign In Header Text',
  [Translations.SIGN_IN_ACTION]: 'Hier zur Anmeldung',
  [Translations.BACK_TO_SIGN_IN]: 'Zurück zur Anmeldung',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Ändern',
  [Translations.CHANGE_PASSWORD]: 'Passwort ändern',
  [Translations.CODE_LABEL]: 'Bestätigungscode',
  [Translations.CODE_PLACEHOLDER]: 'Bestätigungscode eingeben',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Bestätigungscode',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]:
    'Geben Sie den Bestätigungscode ein',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Registrierung bestätigen',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Bestätigungscode verloren?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Bestätigungscode erneut senden',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Bestätigen',
  [Translations.CONFIRM_SMS_CODE]: 'SMS Code bestätigen',
  [Translations.CONFIRM_TOTP_CODE]: 'TOTP Code bestätigen',
  [Translations.CONFIRM]: 'Bestätigen',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Neuen Account anlegen',
  [Translations.EMAIL_LABEL]: 'E-Mail-Adresse',
  [Translations.EMAIL_PLACEHOLDER]: 'E-Mail-Adresse eingeben',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]:
    'Es sind weniger als zwei MFA Typen verfügbar',
  [Translations.NEW_PASSWORD_LABEL]: 'Neues Passwort',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Neues Passwort eingeben',
  [Translations.NO_ACCOUNT_TEXT]: 'Noch kein Account?',
  [Translations.USERNAME_REMOVE_WHITESPACE]:
    'Der Username darf keine Leerzeichen enthalten',
  [Translations.PASSWORD_REMOVE_WHITESPACE]:
    'Das Passwort darf nicht mit einem Leerzeichen beginnen oder enden',
  [Translations.PASSWORD_LABEL]: 'Passwort',
  [Translations.PASSWORD_PLACEHOLDER]: 'Passwort eingeben',
  [Translations.PHONE_LABEL]: 'Telefon Nummer *',
  [Translations.PHONE_PLACEHOLDER]: '(555) 555-1212',
  [Translations.QR_CODE_ALT]: 'qrcode',
  [Translations.RESET_PASSWORD_TEXT]: 'Passwort zurücksetzen',
  [Translations.RESET_YOUR_PASSWORD]: 'Ihr Passwort zurücksetzen',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'Wähle einen MFA Typ',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Verifizieren',
  [Translations.SEND_CODE]: 'Zurücksetzen',
  [Translations.SUBMIT]: 'Bestätigen',
  [Translations.SETUP_TOTP_REQUIRED]: 'TOTP muss konfiguriert werden',
  [Translations.SIGN_IN_ACTION]: 'Anmelden',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Melden Sie sich bei Ihrem Konto an',
  [Translations.SIGN_IN_TEXT]: 'Hier Anmelden',
  [Translations.SIGN_IN_WITH_AMAZON]: 'Melden Sie sich mit Amazon an',
  [Translations.SIGN_IN_WITH_AUTH0]: 'Melden Sie sich mit Auth0 an',
  [Translations.SIGN_IN_WITH_AWS]: 'Melden Sie sich mit AWS an',
  [Translations.SIGN_IN_WITH_FACEBOOK]: 'Melden Sie sich mit Facebook an',
  [Translations.SIGN_IN_WITH_GOOGLE]: 'Melden Sie sich mit Google an',
  [Translations.SIGN_OUT]: 'Abmelden',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'E-Mail',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Sie sind bereits registriert?',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Einen neuen Account erstellen',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Passwort',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Registrieren',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Username',
  [Translations.SUCCESS_MFA_TYPE]: 'Erfolgreich! Ihr MFA Type ist:',
  [Translations.TOTP_HEADER_TEXT]:
    'Scannen Sie und geben Sie dann den Bestätigungscode ein',
  [Translations.TOTP_LABEL]: 'Geben Sie den Bestätigungscode ein:',
  [Translations.TOTP_ISSUER]: 'AWSCognito',
  [Translations.TOTP_SETUP_FAILURE]: 'TOTP Setup ist fehlgeschlagen',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Security Token verifizieren',
  [Translations.TOTP_SUCCESS_MESSAGE]: 'Setup TOTP erfolgreich!',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]:
    'Fehlgeschlagen! MFA kann derzeit nicht konfiguriert werden',
  [Translations.USERNAME_LABEL]: 'Username *',
  [Translations.USERNAME_PLACEHOLDER]: 'Geben Sie Ihren username ein',
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'E-Mail',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]:
    'Kontowiederherstellung erfordert verifizierte Kontaktinformationen',
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Telefon Nummer',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Bestätigen',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verifizieren',
  [Translations.ADDRESS_LABEL]: 'Adresse',
  [Translations.ADDRESS_PLACEHOLDER]: 'Geben Sie Ihre Adresse',
  [Translations.NICKNAME_LABEL]: 'Nickname',
  [Translations.NICKNAME_PLACEHOLDER]: 'Geben Sie Ihren nickname ein',
  [Translations.BIRTHDATE_LABEL]: 'Geburtstag',
  [Translations.BIRTHDATE_PLACEHOLDER]: 'Geben Sie Ihren Geburtstag ein',
  [Translations.PICTURE_LABEL]: 'Bild URL',
  [Translations.PICTURE_PLACEHOLDER]: 'Geben Sie Ihren Bild URL ein',
  [Translations.FAMILY_NAME_LABEL]: 'Nachname',
  [Translations.FAMILY_NAME_PLACEHOLDER]: 'Geben Sie Ihren Nachnamen ein',
  [Translations.PREFERRED_USERNAME_LABEL]: 'bevorzugter Benutzername',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]:
    'Geben Sie Ihren bevorzugten Benutzernamen ein',
  [Translations.GENDER_LABEL]: 'Geschlecht',
  [Translations.GENDER_PLACEHOLDER]: 'Geben Sie Ihr Geschlecht an',
  [Translations.PROFILE_LABEL]: 'Profil URL',
  [Translations.PROFILE_PLACEHOLDER]: 'Geben Sie Ihre Profil URL an',
  [Translations.GIVEN_NAME_LABEL]: 'Vorname',
  [Translations.GIVEN_NAME_PLACEHOLDER]: 'Geben Sie Ihren Vornamen an',
  [Translations.ZONEINFO_LABEL]: 'Zeitzone',
  [Translations.ZONEINFO_PLACEHOLDER]: 'Geben Sie Ihre Zeitzone an',
  [Translations.LOCALE_LABEL]: 'Ort',
  [Translations.LOCALE_PLACEHOLDER]: 'Geben Sie Ihren Ort an',
  [Translations.UPDATED_AT_LABEL]: 'Aktualisiert am',
  [Translations.UPDATED_AT_PLACEHOLDER]:
    'Enter the time the information was last updated',
  [Translations.MIDDLE_NAME_LABEL]: 'zweiter Vorname',
  [Translations.MIDDLE_NAME_PLACEHOLDER]: 'Geben Sie Ihren zweiten Vornamen an',
  [Translations.WEBSITE_LABEL]: 'Website',
  [Translations.WEBSITE_PLACEHOLDER]: 'Geben Sie Ihre Website an',
  [Translations.NAME_LABEL]: 'Vollständiger Name',
  [Translations.NAME_PLACEHOLDER]: 'Geben Sie Ihren vollständigen Namen ein',
  [Translations.PICKER_TEXT]: 'Wählen Sie eine Datei aus',
  [Translations.CONFIRM_SIGN_UP_FAILED]: 'Anmeldung fehlgeschlagen',
  [Translations.SIGN_UP_FAILED]: 'Registrierung fehlgeschlagen',
  [Translations.DEFAULT_MSG]: 'Authentifizierungsfehler',
  [Translations.EMPTY_EMAIL]: 'E-Mail darf nicht leer sein',
  [Translations.EMPTY_PHONE]: 'Telefonnummer darf nicht leer sein',
  [Translations.EMPTY_USERNAME]: 'Benutzername darf nicht leer sein',
  [Translations.INVALID_USERNAME]:
    'Der Benutzername sollte entweder eine Zeichenfolge oder einer der Anmeldetypen sein',
  [Translations.EMPTY_PASSWORD]: 'Passwort kann nicht leer sein',
  [Translations.EMPTY_CODE]: 'Bestätigungscode darf nicht leer sein',
  [Translations.SIGN_UP_ERROR]: 'Fehler beim Erstellen des Kontos',
  [Translations.NO_MFA]: 'Keine gültige MFA-Methode angegeben',
  [Translations.INVALID_MFA]: 'Ungültiger MFA-Typ',
  [Translations.EMPTY_CHALLENGE]:
    'Herausforderungsantwort darf nicht leer sein',
  [Translations.NO_USER_SESSION]:
    'Die Sitzung konnte nicht abgerufen werden, da der Benutzer leer ist',
  [Translations.NETWORK_ERROR]: 'Netzwerkfehler',
  [Translations.DEVICE_CONFIG]:
    'Geräteverfolgung wurde in diesem Benutzerpool nicht konfiguriert',
}

export default AmplifyTranslations
