import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import CustomTitle from '../../../../components/Title/CustomTitle'
import CustomTexts from '../../../../utils/lang/CustomTexts'
import SiteForm from '../../Form/SiteForm'
import { DefaultApi } from '../../../../services/api/default-api'
import LoadingAnimation from '../../../../components/Loader/LoadingAnimation'
import { SnackbarContext } from '../../../../context/Snackbar'
import { Site as SiteModel, SiteReportTypeEnum } from '../../../../services'
import PtjSiteForm from '../../Form/PtjSiteForm'

const Site = (): JSX.Element => {
  const defaultApi = new DefaultApi()
  const { openSnackbarError } = useContext(SnackbarContext)
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()
  const [loading, setLoading] = useState<boolean>(false)
  const [site, setSite] = useState<SiteModel>({})

  useEffect(() => {
    setLoading(true)
    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdSiteGet(locationId, reportId)
        .then((res) => {
          setSite(res.data)
          setLoading(false)
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
          setLoading(false)
        })
  }, [])
  return (
    <Grid container spacing={3}>
      <Grid item md={1} />
      <Grid item xs={12} md={7}>
        <CustomTitle title={CustomTexts.stepper.siteTitle} />
        {loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Box width="100%" textAlign="right">
              <Typography variant="body2">
                {CustomTexts.form.allFieldsRequired}
              </Typography>
            </Box>
            {site.reportType === SiteReportTypeEnum.Ptj ? (
              <PtjSiteForm site={site} />
            ) : (
              <SiteForm site={site} />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default Site
