import React, { useState, useEffect, useContext } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { AxiosError } from 'axios'
import CustomTexts from '../../utils/lang/CustomTexts'
import { primaryPetrolSoft } from '../../components/Theme/CustomColors'
import { NEW_REPORT } from '../../routes/routes'
import CustomLink from '../../components/Link/CustomLink'
import CustomTitle from '../../components/Title/CustomTitle'
import { DefaultApi } from '../../services/api/default-api'
import { ReportListItem } from '../../services'
import { SnackbarContext } from '../../context/Snackbar'
import ReportCard from './ReportCard'
import LoadingAnimation from '../../components/Loader/LoadingAnimation'
import CustomTranslations from '../../utils/lang/CustomTranslations'
import Workflow from './Workflow'
import CustomButton from '../../components/Button/CustomButton'
import LogoGrid from '../LogoGrid'

export const HEADER_HEIGHT = '38px'

export const useLogoGridStyles = makeStyles({
  root: {
    display: 'flex',
    height: `calc(100% - ${HEADER_HEIGHT})`,
    flexDirection: 'column',
  },

  reportWrapper: {
    backgroundColor: primaryPetrolSoft,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Dashboard = (): JSX.Element => {
  const defaultApi = new DefaultApi()
  const { openSnackbarError } = useContext(SnackbarContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [reports, setReports] = useState<ReportListItem[]>([])
  const classes = useLogoGridStyles()
  const { title, subtitle } = CustomTexts.dashboard

  useEffect(() => {
    setLoading(true)

    defaultApi
      .reportsGet()
      .then((res) => {
        setReports(res.data)
        setLoading(false)
      })
      .catch((e: AxiosError) => {
        openSnackbarError(e)
        setLoading(false)
      })
  }, [])

  return (
    <Box className={classes.root}>
      <CustomTitle title={title} subtitle={subtitle} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Workflow />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Box height={240} className={classes.reportWrapper} px={3}>
            <CustomLink to={NEW_REPORT}>
              <CustomButton fullWidth>
                {CustomTranslations.CREATE_REPORT_LABEL}
              </CustomButton>
            </CustomLink>
          </Box>
        </Grid>
        {loading ? (
          <Grid item xs={12} md={6} lg={4}>
            <LoadingAnimation />
          </Grid>
        ) : (
          <>
            {reports.map((report: ReportListItem) => (
              <Grid item xs={12} md={6} lg={4} key={report.id}>
                <ReportCard report={report} />
              </Grid>
            ))}
          </>
        )}
      </Grid>

      <LogoGrid showKfWLogo showPtjLogo />
    </Box>
  )
}

export default Dashboard
