import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { CognitoUserInterface } from '@aws-amplify/ui-components'

export function useUserAttributes(): Record<string, string> | undefined {
  const [userAttr, setUserAttr] = useState<Record<string, string>>()
  useEffect(() => {
    Auth.currentUserInfo()
      .then((user) => setUserAttr(user.attributes))
      .catch((err) => err)
  }, [])
  return userAttr
}

export function useAuthenticatedUser(): CognitoUserInterface | undefined {
  const [authUser, setAuthUser] = useState<CognitoUserInterface>()
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setAuthUser(user))
      .catch((err) => err)
  }, [])
  return authUser
}
