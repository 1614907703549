import { ReactNode, useContext } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import Yup from '../../../utils/lang/ValidationTranslation'
import {
  DefaultApi,
  Site,
  SiteChargingLocationsEnum,
  SiteExistingVehiclesEnum,
  SiteRegisteredAtNetOperatorEnum,
  SiteSupplyCostsStateEnum,
  SiteVehicleTypeEnum,
} from '../../../services'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomTranslations from '../../../utils/lang/CustomTranslations'
import BackButtonStepper from '../../../components/Button/BackButtonStepper'
import NextButtonStepper from '../../../components/Button/NextButtonStepper'
import StepperContext from '../../../context/Stepper'
import CustomAccordion, {
  AccordionDetailsTitle,
} from '../../../components/Accordion/CustomAccordion'
import FormikRadioGroup, {
  booleanRadioType,
} from '../../../components/Input/FormikRadioGroup'
import {
  chargingLocationsLabel,
  existingVehiclesLabel,
  networkOperatorRegisteredLabel,
  supplyCostsStateLabel,
  vehicleTypeLabel,
} from '../../../utils/labelHelper'
import CustomTexts from '../../../utils/lang/CustomTexts'
import FormikMultiSelect from '../../../components/Input/FormikMultiSelect'
import FormikCheckbox from '../../../components/Input/FormikCheckbox'
import FormikNumberField from '../../../components/Input/FormikNumberField'

interface SiteProps {
  site: Site
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  })
)

const {
  REQUIRED_MULTISELECT,
  AMOUNT_LABEL,
  COSTS_LABEL,
  SAVE_SUCCESS,
  LOCATIONS_LABEL,
} = CustomTranslations

const SiteForm = (props: SiteProps): JSX.Element => {
  const { site } = props
  const { reportId, locationId } =
    useParams<{ reportId: string; locationId: string }>()
  const defaultApi = new DefaultApi()
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const { setActiveStep } = useContext(StepperContext)
  const {
    existingVehicles: existingVehiclesText,
    electricVehiclesTitle,
    employeeVehicles: employeeVehiclesText,
    locationRelatedVehicles,
    vehicleType,
    totalCompanyVehicles,
    totalCompanyVehiclesTitle,
    fullElectricCompanyVehicles,
    fullElectricUnknown: fullElectricUnknownText,
    chargingLocations,
    networkOperatorTitle,
    registeredAtNetOperator,
    suppliesPerChargingStation,
    supplyCostsPerLocation,
  } = CustomTexts.form.site

  const existingVehiclesBoolValid = Yup.boolean().when('existingVehicles', {
    is: (existingVehicles: SiteExistingVehiclesEnum) =>
      existingVehicles !== SiteExistingVehiclesEnum.No,
    then: Yup.boolean().required(),
    otherwise: Yup.boolean().notRequired().nullable(),
  })

  const validationSchema = Yup.object().shape({
    existingVehicles: Yup.string()
      .oneOf(Object.values(SiteExistingVehiclesEnum))
      .required(),
    vehicleType: Yup.string()
      .oneOf(Object.values(SiteVehicleTypeEnum))
      .required(),
    locationRelatedVehicles: Yup.string()
      .oneOf(['true', 'false'])
      .when('existingVehicles', {
        is: (existingVehicles: SiteExistingVehiclesEnum) =>
          existingVehicles !== SiteExistingVehiclesEnum.No,
        then: Yup.string().oneOf(['true', 'false']).required(),
        otherwise: Yup.string().notRequired().nullable(),
      }),
    chargingLocations: Yup.array().when('existingVehicles', {
      is: (existingVehicles: SiteExistingVehiclesEnum) =>
        existingVehicles !== SiteExistingVehiclesEnum.No,
      then: Yup.array().min(1, REQUIRED_MULTISELECT).required(),
      otherwise: Yup.array().notRequired().nullable(),
    }),
    totalCompanyVehicles: Yup.number().when('existingVehicles', {
      is: (existingVehicles: SiteExistingVehiclesEnum) =>
        existingVehicles !== SiteExistingVehiclesEnum.No,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().notRequired().nullable(),
    }),
    fullElectricUnknown: existingVehiclesBoolValid,
    fullElectricCompanyVehicles: Yup.number().when(
      ['fullElectricUnknown', 'existingVehicles'],
      {
        is: (
          fullElectricUnknown: boolean,
          existingVehicles: SiteExistingVehiclesEnum
        ) => {
          if (fullElectricUnknown) return false
          if (existingVehicles === SiteExistingVehiclesEnum.No) return false
          return true
        },
        then: Yup.number()
          .min(0)
          .max(
            Yup.ref('totalCompanyVehicles'),
            CustomTexts.form.maxFullElectricVehicles
          )
          .required(),
        otherwise: Yup.number().notRequired().nullable(),
      }
    ),
    employeeVehicles: Yup.number().min(0).required(),
    registeredAtNetOperator: Yup.string()
      .oneOf(Object.values(SiteRegisteredAtNetOperatorEnum))
      .required(),
    suppliesPerChargingStation: Yup.number().min(0).required(),
    supplyCostsPerLocation: Yup.number().min(0).required(),
    supplyCostsState: Yup.string()
      .oneOf(Object.values(SiteSupplyCostsStateEnum))
      .required(),
  })

  const handleSubmit = (values: FormikValues): void => {
    const isSiteExistingVehicle =
      values.existingVehicles !== SiteExistingVehiclesEnum.No

    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdSitePut(locationId, reportId, {
          ...values,
          ...(isSiteExistingVehicle
            ? {
                locationRelatedVehicles:
                  values.locationRelatedVehicles === 'true',
                fullElectricUnknown: values.fullElectricUnknown,
                chargingLocations: values.chargingLocations ?? [],
                totalCompanyVehicles: values.totalCompanyVehicles,
                ...(values.fullElectricUnknown
                  ? {
                      fullElectricCompanyVehicles: undefined,
                    }
                  : {
                      fullElectricCompanyVehicles:
                        values.fullElectricCompanyVehicles,
                    }),
              }
            : {
                fullElectricCompanyVehicles: undefined,
                fullElectricUnknown: undefined,
                totalCompanyVehicles: undefined,
                locationRelatedVehicles: undefined,
                chargingLocations: undefined,
              }),
        })
        .then(() => {
          openSnackbar(SAVE_SUCCESS, 'success')
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        })
        .catch((e: AxiosError) => openSnackbarError(e))
  }
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={{
        existingVehicles: site.existingVehicles ?? '',
        vehicleType: site.vehicleType ?? '',
        locationRelatedVehicles: site.locationRelatedVehicles?.toString(),
        chargingLocations: site.chargingLocations ?? [],
        totalCompanyVehicles: site.totalCompanyVehicles ?? '',
        fullElectricUnknown: site.fullElectricUnknown ?? false,
        fullElectricCompanyVehicles: site.fullElectricCompanyVehicles ?? '',
        employeeVehicles: site.employeeVehicles ?? '',
        suppliesPerChargingStation: site.suppliesPerChargingStation ?? '',
        supplyCostsPerLocation: site.supplyCostsPerLocation ?? '',
        supplyCostsState: site.supplyCostsState ?? '',
        registeredAtNetOperator: site.registeredAtNetOperator ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values): void => handleSubmit(values)}
    >
      {({
        submitForm,
        dirty,
        setFieldValue,
        errors,
        touched,
        validateForm,
        isSubmitting,
        values,
      }): JSX.Element => (
        <Form autoComplete="off">
          <Box mt={2} mb={4}>
            <CustomAccordion
              id="electricVehicles"
              title={electricVehiclesTitle}
              defaultExpanded
            >
              <FormikRadioGroup
                name="existingVehicles"
                error={Boolean(
                  touched.existingVehicles && errors.existingVehicles
                )}
                formLabel={existingVehiclesText}
                options={Object.values(SiteExistingVehiclesEnum).map((type) => {
                  return {
                    title: existingVehiclesLabel(type),
                    value: type,
                  }
                })}
              />
              <Divider />
              <FormikRadioGroup
                name="vehicleType"
                error={Boolean(touched.vehicleType && errors.vehicleType)}
                formLabel={vehicleType}
                options={Object.values(SiteVehicleTypeEnum).map((type) => {
                  return {
                    title: vehicleTypeLabel(type),
                    value: type,
                  }
                })}
              />
              <Divider style={{ marginBottom: '1rem' }} />
              {values.existingVehicles !== SiteExistingVehiclesEnum.No ? (
                <>
                  <FormikRadioGroup
                    name="locationRelatedVehicles"
                    formLabel={locationRelatedVehicles}
                    tooltip={CustomTexts.tooltips.locationRelatedVehicles}
                    error={Boolean(
                      touched.locationRelatedVehicles &&
                        errors.locationRelatedVehicles
                    )}
                    options={booleanRadioType}
                  />
                  <Divider />
                  <AccordionDetailsTitle title={chargingLocations} />
                  <FormikMultiSelect
                    name="chargingLocations"
                    label={LOCATIONS_LABEL}
                    error={Boolean(
                      touched.chargingLocations && errors.chargingLocations
                    )}
                    errorMsg={errors.chargingLocations as string | undefined}
                    defaultValue={site.chargingLocations || []}
                    renderValue={(selected: unknown): ReactNode => {
                      const cast = selected as string[]
                      return cast
                        .map((item: string) =>
                          chargingLocationsLabel(
                            item as SiteChargingLocationsEnum
                          )
                        )
                        .join(', ')
                    }}
                    setFieldValue={setFieldValue}
                    options={Object.values(SiteChargingLocationsEnum).map(
                      (type) => {
                        return {
                          title: chargingLocationsLabel(type),
                          value: type,
                        }
                      }
                    )}
                  />
                  <Divider style={{ marginBottom: '1rem' }} />
                  <AccordionDetailsTitle
                    title={totalCompanyVehiclesTitle}
                    tooltip={CustomTexts.tooltips.totalCompanyVehicles}
                  />
                  <Box display="flex" mt={4} className={classes.wrapper}>
                    <Box mr={14}>
                      <FormikNumberField
                        name="totalCompanyVehicles"
                        label={AMOUNT_LABEL}
                        placeholder={AMOUNT_LABEL}
                        title={totalCompanyVehicles}
                        error={Boolean(
                          touched.totalCompanyVehicles &&
                            errors.totalCompanyVehicles
                        )}
                      />
                    </Box>
                    <Box>
                      <FormikNumberField
                        name="fullElectricCompanyVehicles"
                        label={AMOUNT_LABEL}
                        placeholder={AMOUNT_LABEL}
                        title={fullElectricCompanyVehicles}
                        error={Boolean(
                          touched.fullElectricCompanyVehicles &&
                            errors.fullElectricCompanyVehicles
                        )}
                        style={{ display: 'block' }}
                        disabled={Boolean(values.fullElectricUnknown)}
                      />
                      <FormikCheckbox
                        name="fullElectricUnknown"
                        label={fullElectricUnknownText}
                        disabled={Boolean(values.fullElectricCompanyVehicles)}
                      />
                    </Box>
                  </Box>
                  <Divider style={{ marginBottom: '1rem' }} />
                </>
              ) : (
                <></>
              )}
              <FormikNumberField
                name="employeeVehicles"
                label={AMOUNT_LABEL}
                placeholder={AMOUNT_LABEL}
                title={employeeVehiclesText}
                error={Boolean(
                  touched.employeeVehicles && errors.employeeVehicles
                )}
                tooltip={CustomTexts.tooltips.employeeVehicles}
              />
            </CustomAccordion>
            <CustomAccordion id="networkOperator" title={networkOperatorTitle}>
              <FormikRadioGroup
                name="registeredAtNetOperator"
                error={Boolean(
                  touched.registeredAtNetOperator &&
                    errors.registeredAtNetOperator
                )}
                formLabel={registeredAtNetOperator}
                options={Object.values(SiteRegisteredAtNetOperatorEnum).map(
                  (type) => {
                    return {
                      title: networkOperatorRegisteredLabel(type),
                      value: type,
                    }
                  }
                )}
              />
              <Divider />
              <FormikNumberField
                name="suppliesPerChargingStation"
                label={AMOUNT_LABEL}
                placeholder={AMOUNT_LABEL}
                title={suppliesPerChargingStation}
                error={Boolean(
                  touched.suppliesPerChargingStation &&
                    errors.suppliesPerChargingStation
                )}
              />
              <Divider style={{ marginBottom: '1rem' }} />
              <FormikNumberField
                name="supplyCostsPerLocation"
                label={COSTS_LABEL}
                placeholder="1000"
                title={supplyCostsPerLocation}
                error={Boolean(
                  touched.supplyCostsPerLocation &&
                    errors.supplyCostsPerLocation
                )}
                tooltip={CustomTexts.tooltips.supplyCostsPerLocation}
              />
              <br />
              <FormikRadioGroup
                name="supplyCostsState"
                error={Boolean(
                  touched.supplyCostsState && errors.supplyCostsState
                )}
                formLabel=""
                options={Object.values(SiteSupplyCostsStateEnum).map((type) => {
                  return {
                    title: supplyCostsStateLabel(type),
                    value: type,
                  }
                })}
              />
            </CustomAccordion>
          </Box>
          <BackButtonStepper />
          <NextButtonStepper
            disabled={isSubmitting}
            onClick={(): void => {
              validateForm().then((res) => {
                if (Object.keys(res).length > 0) {
                  openSnackbar(CustomTranslations.INVALID_FIELDS, 'error')
                }
                if (Object.keys(res).length === 0 && !dirty)
                  setActiveStep((prevActiveStep) => prevActiveStep + 1)
                else submitForm()
              })
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SiteForm
