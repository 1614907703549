import { useContext } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Route, useLocation, Routes } from 'react-router-dom'
import ErrorBoundary from '../components/Error/ErrorBoundaries'
import Dashboard from '../pages/Dashboard/Dashboard'
import Profile from '../pages/Profile'
import {
  CHARGED_ENGERY,
  DASHBOARD,
  FAQ,
  ID_LIST,
  NEW_REPORT,
  PROFILE,
  REPORT,
  REPORT_DATA,
  ROOT_PATH,
  UPLOAD_CHARGING_DATA,
} from './routes'
import UserAvatar from '../components/Avatar/UserAvatar'
import Sidebar from '../components/Navigation/Sidebar'
import CreateReport from '../pages/Report/CreateReport'
import LocationDetail from '../pages/Report/Location/LocationDetail'
import LocationList from '../pages/Report/Location/LocationList'
import { matchReportDataPath } from '../utils/locationPath'
import SnackbarProvider from '../context/Snackbar'
import LocationStepper from '../pages/Report/Location/Stepper/LocationStepper'
import ReportingIdList from '../pages/ReportingIdList'
import { ChargingUpload } from '../pages/ChargingUpload'
import ChargingOperationalData from '../pages/ChargingOperationalData'
import Admin from '../pages/Admin'
import { isAdminGroup } from '../utils/authHelper'
import FAQDownload from '../pages/FaqDownload'
import UserContext from '../context/User'

const NowRouter = (): JSX.Element => {
  const location = useLocation()
  const { user } = useContext(UserContext)
  const isRestrictedAdmin = isAdminGroup(user?.userGroups || [])
  const matchPath = matchReportDataPath(location.pathname)
  const useStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }))

  const classes = useStyles()

  return (
    <SnackbarProvider>
      <Box display="flex">
        <Sidebar isAdmin={isRestrictedAdmin} />
        <Box
          p={3}
          className={classes.root}
          width={matchPath ? 'calc(100% - 156px)' : 'calc(100% - 300px)'}
          minHeight="100vh"
        >
          <UserAvatar isAdmin={isRestrictedAdmin} />
          <Routes>
            <Route
              path={ROOT_PATH}
              element={
                <ErrorBoundary>
                  {isRestrictedAdmin ? <Admin /> : <Dashboard />}
                </ErrorBoundary>
              }
            />
            <Route
              path={DASHBOARD}
              element={
                <ErrorBoundary>
                  {isRestrictedAdmin ? <Admin /> : <Dashboard />}
                </ErrorBoundary>
              }
            />
            <Route
              path={NEW_REPORT}
              element={
                <ErrorBoundary>
                  <CreateReport />
                </ErrorBoundary>
              }
            />
            <Route
              path={`${REPORT}/:reportId/investitionsstandort/:locationId`}
              element={
                <ErrorBoundary>
                  <LocationDetail />
                </ErrorBoundary>
              }
            />
            <Route
              path={`${REPORT}/:reportId/investitionsstandort/:locationId/${REPORT_DATA}`}
              element={
                <ErrorBoundary>
                  <LocationStepper />
                </ErrorBoundary>
              }
            />
            <Route
              path={`${REPORT}/:reportId/investitionsstandorte`}
              element={
                <ErrorBoundary>
                  <LocationList />
                </ErrorBoundary>
              }
            />
            <Route
              path={PROFILE}
              element={
                <ErrorBoundary>
                  <Profile />
                </ErrorBoundary>
              }
            />
            <Route
              path={ID_LIST}
              element={
                <ErrorBoundary>
                  <ReportingIdList />
                </ErrorBoundary>
              }
            />
            <Route
              path={FAQ}
              element={
                <ErrorBoundary>
                  <FAQDownload />
                </ErrorBoundary>
              }
            />
            <Route
              path={`${REPORT}/:reportId/investitionsstandort/:locationId/${UPLOAD_CHARGING_DATA}`}
              element={
                <ErrorBoundary>
                  <ChargingUpload />
                </ErrorBoundary>
              }
            />
            <Route
              path={`${REPORT}/:reportId/investitionsstandort/:locationId/${CHARGED_ENGERY}`}
              element={
                <ErrorBoundary>
                  <ChargingOperationalData />
                </ErrorBoundary>
              }
            />
          </Routes>
        </Box>
      </Box>
    </SnackbarProvider>
  )
}

export default NowRouter
