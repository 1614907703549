import { useEffect, useState } from 'react'
import { DefaultApi } from '../services/api/default-api'
import { ChargingStationModelListItem } from '../services/models/charging-station-model-list-item'

export type ManufacturerType = { manufacturer: string }[]
export type ModelType = { model: string }[]

export function useManufacturerList(): ManufacturerType {
  const defaultApi = new DefaultApi()
  const [manufacturer, setManufacturer] = useState<ManufacturerType>([])
  useEffect(() => {
    defaultApi
      .reportChargingStationModelsGet('manufacturer')
      .then((res) => {
        const result = res.data as ManufacturerType
        setManufacturer(result)
      })
      .catch((err) => err)
  }, [])
  return manufacturer
}

export function useManufacturerModelList(): ChargingStationModelListItem[] {
  const defaultApi = new DefaultApi()
  const [list, setList] = useState<ChargingStationModelListItem[]>([])
  useEffect(() => {
    defaultApi
      .reportChargingStationModelsGet()
      .then((res) => {
        const result = res.data as ChargingStationModelListItem[]
        setList(result)
      })
      .catch((err) => err)
  }, [])
  return list
}

export function useModelList(): ModelType {
  const defaultApi = new DefaultApi()
  const [model, setModel] = useState<ModelType>([])
  useEffect(() => {
    defaultApi
      .reportChargingStationModelsGet('model')
      .then((res) => {
        const result = res.data as ModelType
        setModel(result)
      })
      .catch((err) => err)
  }, [])
  return model
}
