import React, { Dispatch, SetStateAction } from 'react'
import { Box, Dialog, DialogTitle } from '@material-ui/core'

interface CustomDialogProps {
  children: JSX.Element | JSX.Element[]
  title: string
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
}

const CustomDialog = (props: CustomDialogProps): JSX.Element => {
  const { children, title, open, handleClose } = props

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={`dialog-title-${title}`}
      open={open}
    >
      <Box p={6} pt={4}>
        <DialogTitle
          id={`dialog-title-${title}`}
          disableTypography
          style={{
            fontSize: '1.25rem',
            lineHeight: '25px',
          }}
        >
          {title}
        </DialogTitle>
        {children}
      </Box>
    </Dialog>
  )
}

export default CustomDialog
