import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components'
import { createContext } from 'react'

export type User = CognitoUserInterface & {
  email: string
  emailVerified: boolean
  authState: AuthState
  userGroups: string[]
}

type UserContextType = {
  user: User | undefined
  updateUser: (u: User) => void
}

const UserContext = createContext<UserContextType>({
  user: undefined,
  updateUser: (): void => {},
})

export default UserContext
