/* tslint:disable */
/* eslint-disable */
/**
 * lis-prod-rest-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2024-01-08T10:35:12Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    vehicleType?: SiteVehicleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    reportType?: SiteReportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    existingVehicles?: SiteExistingVehiclesEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Site
     */
    locationRelatedVehicles?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Site
     */
    chargingLocations?: Array<SiteChargingLocationsEnum>;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    totalCompanyVehicles?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    fullElectricCompanyVehicles?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    fullElectricClassCompanyN1?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    fullElectricClassCompanyN2?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    fullElectricClassCompanyN3?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    fullElectricCompanyPkw?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Site
     */
    fullElectricCompanyNotKnown?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Site
     */
    fullElectricUnknown?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    employeeVehicles?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    employeeVehiclesN1?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    employeeVehiclesN2?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    employeeVehiclesN3?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    employeeVehiclesPkw?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Site
     */
    employeeVehiclesNotKnown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    registeredAtNetOperator?: SiteRegisteredAtNetOperatorEnum;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    suppliesPerChargingStation?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    supplyCostsPerLocation?: number;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    supplyCostsState?: SiteSupplyCostsStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SiteVehicleTypeEnum {
    CompanyFleet = 'COMPANY_FLEET',
    Employee = 'EMPLOYEE',
    Both = 'BOTH'
}
/**
    * @export
    * @enum {string}
    */
export enum SiteReportTypeEnum {
    Commune = 'COMMUNE',
    Company = 'COMPANY',
    Ptj = 'PTJ'
}
/**
    * @export
    * @enum {string}
    */
export enum SiteExistingVehiclesEnum {
    Yes = 'YES',
    No = 'NO',
    Planned = 'PLANNED'
}
/**
    * @export
    * @enum {string}
    */
export enum SiteChargingLocationsEnum {
    ThisSite = 'THIS_SITE',
    OtherCompanySites = 'OTHER_COMPANY_SITES',
    PublicSites = 'PUBLIC_SITES',
    PrivateSites = 'PRIVATE_SITES'
}
/**
    * @export
    * @enum {string}
    */
export enum SiteRegisteredAtNetOperatorEnum {
    Yes = 'YES',
    No = 'NO',
    Unknown = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SiteSupplyCostsStateEnum {
    Preliminary = 'PRELIMINARY',
    Invoice = 'INVOICE'
}



