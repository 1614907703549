import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Amplify, Auth, I18n } from 'aws-amplify'
import {
  AuthState,
  CognitoUserInterface,
  onAuthUIStateChange,
} from '@aws-amplify/ui-components'
import { BrowserRouter } from 'react-router-dom'
import config from './aws-exports.js'
import NowRouter from './routes/NowRouter'
import AmplifyTranslations from './utils/lang/AmplifyTranslations'
import AmplifyAuth from './components/Auth/AmplifyAuth'
import UserContext, { User } from './context/User'

Amplify.configure(config)
I18n.setLanguage('de-DE')
I18n.putVocabulariesForLanguage('de-DE', AmplifyTranslations)

function App(): JSX.Element {
  const [authState, setAuthState] = useState<AuthState>()
  const [user, setUser] = useState<User | undefined>()

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      const userData = authData as CognitoUserInterface
      const userObj = {
        ...userData,
        authState: nextAuthState,
        userGroups:
          userData && userData.signInUserSession
            ? userData.signInUserSession?.idToken.payload['cognito:groups']
            : '',
      }
      setAuthState(nextAuthState)

      if (AuthState.SignedIn === nextAuthState) {
        Auth.currentUserInfo().then((currentUserInfo) => {
          const { attributes } = currentUserInfo
          setUser({
            ...userObj,
            emailVerified: attributes.email_verified,
            email: attributes.email,
          } as User | undefined)
        })
      } else {
        setUser(userObj as User | undefined)
      }
    })
  }, [])

  return authState === AuthState.SignedIn && user ? (
    <div className="App" style={{ flexGrow: 1 }}>
      <UserContext.Provider
        value={{
          user,
          updateUser: (u: User): void => setUser(u),
        }}
      >
        <BrowserRouter>
          <NowRouter />
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  ) : (
    <Box px={12}>
      <AmplifyAuth authState={authState} user={user} />
    </Box>
  )
}

export default App
