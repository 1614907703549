import * as yup from 'yup'

// Setting Translation for yup in own module and exporting it afterwards, seems to be the only way to provide global translation for yup. See: https://github.com/jquense/yup/issues/293#issue-352221231
yup.setLocale({
  mixed: {
    required: 'Pflichtfeld *',
  },
  string: {
    email: 'Geben Sie eine gültige Email-Adresse ein',
  },
  number: {
    min: 'Die eingegebene Zahl darf nicht negativ sein',
  },
})

export default yup
