import {
  ChargingStationGroupElectricityTypeEnum,
  ChargingStationGroupHardwareCostsStateEnum,
  ChargingStationGroupLocationEnum,
  LocationUsageCommercialUseEnum,
  LocationUsageMotivationTypeEnum,
  ReportChargingStationsElectricityTypeEnum,
  ReportChargingStationsHardwareCostsStateEnum,
  ReportChargingStationsLocationEnum,
  ReportListItemStateEnum,
  ReportLocationsStateEnum,
  ReportLocationUsageCommercialUseEnum,
  ReportLocationUsageMotivationTypeEnum,
  ReportSiteChargingLocationsEnum,
  ReportSiteExistingVehiclesEnum,
  ReportSiteRegisteredAtNetOperatorEnum,
  ReportSiteSupplyCostsStateEnum,
  ReportSiteVehicleTypeEnum,
  ReportStateEnum,
  SiteChargingLocationsEnum,
  SiteExistingVehiclesEnum,
  SiteRegisteredAtNetOperatorEnum,
  SiteSupplyCostsStateEnum,
  SiteVehicleTypeEnum,
} from '../services/models'
import { CreateOperatingDataMethodOfRecordingEnum } from '../services/models/create-operating-data'

export const stateLabel = (
  state: ReportListItemStateEnum | ReportLocationsStateEnum | ReportStateEnum
): string => {
  const labels = {
    [ReportListItemStateEnum.Complete]: 'vollständig',
    [ReportListItemStateEnum.Cancelled]: 'storniert',
    [ReportListItemStateEnum.Incomplete]: 'unvollständig',
    [ReportLocationsStateEnum.Step1Complete]: 'unvollständig',
    [ReportLocationsStateEnum.Step2Complete]: 'unvollständig',
    [ReportLocationsStateEnum.Step3Complete]: 'unvollständig',
  }
  return labels[state]
}

export const stateButtonLabel = (
  state: ReportListItemStateEnum | ReportLocationsStateEnum
): string => {
  const labels = {
    [ReportListItemStateEnum.Complete]: 'Ansehen',
    [ReportListItemStateEnum.Cancelled]: 'storniert',
    [ReportListItemStateEnum.Incomplete]: `Los geht's`,
    [ReportLocationsStateEnum.Step1Complete]: 'Formulareingaben fortsetzen',
    [ReportLocationsStateEnum.Step2Complete]: 'Formulareingaben fortsetzen',
    [ReportLocationsStateEnum.Step3Complete]: 'Formulareingaben fortsetzen',
  }
  return labels[state]
}

export const stateReportButtonLabel = (
  state: ReportListItemStateEnum | ReportLocationsStateEnum
): string => {
  const labels = {
    [ReportLocationsStateEnum.Complete]: 'Energiemenge erfassen',
    [ReportLocationsStateEnum.Cancelled]: 'storniert',
    [ReportLocationsStateEnum.Incomplete]: 'Fortsetzen',
    [ReportLocationsStateEnum.Step1Complete]: 'Fortsetzen',
    [ReportLocationsStateEnum.Step2Complete]: 'Fortsetzen',
    [ReportLocationsStateEnum.Step3Complete]: 'Fortsetzen',
  }
  return labels[state]
}

export const usageMotivationTypeLabel = (
  state: LocationUsageMotivationTypeEnum | ReportLocationUsageMotivationTypeEnum
): string => {
  const labels = {
    [LocationUsageMotivationTypeEnum.CompanyImage]:
      'Unternehmensimage/ Kommunenimage',
    [LocationUsageMotivationTypeEnum.CostSaving]: 'Kosteneinsparungen',
    [LocationUsageMotivationTypeEnum.EmissionReduction]:
      'Logistische Vorteile durch eigene Ladesäule',
    [LocationUsageMotivationTypeEnum.EmployeeSatisfaction]:
      'Steigerung der Zufriedenheit der Mitarbeitenden',
    [LocationUsageMotivationTypeEnum.RenewableEnergy]:
      'Nutzung von selbstproduziertem Strom aus erneuerbaren Energien',
    [LocationUsageMotivationTypeEnum.SustainableMobility]:
      'Beitrag zur nachhaltigen Mobilität',
    [LocationUsageMotivationTypeEnum.Others]: 'Sonstiges',
  }
  return labels[state]
}

export const usageCommercialUseLabel = (
  state: LocationUsageCommercialUseEnum | ReportLocationUsageCommercialUseEnum
): string => {
  const labels = {
    [LocationUsageCommercialUseEnum.Yes]: 'Ja',
    [LocationUsageCommercialUseEnum.No]: 'Nein',
    [LocationUsageCommercialUseEnum.Unknown]: 'Keine Angabe',
  }
  return labels[state]
}

export const existingVehiclesLabel = (
  state: SiteExistingVehiclesEnum | ReportSiteExistingVehiclesEnum
): string => {
  const labels = {
    [SiteExistingVehiclesEnum.No]: 'Nein',
    [SiteExistingVehiclesEnum.Planned]:
      'Nein, die Anschaffung ist bereits geplant',
    [SiteExistingVehiclesEnum.Yes]: 'Ja',
  }
  return labels[state]
}

export const networkOperatorRegisteredLabel = (
  state: SiteRegisteredAtNetOperatorEnum | ReportSiteRegisteredAtNetOperatorEnum
): string => {
  const labels = {
    [SiteRegisteredAtNetOperatorEnum.No]: 'Nein',
    [SiteRegisteredAtNetOperatorEnum.Unknown]: 'Keine Angabe',
    [SiteRegisteredAtNetOperatorEnum.Yes]: 'Ja',
  }
  return labels[state]
}

export const supplyCostsStateLabel = (
  state: SiteSupplyCostsStateEnum | ReportSiteSupplyCostsStateEnum
): string => {
  const labels = {
    [SiteSupplyCostsStateEnum.Invoice]: 'Per Rechnung festgesetzt',
    [SiteSupplyCostsStateEnum.Preliminary]: 'Vorläufig kalkuliert',
  }
  return labels[state]
}

const companyFleet =
  'Unternehmenseigene / kommuneneigene E-Fahrzeuge (Flottenladen)'
const employeeFleet = 'E-Fahrzeuge der Mitarbeitenden (Mitarbeiterladen)'

export const vehicleTypeLabel = (state: SiteVehicleTypeEnum): string => {
  const labels = {
    [SiteVehicleTypeEnum.Both]: 'Flottenladen und Mitarbeiterladen',
    [SiteVehicleTypeEnum.CompanyFleet]: companyFleet,
    [SiteVehicleTypeEnum.Employee]: employeeFleet,
  }
  return labels[state]
}

export const vehicleTypeLabelReport = (
  state: ReportSiteVehicleTypeEnum
): string => {
  const labels = {
    [ReportSiteVehicleTypeEnum.CompanyFleet]: companyFleet,
    [ReportSiteVehicleTypeEnum.Employee]: employeeFleet,
    [ReportSiteVehicleTypeEnum.Both]: `${companyFleet} Und ${employeeFleet}`,
  }

  return labels[state]
}

export const chargingLocationsLabel = (
  state: SiteChargingLocationsEnum | ReportSiteChargingLocationsEnum
): string => {
  const labels = {
    [SiteChargingLocationsEnum.OtherCompanySites]:
      'an anderen firmeneigenen Standorten',
    [SiteChargingLocationsEnum.PrivateSites]: 'an privaten Lademöglichkeiten',
    [SiteChargingLocationsEnum.PublicSites]:
      'an öffentlichen Lademöglichkeiten',
    [SiteChargingLocationsEnum.ThisSite]: 'an diesem Standort',
  }
  return labels[state]
}

export const chargingStationGroupLocationLabel = (
  state: ChargingStationGroupLocationEnum | ReportChargingStationsLocationEnum
): string => {
  const labels = {
    [ChargingStationGroupLocationEnum.Yes]: 'Ja',
    [ChargingStationGroupLocationEnum.No]: 'Nein',
    [ChargingStationGroupLocationEnum.Unknown]: 'Keine Angabe',
  }
  return labels[state]
}

export const electricityTypeLabel = (
  state:
    | ChargingStationGroupElectricityTypeEnum
    | ReportChargingStationsElectricityTypeEnum
): string => {
  const labels = {
    [ChargingStationGroupElectricityTypeEnum.Ac]: 'Wechselstrom (AC)',
    [ChargingStationGroupElectricityTypeEnum.Dc]: 'Gleichstrom (DC)',
  }
  return labels[state]
}

export const hardwareCostStateLabel = (
  state:
    | ChargingStationGroupHardwareCostsStateEnum
    | ReportChargingStationsHardwareCostsStateEnum
): string => {
  const labels = {
    [ChargingStationGroupHardwareCostsStateEnum.Invoice]:
      'Per Rechnung festgesetzt',
    [ChargingStationGroupHardwareCostsStateEnum.Preliminary]:
      'Vorläufig kalkuliert',
  }
  return labels[state]
}

export const reportTitleLabel = (
  state: ReportListItemStateEnum | ReportLocationsStateEnum
): string => {
  const CONTINUE =
    'Reporting zu den geförderten Ladesäulen an Ihren Investitionsstandort unvollständig'

  const labels = {
    [ReportListItemStateEnum.Complete]:
      'Reporting zu den geförderten Ladesäulen an Ihrem Investitionsstandort abgeschlossen.',
    [ReportListItemStateEnum.Cancelled]: 'Reporting storniert',
    [ReportListItemStateEnum.Incomplete]: `Starten Sie das Reporting zu den geförderten Ladesäulen an Ihrem Investitionsstandort`,
    [ReportLocationsStateEnum.Step1Complete]: CONTINUE,
    [ReportLocationsStateEnum.Step2Complete]: CONTINUE,
    [ReportLocationsStateEnum.Step3Complete]: CONTINUE,
  }
  return labels[state]
}

export const methodRecordingLabel = (
  state: CreateOperatingDataMethodOfRecordingEnum
): string => {
  const labels = {
    [CreateOperatingDataMethodOfRecordingEnum.ElectricityMeterDiff]:
      'Differenz aus Zählerständen',
    [CreateOperatingDataMethodOfRecordingEnum.Estimation]: 'Geschätzt',
    [CreateOperatingDataMethodOfRecordingEnum.Invoice]: 'Stromrechnung',
    [CreateOperatingDataMethodOfRecordingEnum.ItBackend]: 'IT-Backend',
    [CreateOperatingDataMethodOfRecordingEnum.Kilometers]:
      'Abgeleitet aus elektrisch gefahrenen Kilometern',
    [CreateOperatingDataMethodOfRecordingEnum.Others]: 'Sonstiges',
  }

  return labels[state]
}
