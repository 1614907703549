/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:5833ec94-e8b3-4ecf-b353-b0814f1de904",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_qyBzU0A6E",
    "aws_user_pools_web_client_id": "f17b63jklfmsgnrl6bofr8pmt",
    "oauth": {}
};


export default awsmobile;
