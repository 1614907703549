import React, { useContext } from 'react'
import { Form, Formik, FormikValues, Field } from 'formik'
import { Box, Grid, makeStyles, TextFieldProps } from '@material-ui/core'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { TextField } from 'formik-material-ui'
import Yup from '../../../utils/lang/ValidationTranslation'
import {
  DefaultApi,
  CreateReport,
  ReportReportTypeEnum,
} from '../../../services'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomTranslations from '../../../utils/lang/CustomTranslations'
import CustomTexts from '../../../utils/lang/CustomTexts'
import CustomTooltip from '../../../components/Tooltip/CustomTooltip'
import CustomButton from '../../../components/Button/CustomButton'
import {
  secondaryBlackBlue,
  inputLightGreenBg,
} from '../../../components/Theme/CustomColors'
import { navigateLocationByType } from '../../../hooks/RouteHooks'

const useStyles = makeStyles({
  icon: {
    marginLeft: '0.65rem',
    marginTop: '0.35rem',
  },

  button: {
    marginBottom: '1rem',
    float: 'right',
    marginRight: '2rem',
  },

  inputForm: {
    maxWidth: '400px',

    '& .MuiInputLabel-filled': {
      color: secondaryBlackBlue,
      fontSize: '1rem',
    },

    '& .MuiFilledInput-root': {
      backgroundColor: inputLightGreenBg,
      border: '0.1rem solid white',
      borderRadius: 0,
    },

    '& .MuiFilledInput-underline:before': {
      border: 'none',
    },
  },
})

const Input = (props: TextFieldProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Field
      component={TextField}
      fullWidth
      variant="filled"
      className={classes.inputForm}
      disabled={false}
      size="small"
      style={{ paddingRight: '1rem' }}
      {...props}
    />
  )
}

const {
  KFW_ERROR,
  POSTCODE_ERROR,
  CREATE_REPORT_SUCCESS,
  KFW_REFERENCE_ID_FUNDING,
  SEND_REPORT,
} = CustomTranslations

const validationSchema = Yup.object().shape({
  kfwReferenceId: Yup.string()
    .required()
    .test('len', KFW_ERROR, (val) => (val?.length || 0) <= 15),
  reporterPostalCode: Yup.string()
    .required()
    .test('len', POSTCODE_ERROR, (val) => (val?.length || 0) === 5),
})

const CreateReportForm = (): JSX.Element => {
  const navigate = useNavigate()
  const defaultApi = new DefaultApi()
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const classes = useStyles()
  const { tooltipPostcode, postcodeLabel, tooltipReferenceId } =
    CustomTexts.newReport

  const handleCreate = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void
  ): void => {
    defaultApi
      .reportPut({
        kfwReferenceId: values.kfwReferenceId,
        reporterPostalCode: values.reporterPostalCode,
      } as CreateReport)
      .then((res) => {
        openSnackbar(CREATE_REPORT_SUCCESS, 'success')
        navigateLocationByType(
          navigate,
          res.data.reportType === ReportReportTypeEnum.Company,
          `${res.data.id}`,
          `${(res.data.locations ?? [])[0].id}`
        )
      })
      .catch((e: AxiosError) => openSnackbarError(e))
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        kfwReferenceId: '',
        reporterPostalCode: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }): void =>
        handleCreate(values, setSubmitting)
      }
    >
      {({ values, submitForm, isValid, isSubmitting }): JSX.Element => (
        <Form autoComplete="off">
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={4} mt={2}>
                <Input
                  name="reporterPostalCode"
                  type="string"
                  placeholder="01139"
                  label={postcodeLabel}
                />
                <CustomTooltip title={tooltipPostcode} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="baseline" mb={5}>
                <Input
                  name="kfwReferenceId"
                  type="string"
                  placeholder="123456789876543"
                  label={KFW_REFERENCE_ID_FUNDING}
                />
                <CustomTooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tooltipReferenceId,
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                disabled={
                  values.kfwReferenceId === '' ||
                  values.reporterPostalCode === '' ||
                  !isValid ||
                  isSubmitting
                }
                className={classes.button}
                onClick={submitForm}
              >
                {SEND_REPORT}
              </CustomButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default CreateReportForm
