import { createContext, Dispatch, SetStateAction } from 'react'

type StepperContextType = {
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
}

const StepperContext = createContext<StepperContextType>({
  activeStep: 0,
  setActiveStep: (): void => {},
})

export default StepperContext
