/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
*/

import UniversLightWoff from './1475998/ccd9698a-60c5-48f7-acbe-5352b4aa81d4.woff'
import UniversLightWoff2 from './1475998/58c01d2a-ce4a-4360-a61f-761e91aa67c0.woff2'
import UniversLightCondensedWoff from './1476004/7ce02c2c-45d4-4dee-90f2-f8034e29ac24.woff'
import UniversLightCondensedWoff2 from './1476004/bf8f8741-5f64-4db9-a877-a44624092e68.woff2'

const UniversLight = {
  fontFamily: 'Univers LT W01_47 Light',
  src: `  
  url(${UniversLightWoff}) format("woff2"),
  url(${UniversLightWoff2}) format("woff");`,
}

const UniversCondensed = {
  fontFamily: 'Univers LT W01_57 Condensed',
  src: `  
  url(${UniversLightCondensedWoff}) format("woff2"),
  url(${UniversLightCondensedWoff2}) format("woff");`,
}

export default [UniversLight, UniversCondensed]
