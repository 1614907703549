import { Grid, makeStyles, Typography } from '@material-ui/core'
import CustomTranslations from '../../../../../utils/lang/CustomTranslations'

const useStyles = makeStyles({
  row: {
    overflow: 'hidden',
  },

  answer: {
    whiteSpace: 'pre-wrap',
    color: '#587268',
  },
})

interface RowProps {
  question: string
  answer: string | number | boolean | undefined
}

const Row = ({ question, answer }: RowProps): JSX.Element => {
  const classes = useStyles()

  let formattedAnswer = answer

  if (typeof answer === 'boolean') {
    formattedAnswer = answer ? CustomTranslations.YES : CustomTranslations.NO
  }

  return (
    <Grid container spacing={4} className={classes.row}>
      <Grid item xs={12} md={6}>
        <Typography variant="body1">{`${question}:`}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {answer != null && (
          <Typography variant="body1" className={classes.answer}>
            {formattedAnswer}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default Row
